.creativeVariations {
  flex: 1;
  border: 1px solid #cccccc;
  position: relative;
  margin-right: 12px;
  overflow-x: auto;
}

.headerTitle {
  background-color: #fbfbfd;
  height: 80px;
  color: #4d4f5c;
  border-bottom: 1px solid #cccccc;
  display: flex;
  align-items: center;
}
.headerTitle > p {
  margin: 0;
  padding-left: 1rem;
}
.headerTitle span {
  color: #a6a6a6;
  font-size: 0.8rem;
}

.variationActions {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 1rem 0 1rem;
}

.variationActions :global(.ant-btn-dashed) {
  color: rgb(176, 176, 176);
}

.variationsGrid {
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
}

.gridSizeBox {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.maximumTrigger {
  color: rgb(216, 57, 57);
}

/* CREATIVE SAMPLE */
.sampleCreative {
  color: #373737;
  position: relative;
  padding: 0.9rem 1.6rem 0.9rem 0.9rem;
  background-color: #e1e1e195;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid #ffffff;
  overflow: hidden;
}

.limitExceededSample {
  border: 2px solid rgb(206, 130, 130);
}

.selectedCreative {
  background-color: #4090f7;
  color: #fff;
}

.sampleTitle {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sampleCreativeContent {
  display: flex;
  flex-direction: column;
}

.imageSamplePreview {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.imageSamplePreview > div {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
}
.imageSamplePreview > div > p {
  font-size: 0.9rem;
  font-weight: 500;
}
.imageSamplePreview > div > img {
  height: 50px;
  width: 50px;
  min-width: 50px;
  object-fit: cover;
  /* border-radius: 7px; */
}
.imageSamplePreview > div > p {
  margin: 0;
  padding-left: 0.3rem;
}

.imageSamplePreview > img {
  /* border-radius: 7px; */
  padding-top: 0.7rem;
  width: 100%;
  height: 105px;
  object-fit: cover;
}
.sampleCreativeContent > p {
  font-size: 0.8rem;
  margin: 0.2rem 0;
}
img.iconVariation {
  width: 50%;
  height: auto;
}

.sampleCreativeActions {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0.9rem;
  right: 0.4rem;
}
.sampleCreativeActions > div {
  display: flex;
  flex-direction: column;
}
.actionIcon {
  font-size: 0.8rem;
  margin-bottom: 6px;
  cursor: pointer;
  transition: color 0.2s;
}
.actionIcon:hover {
  color: rgb(74, 74, 189);
}

@media only screen and (max-width: 1380px) {
  .headerTitle {
    height: auto;
  }
  .headerTitle > p {
    padding: 1rem;
  }
}
@media only screen and (max-width: 1240px) {
  .variationActions {
    flex-direction: column;
  }
  .variationActions div {
    margin-bottom: 0.2rem;
  }
}
@media only screen and (max-width: 1150px) {
  .creativeVariations {
    margin-left: 12px;
    margin-bottom: 12px;
  }
  .variationActions {
    flex-direction: row;
  }
}
@media only screen and (max-width: 620px) {
  .variationActions {
    flex-direction: column;
  }
}

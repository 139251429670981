.titleContent {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  color: #43425d;
}
.titleContent > h3 {
  padding-right: 2rem;
  font-weight: normal;
  margin: 0;
}
.newCampaignBtn {
  display: flex;
  align-items: center;
  padding: 1.2rem 0.9rem;
  background-color: #4090f7;
  border-radius: 6px;
}
.plusIconCamp {
  font-size: 25px;
  font-weight: bold;
}

.advancedFilters :global(.Select-menu-outer) {
  z-index: 10;
}

.filtersContainer {
  background-color: #fafafa;
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.dateRadioSelect {
  font-size: 0.9rem;
}
.filtersContainer :global(.ant-radio-group-large .ant-radio-button-wrapper) {
  color: #2f6fb6;
  font-size: inherit;
  background-color: transparent;
}
.filtersContainer :global(.ant-radio-group-large .ant-radio-button-wrapper-checked) {
  font-weight: 550;
  border: 1px solid !important;
  border-color: rgba(47, 111, 182, 0.4) !important;
  border-radius: 6px;
  background-color: rgba(47, 111, 182, 0.03);
  line-height: 28px;
  height: 30px;
}
.filtersContainer :global(.ant-radio-group-large .ant-radio-button-wrapper) {
  border: none;
}
.filtersContainer :global(.ant-radio-group-large .ant-radio-button-wrapper::before) {
  display: none;
}
.filtersContainer :global(.Select-control) {
  border-radius: 2px;
}
.filtersContainer :global(.Select-arrow) {
  border-color: #d9d9d9 transparent transparent;
}
.dateRadioSelect {
  margin-bottom: 0.5rem;
}
.filtersContainerBottom {
  display: flex;
}
.rangeDatePicker {
  margin-right: 1rem;
  height: 34px;
}
.inputTextSearch {
  max-width: 400px;
  width: 50%;
  min-width: 150px;
  margin-right: 1rem;
}

.hideShowLinkBtn {
  padding: 0.5rem 0 0 0;
  font-size: 0.8rem;
}

.selectStatuses,
.selectFormats {
  min-width: 120px;
  margin-right: 1rem;
}

.applyFiltersBtn {
  /* padding-left: 2rem; */
  /* padding-right: 2rem; */
  background-color: #3d8af8;
  /* height: 34px; */
}

.topActionBtns {
  padding: 0.8rem 0;
}
.columnGroupContainer {
  width: 500px;
}
.columnGroupContainer :global(.ant-checkbox-inner) {
  width: 20px;
  height: 20px;
}
.topActionButton {
  margin-right: 1rem;
}

.extActionGroup {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0 1rem;
}

.exportToCSV {
  border: none;
  color: #498adf;
}

.columnsBtn,
.reloadBtn {
  color: #a3a6b4;
  margin-left: 1rem;
  border: 1px solid #a3a6b4;
  border-radius: 0;
}

.bottomActions {
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}
.bottomActions .rightActionBtns > * {
  margin-left: 0.7rem;
}

/* virtualized select inputs */

/* .advancedFilters :global(.Select) {
  width: 200px;
  height: 32px;
  margin-right: 1rem;
  border-radius: 0;
} */
.advancedFilters :global(.Select-control) {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  height: 31px;
  z-index: 10;
  overflow: auto;
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
}
.advancedFilters :global(.Select-value-label) {
  max-width: 135px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.advancedFilters :global(.Select-input) {
  height: 30px;
}
.advancedFilters :global(.Select-placeholder) {
  line-height: 31px;
}
.advancedFilters :global(.Select-value) {
  width: calc(100% - 10px);
  margin-right: 3px;
  white-space: nowrap;
  overflow: hidden;
}

.advancedFilters :global(.has-value .Select-control > *:last-child) {
  display: inline-block;
  margin-left: 0rem;
}

.advancedFilters :global(.Select--multi .Select-clear-zone) {
  display: inline-block;
  margin-left: 5.5rem;
}

.financeContainer > h4 {
  color: #43425d;
  font-size: 1.4rem;
}

.methodTypeContainer {
  display: flex;
  overflow: hidden;
  transition: all 0.4s;
}

.methodTypeSelect {
  cursor: pointer;
  width: 130px;
  height: 70px;
  border-radius: 4px;
  padding: 0.2rem 0.6rem;
  border: 1px solid #eeeeee;
  background-color: #fafafa;
  transition: all 0.2s;
  z-index: 10;
  margin-right: 1.1rem;
  margin-bottom: 1.5rem;
}
.methodTypeSelect:hover {
  /* background-color: #c8e5ff; */
  border: 1px solid #8fc9ff;
}
.methodTypeSelect > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.highlightText {
  color: rgb(104, 104, 104);
  font-size: 0.85rem;
  font-weight: 600;
}

.fundPriceBtn {
  position: relative;
  text-align: center;
  /* border-radius: 4px; */
  color: rgb(101, 101, 101);
  width: 130px;
  margin-right: 1.1rem;
  margin-bottom: 1.8rem;
}

.optionHighlight {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: -29px;
  font-weight: bold;
  color: #1990ff;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8em;
  padding: 0;
  width: 100%;
  pointer-events: none;
}

.optionHighlight > img {
  height: 11px;
  margin-left: 0.3rem;
}

.optionHighlightPrice {
  color: #686868;
  font-weight: 600;
  margin-top: 0.3rem;
  margin-left: 0.1rem;
  font-size: 0.7em;
  padding: 0;
  width: 100%;
  pointer-events: none;
}
.optionHighlightPromoError {
  color: #e31c1c;
  font-weight: 600;
  margin-top: 0.3rem;
  margin-left: 0.1rem;
  font-size: 0.75em;
  padding: 0;
  width: 100%;
  pointer-events: none;
}

.fundPriceBtnCustom {
  padding: 0px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.inputElementContainer {
  display: flex;
  flex-direction: column;
}
.inputElementContainer > .currencyLabel {
  color: #686868;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
  font-size: 0.85rem;
}

.inputNumberFund {
  font-size: 0.8rem;
  width: 130px;
  height: 40px;
  line-height: unset;
}

@media (max-width: 1100px) {
  .inputNumberFund {
    font-size: 0.8rem;
    width: 130px;
    height: 40px;
    line-height: unset;
  }
}
.fundsForm :global(.ant-radio-group-large .ant-radio-button-wrapper) {
  font-size: 0.8rem;
}
.fundsForm > p {
  color: #606270;
  font-weight: 600;
}

.subtotalContainer {
  color: #606270;
  display: flex;
}
.subtotalContainer > div {
  display: flex;
  flex-direction: column;
  margin-top: 1.3rem;
  margin-right: 3rem;
}

.spinWrapper {
  margin-bottom: 1.8rem;
}

.subtotalContainer > div:first-child {
  font-weight: 600;
}
.subtotalContainer > div > span {
  margin-bottom: 0.3rem;
}
.totalPriceValue {
  color: #1990ff;
  font-weight: 700;
}

.bottomBtnActions {
  display: flex;
}

.paymentBtn {
  width: 180px;
  margin-right: 1rem;
}

.viewProformaInvoice {
  padding: 0 1rem 0 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
  align-items: center;
  border: 1px solid #d9d9d9;
  color: rgb(111, 111, 111);
}

.generalInfoHeader,
.generalInfoRows {
  display: block;
  margin-bottom: 0.7rem;
  width: 100%;
}

.generalInfoRows {
  margin: 0px 0px 15px 5px;
}

.statusActiveSpan {
  border: 1px solid #1990ff;
  border-radius: 2px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-left: 0.5rem;
  margin-top: 0.4rem;
  color: #1990ff;
  display: inline-block;
  background-color: #1990ff1a;
  text-transform: capitalize;
}
.statusInactiveSpan {
  display: none;
}

.selectionTag {
  height: 24px;
  display: inline-block;
  border: 1px solid #1990ff;
  border-radius: 2px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  color: #1990ff;
  background-color: #1990ff1a;
  transition: opacity 0.3s;
  user-select: none;
}

.generalInfoLabel {
  width: 200px;
  display: inline-block;
  color: #878787;
}
.generalInfoValue {
  font-weight: 500;
  text-transform: capitalize;
}

.targetingInfoHeader {
  width: 100%;
  margin-bottom: 10px;
}
.targetingInfoQuantity {
  width: 100%;
  margin-bottom: 5px;
}
.targetingInfoType {
  text-transform: capitalize;
  /* width: 100px; */
  margin-right: 0.5rem;
  display: inline-block;
  font-weight: 500;
  color: darkgrey;
}
.targetingInfoValue {
  border: 1px solid #1990ff;
  border-radius: 2px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-left: 0.5rem;
  margin-top: 0.4rem;
  color: #1990ff;
  display: inline-block;
  background-color: #1990ff1a;
  text-transform: capitalize;
}
.targetingInfoValueList {
  text-transform: capitalize;
  background-color: #ffffff;
  color: #1890ff;
  font-weight: 500;
  padding: 2px 0px;
  border-radius: 5px;
  margin-right: 10px;
}

.targetingInfoRow {
  width: 100%;
  margin-bottom: 20px;
}
.statusSpan {
  color: white;
  background-color: #44915e;
  display: block;
  border-radius: 7px;
  text-align: center;
  width: 100px;
}
.trackingInput {
  width: 80%;
  margin-bottom: 10px;
}
.trackingParagraph {
  margin-top: 0.5rem;
  color: darkgrey;
}
.trackingParamSpan {
  color: orangered;
}

.statusactive {
  display: block;
  text-transform: capitalize;
  min-width: 90px;
  font-weight: 500;
  border: 1px solid;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
  background-color: #02875b1a;
  border-color: #02875b;
  color: #02875b;
  text-align: center;
}
.statusinactive {
  display: block;
  text-transform: capitalize;
  min-width: 90px;
  font-weight: 500;
  border: 1px solid;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
  text-align: center;
}

.advertisingBudgetInfo {
  width: 200px;
  margin-right: 80%;
  margin-bottom: 10px;
}
.createCampaignButton {
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: 2rem;
  background-color: #70c040;
  border-color: #70c040;
  border-radius: 4px;
  color: white;
}
.createCampaignButton:hover {
  border-color: #70c040;
  color: #70c040;
}
.campaignTypePushCpa {
  color: #32b66b;
}
.campaignTypePUSH {
  color: #d95c5c;
}
.campaignTypeNATIVE {
  color: #3067ce;
}
.campaignTypeSMARTLINK {
  color: #781edf;
}
.campaignType {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.campaignType {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  font-weight: bold;
  /* font-size: 1rem; */
}

.endActionBtns {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 50px;
}

.reviewCampaignExtraBtn {
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  border-radius: 4px;
  color: rgb(63, 63, 63);
}

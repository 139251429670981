.targetingSelectDiv {
  height: 80px;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
}

.targetingPreviewDiv {
  height: 80px;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.countDisplay {
  padding: 1px 5px;
  font-weight: 500;
  color: #fff;
  background-color: #4a90e2;
  border-radius: 3px;
}

.countBox {
  color: #4a90e2;
}
.targetingSearchItem {
  margin: 0px 90px 0px 20px;
  width: 250px;
}

.deviceSelect {
  display: flex;
  transform: translateY(1px);
}

.deviceSelectButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 51px;
  border: 1px solid #cccccc;
  color: #4d4f5c;
  background-color: transparent;
  font-weight: bold;
}

.deviceSelect :global(.ant-radio-button-wrapper:first-child) {
  border-radius: 0;
  /* border: 2px solid gray; */
  border: 0px;
}
.deviceSelect :global(.ant-radio-button-wrapper:last-child) {
  border-radius: 0;
  /* border: 2px solid gray; */
  border: 0px;
  outline: none;
}
.deviceSelect :global(.ant-radio-button-wrapper:not(:first-child)::before) {
  width: 0px;
}
.deviceSelect :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)) {
  background: #fbfbfd;
  color: #4d4f5c;
  border: 1px solid #cccccc;
  border-right-color: #ccc !important;
  border-bottom: none;
}
.deviceSelect :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)):hover {
  background: #fbfbfd;
  color: #4d4f5c;
  border: 1px solid #cccccc;
  border-right-color: #ccc;
  border-bottom: none;
}

.devicesTabSelect {
  flex-direction: row !important;
  margin: 0px;
  position: relative;
  z-index: 10;
}

.targetingSelectBox {
  width: 63%;
}
.targetingPreviewBox {
  width: 37%;
}

.targetingSelectContainer {
  position: relative;
  z-index: 1;
}

.headTabInfo {
  display: flex;
  flex-direction: column;
}
.headTabInfo > div {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.headTabInfo > div > p {
  font-size: 1rem;
  margin: 0px;
  padding-left: 0.4rem;
}
.headTabInfo > p {
  color: rgb(103, 103, 103);
}

@media (max-width: 1100px) {
  .targetingSelectContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .targetingSelectBox {
    width: 90%;
    margin: 0 auto;
  }
  .devicesTabSelect {
    width: 90%;
    margin: 0 auto;
  }
  .targetingPreviewBox {
    width: 90%;
    margin: 0 auto;
  }
  .targetingSelectDiv {
    margin-right: 0px;
  }
}

.containerHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px 8px 0 0;
}

.containerHeader h2 {
    margin: 0;
    font-weight: 600;
    color: #4D4F5C;
}

.containerHeader .showUnread {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.containerHeader .showUnread span {
    color: #4D4F5C;
}
.targetingSelectDiv {
  height: 80px;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 20px;
}
.targetingSelectDivV2 {
  color: rgb(73, 73, 73);
  height: 80px;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: none;
  margin-right: 20px;
  display: flex;
  align-items: center;
  padding: 10px;
}

.targetingSelectDivV2 > p {
  margin-bottom: 0px;
  font-weight: 500;
}

.validationIssue {
  color: rgb(189, 28, 28);
}

.targetingPreviewDiv {
  height: 80px;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.targetingPreviewDiv > h3 {
  margin: 0;
}

.targetingSearchItem {
  margin: 0px 90px 0px 20px;
  width: 250px;
}
.countDisplay {
  padding: 1px 5px;
  font-weight: 500;
  color: #fff;
  background-color: #4a90e2;
  border-radius: 3px;
}

.countBox {
  color: #4a90e2;
}

.targetingSelectBox {
  width: 63%;
}
.targetingPreviewBox {
  width: 37%;
}

.headTabInfo {
  display: flex;
  flex-direction: column;
}
.headTabInfo > div {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.headTabInfo > div > p {
  font-size: 1rem;
  margin: 0px;
  padding-left: 0.4rem;
}
.headTabInfo > p {
  color: rgb(103, 103, 103);
}

.proceedButton {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin-top: 20px;
  border-radius: 4px;
  background-color: #4090f7;
}

.subIdInput {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.subIdInput :global(.ant-select) {
  margin-right: 1rem;
}

@media (max-width: 1100px) {
  .targetingSelectContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .targetingSelectBox {
    width: 90%;
    margin: 0 auto;
  }
  .targetingPreviewBox {
    width: 90%;
    margin: 0 auto;
  }
  .targetingSelectDiv {
    margin-right: 0px;
  }
}

/* TIME TABLE */

.timesTableWrapper {
  max-width: 100%;
  overflow-y: scroll;
  color: rgb(80, 80, 80);
}

.timeTable-true,
.timeTable-false {
  min-width: 850px;
  border-collapse: collapse;
  border-radius: 20px;
}

.timeTable-true,
.timeTable-true th,
.timeTable-true td,
.timeTable-false,
.timeTable-false th,
.timeTable-false td {
  border: 1px solid #00000015;
  position: relative;
}

.timeRow td {
  height: 30px;
  width: 30px;
  align-items: center;
  justify-content: center;
}

.rowTitle {
  padding: 0 0.7rem;
  text-transform: capitalize;
}

.timeTable-true.times-column-0 tbody .timeRowSelected td:nth-child(3)::before,
.timeTable-true.times-column-1 tbody .timeRowSelected td:nth-child(4)::before,
.timeTable-true.times-column-2 tbody .timeRowSelected td:nth-child(5)::before,
.timeTable-true.times-column-3 tbody .timeRowSelected td:nth-child(6)::before,
.timeTable-true.times-column-4 tbody .timeRowSelected td:nth-child(7)::before,
.timeTable-true.times-column-5 tbody .timeRowSelected td:nth-child(8)::before,
.timeTable-true.times-column-6 tbody .timeRowSelected td:nth-child(9)::before,
.timeTable-true.times-column-7 tbody .timeRowSelected td:nth-child(10)::before,
.timeTable-true.times-column-8 tbody .timeRowSelected td:nth-child(11)::before,
.timeTable-true.times-column-9 tbody .timeRowSelected td:nth-child(12)::before,
.timeTable-true.times-column-10 tbody .timeRowSelected td:nth-child(13)::before,
.timeTable-true.times-column-11 tbody .timeRowSelected td:nth-child(14)::before,
.timeTable-true.times-column-12 tbody .timeRowSelected td:nth-child(15)::before,
.timeTable-true.times-column-13 tbody .timeRowSelected td:nth-child(16)::before,
.timeTable-true.times-column-14 tbody .timeRowSelected td:nth-child(17)::before,
.timeTable-true.times-column-15 tbody .timeRowSelected td:nth-child(18)::before,
.timeTable-true.times-column-16 tbody .timeRowSelected td:nth-child(19)::before,
.timeTable-true.times-column-17 tbody .timeRowSelected td:nth-child(20)::before,
.timeTable-true.times-column-18 tbody .timeRowSelected td:nth-child(21)::before,
.timeTable-true.times-column-19 tbody .timeRowSelected td:nth-child(22)::before,
.timeTable-true.times-column-20 tbody .timeRowSelected td:nth-child(23)::before,
.timeTable-true.times-column-21 tbody .timeRowSelected td:nth-child(24)::before,
.timeTable-true.times-column-22 tbody .timeRowSelected td:nth-child(25)::before,
.timeTable-true.times-column-23 tbody .timeRowSelected td:nth-child(26)::before,
.timeTable-false.times-column-0 tbody tr td:nth-child(3)::before,
.timeTable-false.times-column-1 tbody tr td:nth-child(4)::before,
.timeTable-false.times-column-2 tbody tr td:nth-child(5)::before,
.timeTable-false.times-column-3 tbody tr td:nth-child(6)::before,
.timeTable-false.times-column-4 tbody tr td:nth-child(7)::before,
.timeTable-false.times-column-5 tbody tr td:nth-child(8)::before,
.timeTable-false.times-column-6 tbody tr td:nth-child(9)::before,
.timeTable-false.times-column-7 tbody tr td:nth-child(10)::before,
.timeTable-false.times-column-8 tbody tr td:nth-child(11)::before,
.timeTable-false.times-column-9 tbody tr td:nth-child(12)::before,
.timeTable-false.times-column-10 tbody tr td:nth-child(13)::before,
.timeTable-false.times-column-11 tbody tr td:nth-child(14)::before,
.timeTable-false.times-column-12 tbody tr td:nth-child(15)::before,
.timeTable-false.times-column-13 tbody tr td:nth-child(16)::before,
.timeTable-false.times-column-14 tbody tr td:nth-child(17)::before,
.timeTable-false.times-column-15 tbody tr td:nth-child(18)::before,
.timeTable-false.times-column-16 tbody tr td:nth-child(19)::before,
.timeTable-false.times-column-17 tbody tr td:nth-child(20)::before,
.timeTable-false.times-column-18 tbody tr td:nth-child(21)::before,
.timeTable-false.times-column-19 tbody tr td:nth-child(22)::before,
.timeTable-false.times-column-20 tbody tr td:nth-child(23)::before,
.timeTable-false.times-column-21 tbody tr td:nth-child(24)::before,
.timeTable-false.times-column-22 tbody tr td:nth-child(25)::before,
.timeTable-false.times-column-23 tbody tr td:nth-child(26)::before,
.timeTable-false .timeRowSelected td::before {
  position: absolute;
  content: '';
  display: inline-block;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  background-color: #fbaf16;
  transform: translate(-50%, -50%);
}
.timeRowSelected td:nth-child(1)::before,
.timeRowSelected td:nth-child(2)::before,
.timeRowSelected td:nth-child(1)::before,
.timeRowSelected td:nth-child(2)::before,
.hoursCheckbox::before {
  display: none;
}

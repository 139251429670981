.targetingSelectDiv {
  height: 80px;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: 0px;
  display: flex;
  margin-right: 20px;
  padding: 0.4rem 0.5rem;
  font-size: 0.7rem;
}

.targetingSelectDiv > p:nth-child(2) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.targetingPreviewDiv {
  height: 80px;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.targetingSearchItem {
  margin: 0px 90px 0px 20px;
  width: 250px;
}

.countDisplay {
  padding: 1px 5px;
  font-weight: 500;
  color: #fff;
  background-color: #4a90e2;
  border-radius: 3px;
}

.targetingBoxHour :global(.ant-slider-with-marks) {
  margin-bottom: 10px;
}
.targetingBoxHour {
  height: 243px;
  overflow-x: auto;
  margin-right: 20px;
  border: 1px solid #cccccc;
  padding: 0.7rem 0.5rem;
}
.targetingBoxHourText {
  color: rgb(104, 104, 104);
  display: flex;
  justify-content: space-between;
}
.targetingBoxHourPreview {
  overflow-x: scroll;
  border: 1px solid #cccccc;
  height: 243px;
}
.targetingBoxHourPreview > p {
  color: rgb(104, 104, 104);
  display: flex;
  flex-direction: column;
  font-weight: 500;
  height: 70%;
  font-size: 0.8rem;
  padding: 0.7rem 0.5rem;
}

.selectionTag {
  height: 24px;
  display: inline-block;
  border: 1px solid #1990ff;
  border-radius: 2px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-left: 0.5rem;
  margin-top: 0.4rem;
  color: #1990ff;
  background-color: #1990ff1a;
  transition: opacity 0.3s;
  user-select: none;
}

.headTabInfo :global(label.ant-radio-wrapper) {
  font-weight: 400;
  font-size: 0.85rem;
  /* color: red; */
}

.radioSuperFresh {
  margin-left: 0.3rem;
  border: 1px solid #faad2d;
  font-weight: 500;
  padding: 0.15rem 0.3rem;
  border-radius: 4px;
  color: #faad2d;
  font-size: 0.65rem;
  background-color: #faab2d0f;
}

.countBox {
  color: #4a90e2;
}
.targetingSelectBox {
  width: 63%;
}
.targetingPreviewBox {
  width: 37%;
}
.headTabInfo {
  display: flex;
  flex-direction: column;
  margin-bottom: 7px;
}
.headTabInfo > p {
  margin-bottom: 8.28px;
}
.headTabInfo > div {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.headTabInfo > div > p {
  font-size: 1rem;
  margin: 0px;
  padding-left: 0.4rem;
}
.headTabInfo > p {
  color: rgb(103, 103, 103);
}

@media (max-width: 1100px) {
  .targetingSelectContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  .targetingSelectBox {
    width: 90%;
    margin: 0 auto;
  }
  .targetingPreviewBox {
    width: 90%;
    margin: 0 auto;
  }
  .targetingSelectDiv {
    margin-right: 0px;
  }
}
@media (max-width: 1580px) {
  .targetingSelectDiv {
    font-size: 0.7rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .targetingSelectDiv > p {
    margin-bottom: 0rem;
    max-width: 100%;
  }
}

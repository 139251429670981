.notPreviewContainer {
  margin: 3.6rem 1rem 1rem 1rem;
}

.sendNotifText {
  font-weight: 500;
  color: #4a4a4a;
  font-size: 1rem;
}

.deviceBackground .iconTextWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.3rem;
  width: 100%;
}
.deviceBackgroundWindows .iconTextWrapper {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0.5rem;
}

.dynamicIcon {
  width: 42px;
  height: 42px;
  object-fit: contain;
}

.dynamicImage {
  margin-top: 0.8rem;
  object-fit: contain;
  width: 100%;
  min-height: 100px;
}
/* .deviceBackgroundWindows .dynamicImage {
  object-fit: cover;
  width: 100%;
  height: 120px;
} */
.dynamicTextWrapper {
  font-weight: 500;
  padding-left: 0.5rem;
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
}

.dynamicTextWrapper > div:first-child {
  font-size: 0.85rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dynamicTextWrapper > div:last-child {
  font-size: 0.7rem;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mainNotificationPreview {
  /* min-height: 320px; */
  /* width: 320px; */
  background-color: #f0f2f5;
  box-shadow: 0px 2px 10px #0000001c inset;
  padding: 0.8rem;
  border-radius: 0.5rem;
}

.deviceBackground {
  min-height: 332.39px;
  height: 100%;
  padding: 2rem 0;
  background-size: cover;
  background-position: top right;
}

.deviceBackgroundWindows {
  position: relative;
  min-height: 332.39px;
  height: 100%;
  background-size: cover;
  background-position: bottom right;
}

.deviceBackground .coreNotifBox {
  background-color: #ffffff;
  padding: 0.7rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%;
  margin: 0 auto;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 10px #0000001c;
}

.deviceBackgroundWindows .coreNotifBox {
  background-color: #1b2026;
  color: #ffffff;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 75%;
  position: absolute;
  bottom: 2.3rem;
  right: 0.7rem;
  box-shadow: 0px 2px 10px #0000001c;
}

.notPreviewContainer :global(.ant-radio-group) {
  width: 100%;
  margin-bottom: 1rem;
}

.notPreviewContainer :global(.ant-radio-button-wrapper) {
  width: 50%;
  text-align: center;
}

.notPreviewContainer :global(.ant-radio-button-wrapper:first-child) {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.notPreviewContainer :global(.ant-radio-button-wrapper:last-child) {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

@media (max-width: 900px) {
  .notPreviewContainer {
    display: none;
    /* margin: 1rem 0rem 0rem 0rem; */
  }
}

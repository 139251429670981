.newCreativeButton {
  margin-top: 20px;
  border-radius: 4px;
  background-color: #4090f7;
  border-color: #4090f7;
  padding-right: 1.3rem;
  padding-left: 1.3rem;
}

.finishCreativeButton {
  border-radius: 4px;
  margin-top: 20px;
  color: seagreen;
  border-color: seagreen;
}

.actionButtonsCreative {
  display: flex;
  justify-content: flex-end;
}

.creativesContentWrapper {
  min-height: 550px;
}

.targetingCheckboxGroup {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 5px 0px 0px 10px;
}

.listCollapseContainer {
  background-color: #ffffff;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.048);
}
.listCollapseContainer :global(.ant-collapse-item) {
  border-bottom: 0px;
}

.listCollapseContainer :global(.ant-collapse-item > .ant-collapse-header) {
  padding: 4px;
  padding-left: 38px;
  color: #000000a6;
}

.targetingCheckboxGroupLists {
  /* background-color: #fbfbfd; */
  display: flex;
  flex-direction: column;
  overflow: auto;
  /* padding: 10px 0px 5px 15px; */
  gap: 15px;
  /* box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.048); */
  /* margin-bottom: 5px; */
  /* border-top: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb; */
}

.targetingCheckboxGroupTwoColumns {
  display: block;
  overflow: auto;
  height: 380px;
  padding: 5px 0px 0px 10px;
}
.targetingCheckboxParentDiv {
  height: 295px;
  overflow-x: auto;
  margin-right: 20px;
  border: 1px solid #cccccc;
}

.targetingCheckboxGroup :global(.ant-checkbox-group-item) {
  padding: 10px 5px;
}
.targetingCheckboxGroupTwoColumns :global(.ant-checkbox-group-item) {
  padding: 10px 5px;
  width: 45%;
}

.selectedCountries {
  padding-top: 0.5rem;
}

/* .targetingCheckboxGroupTwoColumns::-webkit-scrollbar,
.targetingCheckboxGroup::-webkit-scrollbar,
.selectedCountries::-webkit-scrollbar,
.accordionSelect::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
  margin-right: 20px;
}
.targetingCheckboxGroupTwoColumns::-webkit-scrollbar-thumb,
.targetingCheckboxGroup::-webkit-scrollbar-thumb,
.selectedCountries::-webkit-scrollbar-thumb,
.accordionSelect::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.targetingCheckboxGroupTwoColumns::-webkit-scrollbar-track,
.targetingCheckboxGroup::-webkit-scrollbar-track,
.selectedCountries::-webkit-scrollbar-track,
.accordionSelect::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background-color: #e4e4e4;
  padding-right: 10px;
  margin-right: 20px;
} */

.targetingPreviewParentDiv {
  overflow-x: scroll;
  border: 1px solid #cccccc;
  height: 295px;
}

.potentialReach {
  position: absolute;
  bottom: 10px;
  border-top: 1px solid grey;
}

.allCheckBox {
  width: 100%;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.048);
  padding-top: 5px;
  padding-bottom: 4px;
  margin-bottom: 2px;
}

.countryRowCancel {
  transform: scale(0.8);
}

/* .countryRowCancel {
  min-width: 15px;
  height: 15px;
  background-color: white;
  border-color: #cccccc;
  color: grey;
}
.countryRowCancel:hover,
.countryRowCancel:focus {
  color: #fff;
  background-color: red;
} */

.clearAllCountries {
  float: right;
  /* margin-left: 53%;
  border-color: red;
  height: 25px;
  display: flex;
  align-items: center;
  border-radius: 10px; */
}

.clearAllX {
  margin-right: -10px;
  margin-left: 10px;
  border-radius: 50%;
  border: 1px solid red;
  width: 15px;
  height: 15px;
  line-height: 10px;
}

.allCountriesSelectedDiv {
  color: rgb(104, 104, 104);
  display: flex;
  flex-direction: column;
  font-weight: 500;
  height: 70%;
  font-size: 0.8rem;
  padding: 1.5rem 1rem;
}
.allCountriesSelectedDiv > div {
  display: flex;
  align-items: center;
}
.allCountriesSelectedDiv p {
  margin: 0;
}
.allCountriesSelectedDiv > p {
  margin-left: 35px;
  color: rgb(170, 170, 170);
}
.allCountriesSelectedDiv svg {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  color: #58a54f;
}

.accordionSelect {
  overflow: auto;
  height: 380px;
}
.accordionSelect,
.accordionSelect :global(.ant-collapse-item),
.accordionSelect :global(.ant-collapse-content) {
  border: none;
  background-color: white;
}

.accordionSelect :global(.ant-collapse-content-box) {
  margin-left: 25px;
  padding: 0px 0px 0px 20px;
  border-left: 1px dashed #cccccc;
}

.accordionSelect :global(.ant-collapse-content-active) {
  margin-top: -15px;
  padding-top: 10px;
}

.accordionSelect :global(.ant-radio-wrapper)::before {
  content: '--- ';
  color: #cccccc;
}
.accordionSelect :global(.ant-tabs-content .ant-checkbox-wrapper)::before {
  content: '--- ';
  color: #cccccc;
}
.accordionSelect :global(.ant-tabs-bar.ant-tabs-top-bar) {
  margin-left: 20px;
}

.targetingSpinner {
  display: flex;
  justify-content: center;
  height: 402px;
}

.targetingSpinner span {
  margin-top: 20%;
}
.previewWithVersionSpan,
.previewVersionTypeSpan {
  display: inline-block;
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  color: #1890ff;
  border-radius: 3px;
  padding: 3px 5px;
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: center;
}

.selectionTag {
  height: 24px;
  display: inline-block;
  border: 1px solid #1990ff;
  border-radius: 2px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-left: 0.5rem;
  margin-top: 0.4rem;
  color: #1990ff;
  background-color: #1990ff1a;
  transition: opacity 0.3s;
  user-select: none;
}
.selectionTag:hover {
  opacity: 0.7;
}
.selectionTag svg {
  margin-left: 0.2rem;
  margin-top: 0.15rem;
}

.previewVersionTypeSpan {
  display: inline;
}

.versionsContainer {
  border-left: 1pt dashed #cccccc;
  display: flex;
  flex-direction: column;
}

.listRow {
  padding: 7px 10px;
  border-bottom: 1px solid #cccccc;
  color: #1890ff;
  align-items: center;
}

.listCountries {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
}

/* TARGETING V2 */
.categoryContainer {
  margin: 0.3rem 1rem 0.5rem 0.7rem;
  transition: 0.3s;
  padding: 0rem 0.2rem;
}
.categoryContainer:hover {
  cursor: pointer;
  background-color: rgb(249, 249, 249);
  border-radius: 5px;
}
.categoryHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.subcategoriesBox :global(.ant-checkbox-group-item) {
  display: block;
  margin-left: 1rem;
  padding-top: 0.2rem;
  overflow: hidden;
}
.subcategoriesBox {
  margin-top: 0.2rem;
}

.categoryGroupContainer {
  border-bottom: 1px solid #cccccc;
}

.previewGroupTitle {
  font-weight: bold;
  margin: 0;
  padding: 0.3rem 0.5rem;
  color: #555555;
}

.subcatTag {
  border: 1px solid #1990ff;
  color: #1990ff;
  background-color: #1990ff1a;
  padding: 0.1rem 0.3rem;
  margin-right: 0.4rem;
  margin-bottom: 0.3rem;
  display: inline-block;
  font-size: 0.75rem;
}

.subcategoryPreviewGroup {
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  display: flex;
  flex-wrap: wrap;
}

.customLoaderContainer {
  margin: 20% auto;
  width: 250px;
  height: 250px;
  animation: spin 2s linear infinite;
}
.customLoaderContainer > img {
  top: 0;
  left: 0;
  transform: rotate(90deg);
  height: 65px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: scale(1.05) rotate(72deg);
  }
  40% {
    transform: scale(0.95) rotate(144deg);
  }
  60% {
    transform: scale(1.05) rotate(216deg);
  }
  80% {
    transform: scale(0.95) rotate(288deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1100px) {
  .targetingCheckboxParentDiv {
    margin-right: 0px;
  }
}

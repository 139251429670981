@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-layout {
  overflow: auto;
}

.ant-layout-sider-collapsed .logo img {
  width: 100%;
}

.ant-layout-sider-collapsed .logo span {
  display: none;
}

.logo {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  height: 32px;
  margin: 16px;
  color: #fff;
}

.logo img {
  display: block;
  width: 50%;
  margin: 0 auto;
}
.service-desk-link {
  padding-left: 24px;
  color: #a6b5cc;
  transition: color 0.4s;
}
.service-desk-link:hover {
  color: #fff;
}
.service-desk-link span {
  padding-left: 6px;
}
.ant-layout-sider-collapsed .service-desk-link {
  text-align: center;
  padding: 0;
  display: block;
  font-size: 20px;
}
.ant-layout-sider-collapsed .service-desk-link span {
  display: none;
}

.logo span {
  position: relative;
  top: 5px;
}

.custom-menu-item {
  display: block;
  width: 100%;
  height: 100%;
}

/* #nprogress .bar {
  background: #1890ff;
}

#nprogress .peg {
  box-shadow: 0 0 10px #1890ff, 0 0 5px #1890ff;
}

#nprogress .spinner-icon {
  border-top-color: #1890ff;
  border-left-color: #1890ff;
} */

.has-error {
  font-size: 10px;
}
.ant-layout-sider-children {
  overflow: auto;
}

.ant-layout-sider {
  background: #012d6d;
}

.ant-menu.ant-menu-dark {
  background: #012d6d;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #012d6d;
  box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.45);
}

.ant-layout-sider-trigger {
  background: #2964a9;
}
.ant-layout-sider-zero-width-trigger {
  top: 10px;
  z-index: 9999;
}
@media (max-width: 600px) {
  .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    display: none;
  }
}
.export-btn {
  margin: 0.8%;
}

/* GLOBAL STYLES: */
.divider {
  background: #f0f2f6;
  margin-top: 4%;
  width: 5000px; /* TODO: WTF??? */
  padding-left: 0;
  margin-left: -25px;
  padding: 5px;
  font-size: 1.4em;
}

h1 {
  font-size: 2em;
}

.modal-button {
  width: 100%;
  overflow: hidden;
}

/* HEADER styles */

.button-rocket {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background: #f9ae3b 0% 0% no-repeat padding-box;
  border-radius: 15px;
  border: none;
  color: white;
  font-size: 1rem;
  height: 45px;
  font-family: 'Montserrat', sans-serif;
}
.button-rocket:hover {
  background-color: #f9ad3bdc;
  color: #fff;
}

.header-new-camp-middle {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.button-rocket img {
  width: 45px;
  transition: 0.3s;
}
.button-rocket:hover img {
  -webkit-transform: translateY(-5px);
          transform: translateY(-5px);
}

.header-wallet {
  text-align: right;
  white-space: nowrap;
  margin-left: 20px;
}
.coins-value {
  color: #5a9e4c;
  /* font-family: normal; */
  font-weight: bold;
  font-size: 1.1rem;
  /* font: Bold 1.4rem/32px Source Sans Pro; */
  margin-right: 20px;
}
.coins-credit-value {
  color: #c6d699;
  /* font-family: normal; */
  font-weight: bold;
  font-size: 1.1rem;
  /* font: Bold 1.4rem/32px Source Sans Pro; */
  margin-right: 20px;
}
.wallet {
  width: 26px;
  padding-bottom: 8px;
  margin-right: 7px;
}

.wallet-limit {
  width: 30px;
  padding-bottom: 4px;
  margin-right: 7px;
}

.header-main {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
}

.header-right {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.header-right svg {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.header-dropdown {
  top: 50px !important;
  width: auto !important;
}
.header-dropdown .ant-dropdown-menu {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 14px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.header-dropdown .ant-dropdown-menu-item:hover,
.header-dropdown .ant-dropdown-menu-item a:hover {
  color: #1890ff;
}

.header-action-btn {
  color: #bcbccb;
  margin-left: 20px;
}
.header-action-btn:nth-child(2) {
  margin-right: 10px;
}
.header-action-btn:hover {
  color: #2d71b6;
  transition: 0.3s;
}
.header-username {
  max-height: 63.99px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-size: 0.75rem;
  text-align: right;
}
.header-username > span {
  line-height: 0.9rem;
}
.header-username > span:nth-child(1) {
  color: #848484;
}
.header-username > span:nth-child(2) {
  color: rgb(169, 169, 169);
}
.header-highligh {
  color: #1890ff;
  font-weight: bold;
}
.profile-avatar {
  width: 38px;
  height: 38px;
  object-fit: cover;
  position: relative;
  bottom: 2px;
  border-radius: 50%;
}

.credit-label {
  font-size: 1.1rem;
  margin-right: 0.3rem;
  color: rgb(112, 112, 112);
  font-weight: bold;
}
.down-arrow svg {
  color: rgb(180, 180, 180);
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-left: 5px;
}
.header-right-spinner {
  width: 30%;
}
.header-right-user {
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  transition: opacity 0.2s;
}
.header-right-user:hover {
  opacity: 0.8;
}
.header-right-user > span {
  white-space: nowrap;
}
.billingDataMissingNotification {
  height: 40px;
  background: rgb(250, 191, 98);
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #032b6d;
}
/* End Header styles */

/* SIDEBAR */

.ant-menu-dark.ant-menu-inline .ant-menu-item {
  padding-right: 13px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected .menuItemRestyle {
  background-color: rgba(255, 255, 255, 0.07);
  border: 1px solid rgba(255, 255, 255, 0.14);
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected .menuItemRestyle > a {
  color: #faad14 !important;
}

.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 22px;
}
.ant-layout-sider-collapsed .menuItemRestyle {
  border-radius: 6px;
  padding-left: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  width: 40px;
  max-height: 40px;
}

.menuItemRestyle {
  border-radius: 6px;
  padding-left: 10px;
  max-height: 40px;
}

.menuItemRestyle > a {
  transition: 0s;
  color: #fff;
}

.menuItemRestyle > a > span {
  transition: all 0.2s;
  color: inherit !important;
}

.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #154486;
}

.ant-menu-item.ant-menu-item-only-child.ant-menu-item-active .menuItemRestyle > a {
  color: #faad14 !important;
}

.ant-menu-submenu-title {
  color: #fff;
}

.submenuTitleRestyle {
  padding: 10px;
}

.ant-menu.ant-menu-dark {
  background-color: transparent;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: transparent;
  box-shadow: none;
}

.ant-layout-sider {
  background-image: url(https://publisher.coinis.com/images/moduleBackground.png);
}

/* Sidebar rocket */

.sidebarcontent .ant-layout-sider-children {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.sidebarModuleDisp {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  width: 100%;
  height: 350px;
  white-space: nowrap;
  color: #e0a64e;
  font-size: 1.3rem;
  font-weight: 600;
  bottom: 0;
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 1.5rem;
  background-position: bottom;
  background-size: 200px;
  background-repeat: no-repeat;
}

.sidebarModuleDisp > span {
  transition: color 0.2s;
}

.sidebarModuleDisp > span:first-child:hover {
  color: #e0a54e72;
}

.sidebarModuleDisp > span:first-child {
  padding-bottom: 1rem;
  word-wrap: break-word;
  cursor: pointer;
}

/* .ant-layout.ant-layout-has-sider > .ant-layout {
  overflow-x: hidden;
} */

.balanceField {
  color: #73b566;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 0.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.balanceField > img {
  height: 24px;
}
.balanceField > p {
  padding-left: 0.3rem;
  margin: 0;
}
.balanceField > img {
  padding-bottom: 0.2rem;
}

.headerDropdown .ant-dropdown-menu {
  max-width: 320px;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 14px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.headerDropdown .ant-dropdown-menu-item {
  padding: 0px 0px;
}
.headerDropdown .ant-dropdown-menu-item:hover {
  background-color: transparent;
}

.broadcastNotification {
  display: inline;
}

.broadcastNotification>p {
  display: inline;
}

.barNotification {
  display: inline;
}

.barNotification>p {
  display: inline;
  font-size: 14px;
  color: #4D4F5C;
}

.barNotificationList {
  width: 496px;
  border-radius: 8px;
  border: transparent;
  background: white;
  box-shadow: 0px 2px 15px #0000000D;
}

.virtualListWrapper .rc-virtual-list-holder {
  border-radius: 0 0 8px 8px;
}

.barNotificationList .ant-list-footer {
  padding: 0;
}

.barNotificationList .ant-list-bordered .ant-list-header {
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 2px 15px #0000000D;
}

.barNotificationList .unReadlistItem {
  width: 496px;
  background-color: rgba(25, 144, 255, 0.05);
}

.barNotificationList .notificationTitle {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  color: #4D4F5C;
}

.barNotificationList .notificationTitle label.ant-radio-wrapper {
  margin: 0;
}

.barNotificationList .notificationTitle .ant-radio-inner::after {
  background-color: #FFAA2D;
}

.barNotificationList .notificationTitle .ant-radio-checked:after {
  border: 1px solid transparent !important;
}

.barNotificationList .notificationTitle .ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none;
}

.barNotificationList .notificationTitle .ant-radio-inner {
  background-color: #F0F0F0;
  border-color: #F0F0F0;
}

.barNotificationList .notificationTitle div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.barNotificationList .notificationTitle .notificationIcon {
  margin-right: .4rem;
}

.barNotificationList .notificationTitle h3 {
  font-size: .95rem;
  font-weight: 600;
  color: #4D4F5C;
  vertical-align: middle;
  margin: 0;
}

.barNotificationList .notificationDescription {
  margin-top: 10px;
  overflow: hidden;
  position: relative;
}

.barNotificationList .notificationDate {
  margin-top: .5rem;
  font-size: 12px;
  color: #989898;
}

.notificationDescription .notificationExpandLinkWrapperRead {
  padding-left: 5px;
  margin: 0;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  cursor: pointer;
}

.notificationDescription .notificationExpandLinkWrapperUnread {
  padding-left: 5px;
  margin: 0;
  background-color: rgba(25, 144, 255, 0.05);
  cursor: pointer;
}

.notificationExpandLink {
  color: #4D4F5C;
  text-decoration: underline;
}

.barNotificationItemModal .ant-modal-content {
  border-radius: 5px;
}

.barNotificationItemModal .ant-modal-header {
  padding: 20px 30px 10px;
  border-bottom: 0px solid #f0f0f0;
  border-radius: 5px 5px 0 0;
}

.barNotificationItemModal .ant-modal-body {
  padding: 10px 30px 10px;
}

.barNotificationItemModal .ant-modal-footer {
  border-top: 0px solid #f0f0f0;
  padding: 10px 30px 20px;
}

.barNotificationItemModal .barNotificationItemModalTitle {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.barNotificationItemModal .barNotificationItemModalTitle h3 {
  display: inline;
  font-size: 1rem;
  font-weight: 600;
  color: #4D4F5C;
  vertical-align: middle;
  margin: 0;
}

.barNotificationItemModalIcon {
  vertical-align: middle;
  margin-right: 0.4rem;
}

/* AGREEMENT SECTION */

.agreementContainer {
  background: #FFAA2D1A;
  border: 1px solid #FFA13B;
  border-radius: 6px;
  padding: 8px 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  cursor: pointer;
  margin-right: 8px;
}

.agreementContainer p {
  margin: 0;
  font-size: 14px;
  line-height: 1.2;
  text-align: right;
  color: #4D4F5C;
}

.agreementContainer p:first-child {
  color: #FFAA2D;
  font-weight: 600;
}

.agreementContainer svg {
  margin-left: 20px;
  color: #FFA13B;
  height: 32px;
  width: 32px;
}

.agreementContainerBilling {
  background: #F849491A;
  border: 1px solid #F84949;
  border-radius: 6px;
  padding: 8px 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  cursor: pointer;
  margin-right: 8px;
}

.link {
  text-decoration: underline;
  color: #1990FF;
  cursor: pointer;
}

@media (max-width: 1500px) {
  /* .header-username {
    display: none;
  } */
  .button-rocket {
    font-size: 0.9rem;
    padding: 0.5rem 0.7rem;
    height: 40px;
  }
  /* .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  } */
}

/* @media screen and (max-height: 935px) {
  .sidebarModuleDisp {
    background-image: none !important;
  }
} */

@media (max-width: 900px) {
  /* .header-wallet, */
  .header-action-btn {
    display: none;
  }
}

.NotificationBar_containerHeader__3FEWD {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-radius: 8px 8px 0 0;
}

.NotificationBar_containerHeader__3FEWD h2 {
    margin: 0;
    font-weight: 600;
    color: #4D4F5C;
}

.NotificationBar_containerHeader__3FEWD .NotificationBar_showUnread__omWCJ {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}

.NotificationBar_containerHeader__3FEWD .NotificationBar_showUnread__omWCJ span {
    color: #4D4F5C;
}
.Show_headingActions__3cF1F {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 30px;
}

.Show_headingActions__3cF1F > div {
  margin-right: 20px;
}

.Show_columnGroupContainer__1jqjV {
  width: 500px;
}
.Show_columnGroupContainer__1jqjV .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

.Show_searchInput__1UlmY {
  width: 250px;
}

.Show_campaignsTable__2OKMA table thead th {
  background-color: #fff;
  color: #707070;
  font-size: 0.9rem;
}

.Show_campaignsTable__2OKMA table tbody {
  font-size: 0.85rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #808080 !important;
}

.Show_campaignsTable__2OKMA .ant-table-row {
  background-color: #fff;
}

.Show_campaignsTable__2OKMA .ant-table-footer {
  background-color: #fff;
}

/* .statusColumn, */
/* .status, */
.Show_campaignNameWrapper__1toje,
.Show_campaignType__QJaco {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  color: #5b49a7;
}

.Show_status__nyrPv {
  background-color: #d1d1d11a;
  border: 1px solid #d1d1d1;
  color: #d1d1d1;
}

.Show_campaignType__QJaco {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  font-weight: bold;
  font-size: 1rem;
}
.Show_campaignType__QJaco span {
  position: relative;
  left: -15px;
  font-size: 0.85rem;
}

.Show_campaignNameWrapper__1toje {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.Show_campaignsTable__2OKMA .Show_campaignNameColumn__18oUH {
  cursor: pointer;
}

.Show_status__nyrPv {
  text-transform: capitalize;
  min-width: 90px;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
  margin-right: 0.5rem;
}
.Show_statusactive__3WWdi {
  background-color: #02875b1a;
  border-color: #02875b;
  color: #02875b;
}
.Show_statuspaused__3mV7F {
  background-color: #d1d1d11a;
  border-color: #d1d1d1;
  color: #d1d1d1;
}
.Show_statussuspended__1tpL4 {
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
}
.Show_statuspending__1HHn4 {
  background-color: #faad141a;
  border-color: #faad14;
  color: #faad14;
}
.Show_statusready__37Lqr {
  background-color: #02875b1a;
  border: 1px solid #02875b;
  color: #02875b;
}
.Show_statusimplementing__2zRt7 {
  background-color: #1990ff1a;
  border: 1px solid #1990ff;
  color: #1990ff;
}
.Show_statusdisabled__25N4d {
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
}
.Show_campaignTypePushCpa__VXsLt {
  color: #32b66b;
}
.Show_campaignTypePushCpc__3ZgNp {
  color: #d95c5c;
}
.Show_campaignTypeNativeCpc__1ZkoZ {
  color: #3067ce;
}
.Show_campaignTypeSmartlinkCpa__sQGlb {
  color: #781edf;
}
.Show_nameInitials__2XpRv {
  width: 2.3em;
  height: 2.3em;
  border-radius: 50%;
  color: #fff;
  background-color: rgb(85, 85, 85);
  padding: 5px;
  margin-right: 5px;
  text-align: center;
}

.Show_campaignsTable__2OKMA .ant-table-tbody > tr > td {
  padding: 18px 12px;
}
.Show_campaignsTable__2OKMA .ant-table-tbody > tr > td:last-child {
  padding: 6px 12px;
}

.Show_channelName__zY_p_,
.Show_companyName__1oVeY,
.Show_dateCreated__1NO4P {
  text-align: left;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
}
.Show_channelName__zY_p_ {
  color: #0071bd;
  font-weight: 600;
  cursor: pointer;
}
/* .channelName {
  width: 200px;
} */
.Show_campaignId__8WnYy {
  font-size: 0.8rem;
  color: #0071bd;
  font-weight: 600;
  cursor: pointer;
}

.Show_actions__2TZrh {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.Show_actions__2TZrh > button {
  cursor: pointer;
  margin-right: 6px;
  width: 32px;
  height: 32px;
  padding: 2.4px 0;
  font-size: 16px;
  border-radius: 2px;
}

.Show_actions__2TZrh > span {
  margin-right: 6px;
}

.Show_actions__2TZrh button:last-child {
  margin-right: 0;
}

.Show_actions__2TZrh .ant-popover-inner-content {
  padding: 5px 8px !important;
}

/* CAMPAIGNS TITLE AND FILTERS AREA */

.Show_titleContent__3WNV3 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 0.8rem;
  color: #43425d;
}
.Show_titleContent__3WNV3 > h3 {
  font-size: 1.4rem;
  color: #43425d;
  padding-right: 0.9rem;
  margin: 0;
  font-weight: 500;
}

.Show_newCampaignBtn__24IH7 {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  border-radius: 4px;
  background-color: #4090f7;
}

/* .newCampaignBtn { */
/* display: flex;
  align-items: center; */
/* padding: 1.2rem 0.9rem; */
/* background-color: #4090f7; */
/* border-radius: 6px; */
/* } */

.Show_filtersContainer__1juK7 {
  background-color: #fafafa;
  padding: 0.5rem 1rem 1.5rem 1rem;
}
.Show_dateRadioSelect__WAwnZ {
  font-size: 0.9rem;
}
.Show_campaignsTable__2OKMA .ant-radio-group-large .ant-radio-button-wrapper {
  color: #2f6fb6;
  font-size: inherit;
  background-color: transparent;
}
.Show_campaignsTable__2OKMA .ant-radio-group-large .ant-radio-button-wrapper-checked {
  font-weight: 550;
  border: 1px solid !important;
  border-color: rgba(47, 111, 182, 0.4) !important;
  border-radius: 6px;
  background-color: rgba(47, 111, 182, 0.03);
  line-height: 28px;
  height: 30px;
}
.Show_campaignsTable__2OKMA .ant-radio-group-large .ant-radio-button-wrapper {
  border: none;
}
.Show_campaignsTable__2OKMA .ant-radio-group-large .ant-radio-button-wrapper::before {
  display: none;
}
.Show_dateRadioSelect__WAwnZ {
  margin-bottom: 0.5rem;
}
/* .filtersContainerBottom {
  display: flex;
} */
.Show_filtersContainerBottom__2n50f .Select {
  width: 200px;
  /* height: 32px; */
  margin-right: 1rem;
  border-radius: 0;
}
.Show_filtersContainerBottom__2n50f .Select-control {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  height: 31px;
  z-index: 10;
  overflow: auto;
}
.Show_filtersContainerBottom__2n50f .has-value .Select-control > *:last-child {
  display: inline-block;
  margin-left: 0rem;
}

.Show_filtersContainerBottom__2n50f .Select--multi .Select-clear-zone {
  display: inline-block;
  margin-left: 5.5rem;
}

.Show_filtersContainerBottom__2n50f .Select-value .Select-value-label {
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Show_filtersContainerBottom__2n50f .Select-input {
  height: 30px;
}
.Show_filtersContainerBottom__2n50f .Select-placeholder {
  line-height: 31px;
}
.Show_filtersContainerBottom__2n50f .Select-value {
  width: 100%;
  max-width: 185px;
  white-space: nowrap;
  overflow: hidden;
}
.Show_rangeDatePicker__29wIf {
  height: 32px;
  margin-right: 1rem;
}
.Show_inputTextSearch__1boKS {
  max-width: 400px;
  width: 50%;
  min-width: 150px;
  margin-right: 1rem;
}

.Show_selectStatuses__105vY,
.Show_selectFormats__3FqGK {
  height: 32px;
  min-width: 120px;
  margin-right: 1rem;
}

.Show_applyFiltersBtn__11uet {
  margin-top: 0.7rem;
  background-color: #3d8af8;
  /* width: 100%; */
}

/* .campaignsTable .selectStatuses :global(.ant-select-selector),
.campaignsTable .selectFormats :global(.ant-select-selector) {
  height: 100%;
} */

.Show_extActionGroup__1-KRU {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1rem 0 1rem;
}

.Show_extActionGroup__1-KRU .Show_extActionRightGroup__3-mE4 .ant-btn {
  color: #464646;
  border: 1px solid #c4c4c4;
  border-radius: 3px;
}

.Show_extActionGroup__1-KRU .Show_extActionRightGroup__3-mE4 .ant-btn:hover {
  color: #40a9ff;
  background: #fff;
  border-color: #40a9ff;
}

.Show_extActionGroup__1-KRU .Show_extActionRightGroup__3-mE4 .ant-btn:not(:last-child) {
  margin-right: 0.5rem;
}

.Show_budgetColContainer__S-ePN {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.Show_budgetColContainer__S-ePN button {
  margin-left: 0.5rem;
}

.Show_budgetColContainer__S-ePN .ant-btn-icon-only {
  height: 22px;
  width: 22px;
  font-size: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Show_budgetColContainer__S-ePN .ant-btn > .anticon {
  line-height: 0;
}

.Show_budgetColContainer__S-ePN .ant-btn-icon-only > * {
  font-size: 13px;
}

.Show_cpcColContainer__t37ZH {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.Show_cpcColContainer__t37ZH button {
  margin-left: 0.5rem;
}

.Show_cpcColContainer__t37ZH .ant-btn-icon-only {
  height: 22px;
  width: 22px;
  font-size: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Show_cpcColContainer__t37ZH .ant-btn > .anticon {
  line-height: 0;
}

.Show_cpcColContainer__t37ZH .ant-btn-icon-only > * {
  font-size: 13px;
}

.Show_antTableContent__2fw1u .ant-table-cell {
  color: rgb(115, 115, 115);
}

@media (max-width: 1000px) {
  .Show_filtersContainerBottom__2n50f {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .Show_inputTextSearch__1boKS,
  .Show_selectStatuses__105vY,
  .Show_rangeDatePicker__29wIf,
  .Show_selectFormats__3FqGK {
    width: 100%;
    max-width: none;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  .Show_applyFiltersBtn__11uet {
    max-width: 400px;
  }
  .Show_campaignsTable__2OKMA .ant-radio-group-large .ant-radio-button-wrapper {
    display: none;
  }
  .Show_extActionGroup__1-KRU {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .Show_titleContent__3WNV3 {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
  .Show_columnsBtn__Qt7h4,
  .Show_reloadBtn__2ueva {
    margin-bottom: 0.4rem;
  }
}

.Show_pricingColumn__3y1N_ {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.Show_pricingColumn__3y1N_ .Show_confirmButtonDisabled__2Un6R {
  position: relative;
  top: 4px;
  right: 0;
  margin-left: 1rem;
}

.Show_pricingColumn__3y1N_ .Show_confirmButtonGreen__sTS3U {
  position: relative;
  top: 4px;
  right: 0;
  margin-left: 1rem;
  color: #73B566;
  border: 1px solid #73B566;
  background-color: #73B5661a;
}

.Show_priceUpdateContainer__DDQ3w {
  display: -webkit-flex;
  display: flex;
}

.Show_priceUpdateContainer__DDQ3w .ant-btn{
  padding: 0;
  height: 100%;
  width: 33px;
  border: 1px solid #EEEEEE;
  color: #4D4F5C;
  font-size: 13px;
  box-shadow: none;
  border-radius: 0 5px 5px 0;
}

.Show_priceUpdateContainer__DDQ3w .ant-btn:first-child{
  border-radius: 5px 0 0 5px;
}

.Show_mainPriceBox__1mKjx {
  /* padding: 0.3rem; */
  border-top: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
}

.Show_mainPriceBox__1mKjx * {
  box-shadow: none;
}

.Show_mainPriceBox__1mKjx .ant-input-number {
  border: none;
  border-radius: 0;
}

.Show_mainPriceBox__1mKjx .ant-input-number:focus {
  border: none;
  box-shadow: none;
}

.Show_mainPriceBox__1mKjx .ant-input-number-input-wrap input{
  padding: 0;
  text-align: center;
  color: #43425d;
}

.Show_mainPriceBox__1mKjx .ant-input-number-handler-wrap {
  position: relative;
  display: none;
}
.BudgetModal_budgetModalWrapper__b17Aj .ant-modal-content {
    border-radius: 5px;
}

.BudgetModal_budgetModalWrapper__b17Aj .ant-modal-header {
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    padding: 20px 30px 0 30px;
}

.BudgetModal_budgetModalWrapper__b17Aj .ant-modal-close-x {
    font-size: 12px;
}

.BudgetModal_budgetModalWrapper__b17Aj .ant-modal-body {
    padding: 20px 30px;
}

.BudgetModal_budgetModalWrapper__b17Aj .ant-modal-body p {
    margin: 0;
}

.BudgetModal_actionButtons__L1Alw {
    margin: 0;
}

.BudgetModal_actionButtons__L1Alw .ant-form-item-control-input-content{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.BudgetModal_actionButtons__L1Alw button:first-child {
    width: 35%;
}

.BudgetModal_actionButtons__L1Alw button:nth-child(2) {
    width: 55%;
}

.BudgetModal_inputDollar__2vnDu {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: flex-start;
            align-items: flex-start;
}

.BudgetModal_inputDollar__2vnDu .ant-row{
    margin-bottom: 1rem;
    width: 100%;
}

.BudgetModal_inputDollar__2vnDu .ant-input-number{
    border-radius: 2px 0 0 2px;
}

.BudgetModal_inputDollar__2vnDu .BudgetModal_dollarBox__3NL3A {
    height: 32px;
    width: 32px;
    margin-top: 1.89rem;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: #FAFAFA;
    color: #D1D1D1;
    border: 1px solid #D1D1D1;
    border-left: none;
    border-radius: 0 2px 2px 0;
}
.GeneralConfig_generalWrapper__1In12 {
  width: 100%;
  min-width: 300px;
}
.GeneralConfig_generalContentWrapper__3zP8H {
  width: 80%;
  max-width: 450px;
  min-height: 550px;
}
.GeneralConfig_statusRadioButton__HuJuZ {
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
  -webkit-align-items: center;
          align-items: center;
}

.GeneralConfig_statusRadioButton__HuJuZ .ant-form-item-label {
  padding: 0px;
  width: 140px;
}

.GeneralConfig_radioButtonLabels__17b10 {
  width: 75px;
  text-align: center;
}

.GeneralConfig_autoManualPricing__3x6QW .ant-form-item-label > label {
  margin-left: 30px;
}

.GeneralConfig_datepickerStart__26sSv,
.GeneralConfig_datepickerEnd__2bfHJ {
  width: 50%;
  display: inline-block;
}
.GeneralConfig_datepickerStart__26sSv .ant-col.ant-form-item-control {
  margin-right: 10px;
}
.GeneralConfig_datepickerStart__26sSv .ant-picker,
.GeneralConfig_datepickerEnd__2bfHJ .ant-picker {
  width: 100%;
}

.GeneralConfig_statusInfo__1dvO8 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 1rem;
}
.GeneralConfig_statusInfo__1dvO8 > p {
  margin: 0;
  color: #000000d9;
  padding-right: 0.5rem;
}

.GeneralConfig_manualValueInput__p7pQX {
  display: inline-block;
  width: 150px;
  position: absolute;
  margin-top: 30px;
  margin-left: 10px;
}

.GeneralConfig_campaignBudget__3nu0P {
  display: inline-block;
  width: 60%;
  margin-right: 7%;
}
.GeneralConfig_campaignBudget__3nu0P input {
  min-height: 32px;
}
.GeneralConfig_campaignBudgetPeriod__12e3W {
  display: inline-block;
  width: 33%;
}
.GeneralConfig_proceedButton__2MCaS {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  float: right;
  margin-top: 20px;
  border-radius: 4px;
  background-color: #4090f7;
}

@media (max-width: 1100px) {
  .GeneralConfig_generalWrapper__1In12 {
    width: 95%;
    margin: 0 auto;
  }
}

/* .campaignTypeInput,
.accName {
  width: 50%;
  display: inline-block;
}

.accName :global(.ant-col.ant-form-item-control) {
  margin-right: 10px;
}
.campaignTypeInput :global(.ant-form-item-control-input-content) {
  display: flex;
} */

.Targeting_targetingContainer__jqZwm > .ant-tabs {
  min-height: 550px;
  /* max-height: 550px; */
}

.Targeting_proceedButton__3rmNl {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin-top: 20px;
  border-radius: 4px;
  background-color: #4090f7;
}

.Geos_targetingSelectDiv__tQ6PC {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 80px;
  align-items: center;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: 0px;
  margin-right: 20px;
  padding: 0 20px;
}

.Geos_targetingSelectDivLeft__G_Hqr{
display: -webkit-flex;
display: flex;
-webkit-align-items: center;
        align-items: center;
}

.Geos_checkboxRegions__19U48 {
  color: rgb(118, 118, 118);
  margin-left: 1rem;
}

.Geos_targetingPreviewDiv__YKJmG {
  height: 80px;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.Geos_targetingSearchItem__3db9u {
  margin: 0 10px 0 0;
  width: 250px;
}

.Geos_countDisplay__37EYd {
  padding: 1px 5px;
  font-weight: 500;
  color: #fff;
  background-color: #4a90e2;
  border-radius: 3px;
}

.Geos_countBox__3WDc0 {
  color: #4a90e2;
}
.Geos_targetingSelectBox__2JF5g {
  width: 63%;
}
.Geos_targetingPreviewBox__2Huzf {
  width: 37%;
}

.Geos_headTabInfo__YvZyV {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.Geos_headTabInfo__YvZyV > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 600;
}
.Geos_headTabInfo__YvZyV > div > p {
  font-size: 1rem;
  margin: 0px;
  padding-left: 0.4rem;
}
.Geos_headTabInfo__YvZyV > p {
  color: rgb(103, 103, 103);
}

@media (max-width: 1100px) {
  .Geos_targetingSelectContainer__1rQ_U {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }
  .Geos_targetingSelectBox__2JF5g {
    width: 90%;
    margin: 0 auto;
  }
  .Geos_targetingPreviewBox__2Huzf {
    width: 90%;
    margin: 0 auto;
  }
  .Geos_targetingSelectDiv__tQ6PC {
    margin-right: 0px;
  }
}

.TargetingGeos_targetingCheckboxGroup__2gJim {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: auto;
  padding: 12px 0px 0px 12px;
}

.TargetingGeos_targetingCheckboxGroup__2gJim > label {
  margin-bottom: 12px;
}

.TargetingGeos_listCollapseContainer__3m1EK {
  background-color: #ffffff;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.048);
}
.TargetingGeos_listCollapseContainer__3m1EK .ant-collapse-item {
  border-bottom: 0px;
}

.TargetingGeos_listCollapseContainer__3m1EK .ant-collapse-item > .ant-collapse-header {
  padding: 4px;
  padding-left: 38px;
  color: #000000a6;
}

.TargetingGeos_targetingCheckboxGroupLists__bhM94 {
  /* background-color: #fbfbfd; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: auto;
  /* padding: 10px 0px 5px 15px; */
  grid-gap: 15px;
  gap: 15px;
  /* box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.048); */
  /* margin-bottom: 5px; */
  /* border-top: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb; */
}

.TargetingGeos_targetingCheckboxGroupTwoColumns__NMo0b {
  display: block;
  overflow: auto;
  height: 380px;
  padding: 5px 0px 0px 10px;
}

.TargetingGeos_targetingCheckboxParentDiv__uIwQe {
  height: 295px;
  overflow-x: auto;
  margin-right: 20px;
  border: 1px solid #cccccc;
}

.TargetingGeos_targetingCheckboxParentDiv__uIwQe .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.TargetingGeos_targetingCheckboxGroup__2gJim .ant-checkbox-group-item {
  padding: 10px 5px;
}
.TargetingGeos_targetingCheckboxGroupTwoColumns__NMo0b .ant-checkbox-group-item {
  padding: 10px 5px;
  width: 45%;
}

.TargetingGeos_selectedCountries__2_vgf {
  padding-top: 0.5rem;
}

.TargetingGeos_subItemsContainer__3Zgdp {
  margin-left: 1.5rem;
  margin-top: 1rem;
}

.TargetingGeos_subItemsContainer__3Zgdp .ant-checkbox-group{
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.TargetingGeos_subItemsContainer__3Zgdp .ant-checkbox-group label{
  margin-bottom: .5rem;
}

.TargetingGeos_subItemsContainer__3Zgdp .ant-checkbox-group label:last-child{
  margin: 0;
}

.TargetingGeos_subItemsContainer__3Zgdp .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}

.TargetingGeos_subItemsContainer__3Zgdp p {
  margin: 0;
  color: #c2c2c2;
  font-style: italic;
}

/* .targetingRadioGroupTwoColumns::-webkit-scrollbar,
.targetingRadioGroup::-webkit-scrollbar,
.selectedCountries::-webkit-scrollbar,
.accordionSelect::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
  margin-right: 20px;
}
.targetingRadioGroupTwoColumns::-webkit-scrollbar-thumb,
.targetingRadioGroup::-webkit-scrollbar-thumb,
.selectedCountries::-webkit-scrollbar-thumb,
.accordionSelect::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.targetingRadioGroupTwoColumns::-webkit-scrollbar-track,
.targetingRadioGroup::-webkit-scrollbar-track,
.selectedCountries::-webkit-scrollbar-track,
.accordionSelect::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background-color: #e4e4e4;
  padding-right: 10px;
  margin-right: 20px;
} */

.TargetingGeos_targetingPreviewParentDiv__BVzbc {
  overflow-x: scroll;
  border: 1px solid #cccccc;
  height: 295px;
}

.TargetingGeos_potentialReach__13NmS {
  position: absolute;
  bottom: 10px;
  border-top: 1px solid grey;
}

.TargetingGeos_allCheckBox__2eLBv {
  width: 100%;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.048);
  padding-top: 5px;
  padding-bottom: 4px;
  margin-bottom: 2px;
}

.TargetingGeos_countryRowCancel__2Gen0 {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

/* .countryRowCancel {
  min-width: 15px;
  height: 15px;
  background-color: white;
  border-color: #cccccc;
  color: grey;
}
.countryRowCancel:hover,
.countryRowCancel:focus {
  color: #fff;
  background-color: red;
} */

.TargetingGeos_clearAllCountries__2HMsW {
  float: right;
  /* margin-left: 53%;
  border-color: red;
  height: 25px;
  display: flex;
  align-items: center;
  border-radius: 10px; */
}

.TargetingGeos_clearAllX__11-FJ {
  margin-right: -10px;
  margin-left: 10px;
  border-radius: 50%;
  border: 1px solid red;
  width: 15px;
  height: 15px;
  line-height: 10px;
}

.TargetingGeos_allCountriesSelectedDiv__3HksC {
  color: rgb(104, 104, 104);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-weight: 500;
  height: 70%;
  font-size: 0.8rem;
  padding: 1.5rem 1rem;
}

.TargetingGeos_allCountriesSelectedDiv__3HksC p {
  font-size: 14px;
  color: #4D4F5C;
  font-weight: 400;
}

.TargetingGeos_allCountriesSelectedDiv__3HksC > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 1rem;
}

.TargetingGeos_allCountriesSelectedDiv__3HksC > div p {
  color: #D85C5C;
  margin: 0;
}

.TargetingGeos_allCountriesSelectedDiv__3HksC svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  color: #FAAD14;
}

.TargetingGeos_shownResultContainer__mZ_P7 p {
  margin: 0.7rem;
  color: #4D4F5C;
}

.TargetingGeos_accordionSelect__3wUvt {
  overflow: auto;
  height: 380px;
}
.TargetingGeos_accordionSelect__3wUvt,
.TargetingGeos_accordionSelect__3wUvt .ant-collapse-item,
.TargetingGeos_accordionSelect__3wUvt .ant-collapse-content {
  border: none;
  background-color: white;
}

.TargetingGeos_accordionSelect__3wUvt .ant-collapse-content-box {
  margin-left: 25px;
  padding: 0px 0px 0px 20px;
  border-left: 1px dashed #cccccc;
}

.TargetingGeos_accordionSelect__3wUvt .ant-collapse-content-active {
  margin-top: -15px;
  padding-top: 10px;
}

.TargetingGeos_accordionSelect__3wUvt .ant-radio-wrapper::before {
  content: '--- ';
  color: #cccccc;
}
.TargetingGeos_accordionSelect__3wUvt .ant-tabs-content .ant-checkbox-wrapper::before {
  content: '--- ';
  color: #cccccc;
}
.TargetingGeos_accordionSelect__3wUvt .ant-tabs-bar.ant-tabs-top-bar {
  margin-left: 20px;
}

.TargetingGeos_targetingSpinner__2pDom {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  height: 402px;
}

.TargetingGeos_targetingSpinner__2pDom span {
  margin-top: 20%;
}
.TargetingGeos_previewWithVersionSpan__2Tu1m,
.TargetingGeos_previewVersionTypeSpan__25rD0 {
  display: inline-block;
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  color: #1890ff;
  border-radius: 3px;
  padding: 3px 5px;
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: center;
}

.TargetingGeos_selectionTag__HFCVO {
  height: 24px;
  display: inline-block;
  border: 1px solid #1990ff;
  border-radius: 2px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-left: 0.7rem;
  margin-top: 0.4rem;
  color: #1990ff;
  background-color: #1990ff1a;
  transition: opacity 0.3s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.TargetingGeos_selectionTag__HFCVO:hover {
  opacity: 0.7;
}
.TargetingGeos_selectionTag__HFCVO svg {
  margin-left: 0.2rem;
  margin-top: 0.15rem;
}

.TargetingGeos_previewVersionTypeSpan__25rD0 {
  display: inline;
}

.TargetingGeos_versionsContainer__28X7M {
  border-left: 1pt dashed #cccccc;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.TargetingGeos_listRow__Qe8_K {
  padding: 7px 10px;
  border-bottom: 1px solid #cccccc;
  color: #1890ff;
  -webkit-align-items: center;
          align-items: center;
}

.TargetingGeos_listCountries__NFlc3 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0.5rem;
}

/* TARGETING V2 */
.TargetingGeos_categoryContainer__EtH39 {
  margin: 0.3rem 1rem 0.5rem 0.7rem;
  transition: 0.3s;
  padding: 0rem 0.2rem;
}
.TargetingGeos_categoryContainer__EtH39:hover {
  cursor: pointer;
  background-color: rgb(249, 249, 249);
  border-radius: 5px;
}
.TargetingGeos_categoryHead__2qDeY {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.TargetingGeos_subcategoriesBox__HnBSk .ant-checkbox-group-item {
  display: block;
  margin-left: 1rem;
  padding-top: 0.2rem;
  overflow: hidden;
}
.TargetingGeos_subcategoriesBox__HnBSk {
  margin-top: 0.2rem;
}

.TargetingGeos_categoryGroupContainer__1gjUc {
  border-bottom: 1px solid #cccccc;
}

.TargetingGeos_previewGroupTitle__2XAYB {
  font-weight: bold;
  margin: 0;
  padding: 0.3rem 0.5rem;
  color: #555555;
}

.TargetingGeos_subcatTag__1mjrr {
  border: 1px solid #1990ff;
  color: #1990ff;
  background-color: #1990ff1a;
  padding: 0.1rem 0.3rem;
  margin-right: 0.4rem;
  margin-bottom: 0.3rem;
  display: inline-block;
  font-size: 0.75rem;
}

.TargetingGeos_subcategoryPreviewGroup__12FlU {
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.TargetingGeos_customLoaderContainer__1G3Lp {
  margin: 20% auto;
  width: 250px;
  height: 250px;
  -webkit-animation: TargetingGeos_spin__ey5lW 2s linear infinite;
          animation: TargetingGeos_spin__ey5lW 2s linear infinite;
}
.TargetingGeos_customLoaderContainer__1G3Lp > img {
  top: 0;
  left: 0;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  height: 65px;
}

@-webkit-keyframes TargetingGeos_spin__ey5lW {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  20% {
    -webkit-transform: scale(1.05) rotate(72deg);
            transform: scale(1.05) rotate(72deg);
  }
  40% {
    -webkit-transform: scale(0.95) rotate(144deg);
            transform: scale(0.95) rotate(144deg);
  }
  60% {
    -webkit-transform: scale(1.05) rotate(216deg);
            transform: scale(1.05) rotate(216deg);
  }
  80% {
    -webkit-transform: scale(0.95) rotate(288deg);
            transform: scale(0.95) rotate(288deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes TargetingGeos_spin__ey5lW {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  20% {
    -webkit-transform: scale(1.05) rotate(72deg);
            transform: scale(1.05) rotate(72deg);
  }
  40% {
    -webkit-transform: scale(0.95) rotate(144deg);
            transform: scale(0.95) rotate(144deg);
  }
  60% {
    -webkit-transform: scale(1.05) rotate(216deg);
            transform: scale(1.05) rotate(216deg);
  }
  80% {
    -webkit-transform: scale(0.95) rotate(288deg);
            transform: scale(0.95) rotate(288deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (max-width: 1100px) {
  .TargetingGeos_targetingCheckboxParentDiv__uIwQe {
    margin-right: 0px;
  }
}

.Os_targetingSelectDiv__3NtOt {
  height: 80px;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}
.Os_targetingSelectDivLeft__2coLd {
  display: -webkit-flex;
  display: flex;
  height: 80px;
  -webkit-align-items: center;
          align-items: center;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: 0px;
  margin-right: 20px;
}

.Os_checkboxVersion__2Dhk1 {
  color: rgb(118, 118, 118);
  margin-left: 1rem;
}

.Os_targetingSearchItem__3QO5R {
  margin: 0px 0px 0px 20px;
  width: 250px;
}

.Os_countDisplay__396fe {
  padding: 1px 5px;
  font-weight: 500;
  color: #fff;
  background-color: #4a90e2;
  border-radius: 3px;
}

.Os_countBox__3HA4g {
  color: #4a90e2;
}
.Os_targetingSelectBox__KGagE {
  width: 63%;
}
.Os_targetingPreviewBox__2TgZI {
  width: 37%;
}

.Os_headTabInfo__ISUAr {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.Os_headTabInfo__ISUAr > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 600;
}
.Os_headTabInfo__ISUAr > div > p {
  font-size: 1rem;
  margin: 0px;
  padding-left: 0.4rem;
}
.Os_headTabInfo__ISUAr > p {
  color: rgb(103, 103, 103);
}

@media (max-width: 1100px) {
  .Os_targetingSelectContainer__1e3Fy {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }
  .Os_targetingSelectBox__KGagE {
    width: 90%;
    margin: 0 auto;
  }
  .Os_targetingPreviewBox__2TgZI {
    width: 90%;
    margin: 0 auto;
  }
  .Os_targetingSelectDivLeft__2coLd {
    margin-right: 0px;
  }
}
@media (max-width: 1400px) {
  .Os_targetingSelectDivLeft__2coLd {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .Os_targetingSelectDivLeft__2coLd > .Os_targetingSearchItem__3QO5R {
    margin: 0;
  }
}

.Targeting_targetingCheckboxGroup__g5Jo2 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: auto;
  padding: 5px 0px 0px 10px;
}

.Targeting_listCollapseContainer__1HoXl {
  background-color: #ffffff;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.048);
}
.Targeting_listCollapseContainer__1HoXl .ant-collapse-item {
  border-bottom: 0px;
}

.Targeting_listCollapseContainer__1HoXl .ant-collapse-item > .ant-collapse-header {
  padding: 4px;
  padding-left: 38px;
  color: #000000a6;
}

.Targeting_targetingCheckboxGroupLists__3it6d {
  /* background-color: #fbfbfd; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  overflow: auto;
  /* padding: 10px 0px 5px 15px; */
  grid-gap: 15px;
  gap: 15px;
  /* box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.048); */
  /* margin-bottom: 5px; */
  /* border-top: 1px solid #cbcbcb;
  border-bottom: 1px solid #cbcbcb; */
}

.Targeting_targetingCheckboxGroupTwoColumns__3omDm {
  display: block;
  overflow: auto;
  height: 380px;
  padding: 5px 0px 0px 10px;
}
.Targeting_targetingCheckboxParentDiv__1o120 {
  height: 295px;
  overflow-x: auto;
  margin-right: 20px;
  border: 1px solid #cccccc;
}

.Targeting_targetingCheckboxGroup__g5Jo2 .ant-checkbox-group-item {
  padding: 10px 5px;
}
.Targeting_targetingCheckboxGroupTwoColumns__3omDm .ant-checkbox-group-item {
  padding: 10px 5px;
  width: 45%;
}

.Targeting_selectedCountries__3kUSZ {
  padding-top: 0.5rem;
}

/* .targetingCheckboxGroupTwoColumns::-webkit-scrollbar,
.targetingCheckboxGroup::-webkit-scrollbar,
.selectedCountries::-webkit-scrollbar,
.accordionSelect::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
  margin-right: 20px;
}
.targetingCheckboxGroupTwoColumns::-webkit-scrollbar-thumb,
.targetingCheckboxGroup::-webkit-scrollbar-thumb,
.selectedCountries::-webkit-scrollbar-thumb,
.accordionSelect::-webkit-scrollbar-thumb {
  border-radius: 20px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

.targetingCheckboxGroupTwoColumns::-webkit-scrollbar-track,
.targetingCheckboxGroup::-webkit-scrollbar-track,
.selectedCountries::-webkit-scrollbar-track,
.accordionSelect::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  background-color: #e4e4e4;
  padding-right: 10px;
  margin-right: 20px;
} */

.Targeting_targetingPreviewParentDiv__2WjiA {
  overflow-x: scroll;
  border: 1px solid #cccccc;
  height: 295px;
}

.Targeting_potentialReach__seWog {
  position: absolute;
  bottom: 10px;
  border-top: 1px solid grey;
}

.Targeting_allCheckBox__3CwYx {
  width: 100%;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.048);
  padding-top: 5px;
  padding-bottom: 4px;
  margin-bottom: 2px;
}

.Targeting_countryRowCancel__3pq-F {
  -webkit-transform: scale(0.8);
          transform: scale(0.8);
}

/* .countryRowCancel {
  min-width: 15px;
  height: 15px;
  background-color: white;
  border-color: #cccccc;
  color: grey;
}
.countryRowCancel:hover,
.countryRowCancel:focus {
  color: #fff;
  background-color: red;
} */

.Targeting_clearAllCountries__37EM2 {
  float: right;
  /* margin-left: 53%;
  border-color: red;
  height: 25px;
  display: flex;
  align-items: center;
  border-radius: 10px; */
}

.Targeting_clearAllX__11nam {
  margin-right: -10px;
  margin-left: 10px;
  border-radius: 50%;
  border: 1px solid red;
  width: 15px;
  height: 15px;
  line-height: 10px;
}

.Targeting_allCountriesSelectedDiv__1gckg {
  color: rgb(104, 104, 104);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-weight: 500;
  height: 70%;
  font-size: 0.8rem;
  padding: 1.5rem 1rem;
}
.Targeting_allCountriesSelectedDiv__1gckg > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.Targeting_allCountriesSelectedDiv__1gckg p {
  margin: 0;
}
.Targeting_allCountriesSelectedDiv__1gckg > p {
  margin-left: 35px;
  color: rgb(170, 170, 170);
}
.Targeting_allCountriesSelectedDiv__1gckg svg {
  width: 25px;
  height: 25px;
  margin-right: 10px;
  color: #58a54f;
}

.Targeting_accordionSelect__1rg8k {
  overflow: auto;
  height: 380px;
}
.Targeting_accordionSelect__1rg8k,
.Targeting_accordionSelect__1rg8k .ant-collapse-item,
.Targeting_accordionSelect__1rg8k .ant-collapse-content {
  border: none;
  background-color: white;
}

.Targeting_accordionSelect__1rg8k .ant-collapse-content-box {
  margin-left: 25px;
  padding: 0px 0px 0px 20px;
  border-left: 1px dashed #cccccc;
}

.Targeting_accordionSelect__1rg8k .ant-collapse-content-active {
  margin-top: -15px;
  padding-top: 10px;
}

.Targeting_accordionSelect__1rg8k .ant-radio-wrapper::before {
  content: '--- ';
  color: #cccccc;
}
.Targeting_accordionSelect__1rg8k .ant-tabs-content .ant-checkbox-wrapper::before {
  content: '--- ';
  color: #cccccc;
}
.Targeting_accordionSelect__1rg8k .ant-tabs-bar.ant-tabs-top-bar {
  margin-left: 20px;
}

.Targeting_targetingSpinner__rxTYa {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  height: 402px;
}

.Targeting_targetingSpinner__rxTYa span {
  margin-top: 20%;
}
.Targeting_previewWithVersionSpan__3H3gS,
.Targeting_previewVersionTypeSpan__2OBzG {
  display: inline-block;
  background-color: #e6f7ff;
  border: 1px solid #91d5ff;
  color: #1890ff;
  border-radius: 3px;
  padding: 3px 5px;
  font-size: 0.8rem;
  margin-top: 4px;
  text-align: center;
}

.Targeting_selectionTag__NZ6_H {
  height: 24px;
  display: inline-block;
  border: 1px solid #1990ff;
  border-radius: 2px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-left: 0.5rem;
  margin-top: 0.4rem;
  color: #1990ff;
  background-color: #1990ff1a;
  transition: opacity 0.3s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.Targeting_selectionTag__NZ6_H:hover {
  opacity: 0.7;
}
.Targeting_selectionTag__NZ6_H svg {
  margin-left: 0.2rem;
  margin-top: 0.15rem;
}

.Targeting_previewVersionTypeSpan__2OBzG {
  display: inline;
}

.Targeting_versionsContainer__1izrl {
  border-left: 1pt dashed #cccccc;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.Targeting_listRow__2r76C {
  padding: 7px 10px;
  border-bottom: 1px solid #cccccc;
  color: #1890ff;
  -webkit-align-items: center;
          align-items: center;
}

.Targeting_listCountries__12Ii_ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding-left: 0.5rem;
}

/* TARGETING V2 */
.Targeting_categoryContainer__erH7J {
  margin: 0.3rem 1rem 0.5rem 0.7rem;
  transition: 0.3s;
  padding: 0rem 0.2rem;
}
.Targeting_categoryContainer__erH7J:hover {
  cursor: pointer;
  background-color: rgb(249, 249, 249);
  border-radius: 5px;
}
.Targeting_categoryHead__2XTXs {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.Targeting_subcategoriesBox__3r5Zk .ant-checkbox-group-item {
  display: block;
  margin-left: 1rem;
  padding-top: 0.2rem;
  overflow: hidden;
}
.Targeting_subcategoriesBox__3r5Zk {
  margin-top: 0.2rem;
}

.Targeting_categoryGroupContainer__3XMXe {
  border-bottom: 1px solid #cccccc;
}

.Targeting_previewGroupTitle__L0Id9 {
  font-weight: bold;
  margin: 0;
  padding: 0.3rem 0.5rem;
  color: #555555;
}

.Targeting_subcatTag__2PoDI {
  border: 1px solid #1990ff;
  color: #1990ff;
  background-color: #1990ff1a;
  padding: 0.1rem 0.3rem;
  margin-right: 0.4rem;
  margin-bottom: 0.3rem;
  display: inline-block;
  font-size: 0.75rem;
}

.Targeting_subcategoryPreviewGroup__1BGso {
  padding: 0rem 0.5rem 0.5rem 0.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.Targeting_customLoaderContainer__23Qzh {
  margin: 20% auto;
  width: 250px;
  height: 250px;
  -webkit-animation: Targeting_spin__25aNa 2s linear infinite;
          animation: Targeting_spin__25aNa 2s linear infinite;
}
.Targeting_customLoaderContainer__23Qzh > img {
  top: 0;
  left: 0;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  height: 65px;
}

@-webkit-keyframes Targeting_spin__25aNa {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  20% {
    -webkit-transform: scale(1.05) rotate(72deg);
            transform: scale(1.05) rotate(72deg);
  }
  40% {
    -webkit-transform: scale(0.95) rotate(144deg);
            transform: scale(0.95) rotate(144deg);
  }
  60% {
    -webkit-transform: scale(1.05) rotate(216deg);
            transform: scale(1.05) rotate(216deg);
  }
  80% {
    -webkit-transform: scale(0.95) rotate(288deg);
            transform: scale(0.95) rotate(288deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Targeting_spin__25aNa {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  20% {
    -webkit-transform: scale(1.05) rotate(72deg);
            transform: scale(1.05) rotate(72deg);
  }
  40% {
    -webkit-transform: scale(0.95) rotate(144deg);
            transform: scale(0.95) rotate(144deg);
  }
  60% {
    -webkit-transform: scale(1.05) rotate(216deg);
            transform: scale(1.05) rotate(216deg);
  }
  80% {
    -webkit-transform: scale(0.95) rotate(288deg);
            transform: scale(0.95) rotate(288deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (max-width: 1100px) {
  .Targeting_targetingCheckboxParentDiv__1o120 {
    margin-right: 0px;
  }
}

.Browsers_targetingSelectDiv__3gpAe {
  height: 80px;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}
.Browsers_targetingSelectDivLeft__jac0t {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 80px;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: 0px;
  margin-right: 20px;
}

.Browsers_checkboxVersion__2UX_i {
  color: rgb(118, 118, 118);
  margin-left: 1rem;
}

.Browsers_targetingSearchItem__1zpBB {
  margin: 0px 0px 0px 20px;
  width: 250px;
}

.Browsers_countDisplay__FcaLN {
  padding: 1px 5px;
  font-weight: 500;
  color: #fff;
  background-color: #4a90e2;
  border-radius: 3px;
}

.Browsers_countBox__2FYn5 {
  color: #4a90e2;
}
.Browsers_targetingSelectBox__3rvRB {
  width: 63%;
}
.Browsers_targetingPreviewBox__4TGOy {
  width: 37%;
}
.Browsers_headTabInfo__3Q7I0 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.Browsers_headTabInfo__3Q7I0 > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 600;
}
.Browsers_headTabInfo__3Q7I0 > div > p {
  font-size: 1rem;
  margin: 0px;
  padding-left: 0.4rem;
}
.Browsers_headTabInfo__3Q7I0 > p {
  color: rgb(103, 103, 103);
}

@media (max-width: 1100px) {
  .Browsers_targetingSelectContainer__3f6MG {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }
  .Browsers_targetingSelectBox__3rvRB {
    width: 90%;
    margin: 0 auto;
  }
  .Browsers_targetingPreviewBox__4TGOy {
    width: 90%;
    margin: 0 auto;
  }
  .Browsers_targetingSelectDivLeft__jac0t {
    margin-right: 0px;
  }
}

@media (max-width: 1400px) {
  .Browsers_targetingSelectDivLeft__jac0t {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
  }
  .Browsers_targetingSelectDivLeft__jac0t > .Browsers_targetingSearchItem__1zpBB {
    margin: 0;
  }
}

.IP_borderIP__25u1p {
  border: 1px solid #cccccc;
}

.IP_headTabInfo__2OeuY {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.IP_headTabInfo__2OeuY > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 600;
}
.IP_headTabInfo__2OeuY > div > p {
  font-size: 1rem;
  margin: 0px;
  padding-left: 0.4rem;
}
.IP_headTabInfo__2OeuY > p {
  color: rgb(103, 103, 103);
}

.Devices_targetingSelectDiv__1teRJ {
  height: 80px;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 20px;
}

.Devices_targetingPreviewDiv__3H4CJ {
  height: 80px;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.Devices_countDisplay__LKEvZ {
  padding: 1px 5px;
  font-weight: 500;
  color: #fff;
  background-color: #4a90e2;
  border-radius: 3px;
}

.Devices_countBox__3LfvB {
  color: #4a90e2;
}
.Devices_targetingSearchItem__1xpWM {
  margin: 0px 90px 0px 20px;
  width: 250px;
}

.Devices_deviceSelect__3TVtA {
  display: -webkit-flex;
  display: flex;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}

.Devices_deviceSelectButton__bGlz8 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 51px;
  border: 1px solid #cccccc;
  color: #4d4f5c;
  background-color: transparent;
  font-weight: bold;
}

.Devices_deviceSelect__3TVtA .ant-radio-button-wrapper:first-child {
  border-radius: 0;
  /* border: 2px solid gray; */
  border: 0px;
}
.Devices_deviceSelect__3TVtA .ant-radio-button-wrapper:last-child {
  border-radius: 0;
  /* border: 2px solid gray; */
  border: 0px;
  outline: none;
}
.Devices_deviceSelect__3TVtA .ant-radio-button-wrapper:not(:first-child)::before {
  width: 0px;
}
.Devices_deviceSelect__3TVtA .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  background: #fbfbfd;
  color: #4d4f5c;
  border: 1px solid #cccccc;
  border-right-color: #ccc !important;
  border-bottom: none;
}
.Devices_deviceSelect__3TVtA .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  background: #fbfbfd;
  color: #4d4f5c;
  border: 1px solid #cccccc;
  border-right-color: #ccc;
  border-bottom: none;
}

.Devices_devicesTabSelect__3eWIo {
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
  margin: 0px;
  position: relative;
  z-index: 10;
}

.Devices_targetingSelectBox__RZR2k {
  width: 63%;
}
.Devices_targetingPreviewBox__euIQq {
  width: 37%;
}

.Devices_targetingSelectContainer__3kJX5 {
  position: relative;
  z-index: 1;
}

.Devices_headTabInfo__20izy {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.Devices_headTabInfo__20izy > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 600;
}
.Devices_headTabInfo__20izy > div > p {
  font-size: 1rem;
  margin: 0px;
  padding-left: 0.4rem;
}
.Devices_headTabInfo__20izy > p {
  color: rgb(103, 103, 103);
}

@media (max-width: 1100px) {
  .Devices_targetingSelectContainer__3kJX5 {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }
  .Devices_targetingSelectBox__RZR2k {
    width: 90%;
    margin: 0 auto;
  }
  .Devices_devicesTabSelect__3eWIo {
    width: 90%;
    margin: 0 auto;
  }
  .Devices_targetingPreviewBox__euIQq {
    width: 90%;
    margin: 0 auto;
  }
  .Devices_targetingSelectDiv__1teRJ {
    margin-right: 0px;
  }
}

.Days_headTabInfo__3NgYX {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.Days_headTabInfo__3NgYX > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 600;
}
.Days_headTabInfo__3NgYX > div > p {
  font-size: 1rem;
  margin: 0px;
  padding-left: 0.4rem;
}
.Days_headTabInfo__3NgYX > p {
  color: rgb(103, 103, 103);
}

.Days_headTabInfo__3NgYX {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.Days_headTabInfo__3NgYX > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 600;
}
.Days_headTabInfo__3NgYX > div > p {
  font-size: 1rem;
  margin: 0px;
  padding-left: 0.4rem;
}
.Days_headTabInfo__3NgYX > p {
  color: rgb(103, 103, 103);
}

.Days_actionTimeButtons__1Xb0F .ant-btn {
  margin-right: 1.3rem;
  border-radius: 4px;
  margin-bottom: 1rem;
}
.Days_actionTimeButtons__1Xb0F .ant-btn-primary {
  background-color: #4090f7;
  border-color: #4090f7;
}

.Days_timesInfoLabel__g-k2R {
  padding-top: 1rem;
  color: #1990ff;
  font-size: 0.8rem;
  height: 53px;
}

/* TIME TABLE */

.TimesTable_timesTableWrapper__aCiWh {
  max-width: 100%;
  overflow-y: scroll;
  color: rgb(80, 80, 80);
}

.TimesTable_timeTable-true__1NOyf,
.TimesTable_timeTable-false__2WSrF {
  min-width: 850px;
  border-collapse: collapse;
  border-radius: 20px;
}

.TimesTable_timeTable-true__1NOyf,
.TimesTable_timeTable-true__1NOyf th,
.TimesTable_timeTable-true__1NOyf td,
.TimesTable_timeTable-false__2WSrF,
.TimesTable_timeTable-false__2WSrF th,
.TimesTable_timeTable-false__2WSrF td {
  border: 1px solid #00000015;
  position: relative;
}

.TimesTable_timeRow__3U8TB td {
  height: 30px;
  width: 30px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.TimesTable_rowTitle__3GK9M {
  padding: 0 0.7rem;
  text-transform: capitalize;
}

.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-0__3_MIq tbody .TimesTable_timeRowSelected__YXMat td:nth-child(3)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-1__BXrYA tbody .TimesTable_timeRowSelected__YXMat td:nth-child(4)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-2__dCLjd tbody .TimesTable_timeRowSelected__YXMat td:nth-child(5)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-3__26S8V tbody .TimesTable_timeRowSelected__YXMat td:nth-child(6)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-4__3Voan tbody .TimesTable_timeRowSelected__YXMat td:nth-child(7)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-5__2U9NI tbody .TimesTable_timeRowSelected__YXMat td:nth-child(8)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-6__cN1fG tbody .TimesTable_timeRowSelected__YXMat td:nth-child(9)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-7__1mDmH tbody .TimesTable_timeRowSelected__YXMat td:nth-child(10)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-8__2gdd2 tbody .TimesTable_timeRowSelected__YXMat td:nth-child(11)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-9__zaFxt tbody .TimesTable_timeRowSelected__YXMat td:nth-child(12)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-10__luobH tbody .TimesTable_timeRowSelected__YXMat td:nth-child(13)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-11__1VWnW tbody .TimesTable_timeRowSelected__YXMat td:nth-child(14)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-12__2ZGJp tbody .TimesTable_timeRowSelected__YXMat td:nth-child(15)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-13__nvaQz tbody .TimesTable_timeRowSelected__YXMat td:nth-child(16)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-14__18mQ6 tbody .TimesTable_timeRowSelected__YXMat td:nth-child(17)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-15__3LgDY tbody .TimesTable_timeRowSelected__YXMat td:nth-child(18)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-16__13oqy tbody .TimesTable_timeRowSelected__YXMat td:nth-child(19)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-17__1fNqb tbody .TimesTable_timeRowSelected__YXMat td:nth-child(20)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-18__1Pd-9 tbody .TimesTable_timeRowSelected__YXMat td:nth-child(21)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-19__Okfq8 tbody .TimesTable_timeRowSelected__YXMat td:nth-child(22)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-20__3n1gl tbody .TimesTable_timeRowSelected__YXMat td:nth-child(23)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-21__143NO tbody .TimesTable_timeRowSelected__YXMat td:nth-child(24)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-22__sxNpi tbody .TimesTable_timeRowSelected__YXMat td:nth-child(25)::before,
.TimesTable_timeTable-true__1NOyf.TimesTable_times-column-23__14Nr_ tbody .TimesTable_timeRowSelected__YXMat td:nth-child(26)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-0__3_MIq tbody tr td:nth-child(3)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-1__BXrYA tbody tr td:nth-child(4)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-2__dCLjd tbody tr td:nth-child(5)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-3__26S8V tbody tr td:nth-child(6)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-4__3Voan tbody tr td:nth-child(7)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-5__2U9NI tbody tr td:nth-child(8)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-6__cN1fG tbody tr td:nth-child(9)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-7__1mDmH tbody tr td:nth-child(10)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-8__2gdd2 tbody tr td:nth-child(11)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-9__zaFxt tbody tr td:nth-child(12)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-10__luobH tbody tr td:nth-child(13)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-11__1VWnW tbody tr td:nth-child(14)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-12__2ZGJp tbody tr td:nth-child(15)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-13__nvaQz tbody tr td:nth-child(16)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-14__18mQ6 tbody tr td:nth-child(17)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-15__3LgDY tbody tr td:nth-child(18)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-16__13oqy tbody tr td:nth-child(19)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-17__1fNqb tbody tr td:nth-child(20)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-18__1Pd-9 tbody tr td:nth-child(21)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-19__Okfq8 tbody tr td:nth-child(22)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-20__3n1gl tbody tr td:nth-child(23)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-21__143NO tbody tr td:nth-child(24)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-22__sxNpi tbody tr td:nth-child(25)::before,
.TimesTable_timeTable-false__2WSrF.TimesTable_times-column-23__14Nr_ tbody tr td:nth-child(26)::before,
.TimesTable_timeTable-false__2WSrF .TimesTable_timeRowSelected__YXMat td::before {
  position: absolute;
  content: '';
  display: inline-block;
  top: 50%;
  left: 50%;
  width: 50%;
  height: 50%;
  border-radius: 50%;
  background-color: #fbaf16;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}
.TimesTable_timeRowSelected__YXMat td:nth-child(1)::before,
.TimesTable_timeRowSelected__YXMat td:nth-child(2)::before,
.TimesTable_timeRowSelected__YXMat td:nth-child(1)::before,
.TimesTable_timeRowSelected__YXMat td:nth-child(2)::before,
.TimesTable_hoursCheckbox__2hAP6::before {
  display: none;
}

.Ages_targetingSelectDiv__28gGl {
  height: 80px;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: 0px;
  display: -webkit-flex;
  display: flex;
  margin-right: 20px;
  padding: 0.4rem 0.5rem;
  font-size: 0.7rem;
}

.Ages_targetingSelectDiv__28gGl > p:nth-child(2) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Ages_targetingPreviewDiv__272uX {
  height: 80px;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.Ages_targetingSearchItem__1w0zb {
  margin: 0px 90px 0px 20px;
  width: 250px;
}

.Ages_countDisplay__1NIJa {
  padding: 1px 5px;
  font-weight: 500;
  color: #fff;
  background-color: #4a90e2;
  border-radius: 3px;
}

.Ages_targetingBoxHour__2qs40 .ant-slider-with-marks {
  margin-bottom: 10px;
}
.Ages_targetingBoxHour__2qs40 {
  height: 243px;
  overflow-x: auto;
  margin-right: 20px;
  border: 1px solid #cccccc;
  padding: 0.7rem 0.5rem;
}
.Ages_targetingBoxHourText__2bCRe {
  color: rgb(104, 104, 104);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.Ages_targetingBoxHourPreview__3BMiJ {
  overflow-x: scroll;
  border: 1px solid #cccccc;
  height: 243px;
}
.Ages_targetingBoxHourPreview__3BMiJ > p {
  color: rgb(104, 104, 104);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  font-weight: 500;
  height: 70%;
  font-size: 0.8rem;
  padding: 0.7rem 0.5rem;
}

.Ages_selectionTag___FULu {
  height: 24px;
  display: inline-block;
  border: 1px solid #1990ff;
  border-radius: 2px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-left: 0.5rem;
  margin-top: 0.4rem;
  color: #1990ff;
  background-color: #1990ff1a;
  transition: opacity 0.3s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.Ages_headTabInfo__3uMOi label.ant-radio-wrapper {
  font-weight: 400;
  font-size: 0.85rem;
  /* color: red; */
}

.Ages_radioSuperFresh__2Bqy4 {
  margin-left: 0.3rem;
  border: 1px solid #faad2d;
  font-weight: 500;
  padding: 0.15rem 0.3rem;
  border-radius: 4px;
  color: #faad2d;
  font-size: 0.65rem;
  background-color: #faab2d0f;
}

.Ages_countBox__3AMVj {
  color: #4a90e2;
}
.Ages_targetingSelectBox__cXJ32 {
  width: 63%;
}
.Ages_targetingPreviewBox__2fMBX {
  width: 37%;
}
.Ages_headTabInfo__3uMOi {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 7px;
}
.Ages_headTabInfo__3uMOi > p {
  margin-bottom: 8.28px;
}
.Ages_headTabInfo__3uMOi > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 600;
}
.Ages_headTabInfo__3uMOi > div > p {
  font-size: 1rem;
  margin: 0px;
  padding-left: 0.4rem;
}
.Ages_headTabInfo__3uMOi > p {
  color: rgb(103, 103, 103);
}

@media (max-width: 1100px) {
  .Ages_targetingSelectContainer__v9-3k {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }
  .Ages_targetingSelectBox__cXJ32 {
    width: 90%;
    margin: 0 auto;
  }
  .Ages_targetingPreviewBox__2fMBX {
    width: 90%;
    margin: 0 auto;
  }
  .Ages_targetingSelectDiv__28gGl {
    margin-right: 0px;
  }
}
@media (max-width: 1580px) {
  .Ages_targetingSelectDiv__28gGl {
    font-size: 0.7rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .Ages_targetingSelectDiv__28gGl > p {
    margin-bottom: 0rem;
    max-width: 100%;
  }
}

.TrafficSources_targetingSelectDiv__1wPb9 {
  height: 80px;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  margin-right: 20px;
}
.TrafficSources_targetingSelectDivV2__1W-7R {
  color: rgb(73, 73, 73);
  height: 80px;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: none;
  margin-right: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px;
}

.TrafficSources_targetingSelectDivV2__1W-7R > p {
  margin-bottom: 0px;
  font-weight: 500;
}

.TrafficSources_validationIssue__2Dmqd {
  color: rgb(189, 28, 28);
}

.TrafficSources_targetingPreviewDiv__2BOjv {
  height: 80px;
  background: #fbfbfd 0% 0% no-repeat padding-box;
  border: 1px solid #cccccc;
  border-bottom: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}

.TrafficSources_targetingPreviewDiv__2BOjv > h3 {
  margin: 0;
}

.TrafficSources_targetingSearchItem__3TgDY {
  margin: 0px 90px 0px 20px;
  width: 250px;
}
.TrafficSources_countDisplay__1W1nQ {
  padding: 1px 5px;
  font-weight: 500;
  color: #fff;
  background-color: #4a90e2;
  border-radius: 3px;
}

.TrafficSources_countBox__3YNj9 {
  color: #4a90e2;
}

.TrafficSources_targetingSelectBox__1qFEB {
  width: 63%;
}
.TrafficSources_targetingPreviewBox__21WxK {
  width: 37%;
}

.TrafficSources_headTabInfo__x2E8j {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.TrafficSources_headTabInfo__x2E8j > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 600;
}
.TrafficSources_headTabInfo__x2E8j > div > p {
  font-size: 1rem;
  margin: 0px;
  padding-left: 0.4rem;
}
.TrafficSources_headTabInfo__x2E8j > p {
  color: rgb(103, 103, 103);
}

.TrafficSources_proceedButton__23C-f {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin-top: 20px;
  border-radius: 4px;
  background-color: #4090f7;
}

.TrafficSources_subIdInput__3WYbn {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.TrafficSources_subIdInput__3WYbn .ant-select {
  margin-right: 1rem;
}

@media (max-width: 1100px) {
  .TrafficSources_targetingSelectContainer__3A5kn {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
  }
  .TrafficSources_targetingSelectBox__1qFEB {
    width: 90%;
    margin: 0 auto;
  }
  .TrafficSources_targetingPreviewBox__21WxK {
    width: 90%;
    margin: 0 auto;
  }
  .TrafficSources_targetingSelectDiv__1wPb9 {
    margin-right: 0px;
  }
}

.NewCreatives_newCreativeButton__3ocnw {
  margin-top: 20px;
  border-radius: 4px;
  background-color: #4090f7;
  border-color: #4090f7;
  padding-right: 1.3rem;
  padding-left: 1.3rem;
}

.NewCreatives_finishCreativeButton__PNxYK {
  border-radius: 4px;
  margin-top: 20px;
  color: seagreen;
  border-color: seagreen;
}

.NewCreatives_actionButtonsCreative__1tPXy {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.NewCreatives_creativesContentWrapper__2xQOf {
  min-height: 550px;
}

.Creatives_creativesTable__1joTL {
  width: 100%;
}
.Creatives_creativesTable__1joTL .ant-table-thead > tr > th {
  /* background-color: #ffffff; */
  color: #4d4f5c;
  font-weight: 600;
}

.Creatives_actionsButton__cRNgn {
  border: none;
  padding: 5px;
}

.Creatives_statusSpan__2jHH5 {
  color: white;
  display: block;
  padding: 0.2rem 0.8rem;
  border-radius: 7px;
  text-align: center;
}
.Creatives_newCreativeButton__1njnJ {
  margin-top: 1rem;
  color: #40a9ff;
  background-color: white;
  box-shadow: none;
  border: none;
}
.Creatives_campaignTypeModal__3lQ4X {
  width: 80vw !important;
  height: 80vh !important;
  margin-right: 100px;
}
.Creatives_campaignTypePushCpa__qgkPC {
  color: #32b66b;
}
.Creatives_campaignTypePUSH__34cka {
  color: #d95c5c;
}
.Creatives_campaignTypeNATIVE__3D7ep {
  color: #3067ce;
}
.Creatives_campaignTypeSMARTLINK__3lp2V {
  color: #781edf;
}

.Creatives_status__1V26V {
  display: block;
  text-align: center;
  text-transform: capitalize;
  min-width: 90px;
  font-weight: 500;
  border: 1px solid;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
}
.Creatives_statusactive__2GYq7 {
  background-color: #02875b1a;
  border-color: #02875b;
  color: #02875b;
}
.Creatives_statusinactive__34zBX {
  display: block;
  text-align: center;
  text-transform: capitalize;
  min-width: 90px;
  font-weight: 500;
  border: 1px solid;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
}
.Creatives_statuspending__2Iyan {
  background-color: #faad141a;
  border-color: #faad14;
  color: #faad14;
}
.Creatives_statuspaused__3_7TQ {
  background-color: #d1d1d11a;
  border-color: #d1d1d1;
  color: #d1d1d1;
}

.Creatives_campaignType__epFfS {
  /* width: 120px; */
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Creatives_campaignType__epFfS {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  font-weight: bold;
  /* font-size: 1rem; */
}

.Creatives_deletedRow__1dTh9 {
  display: none;
}

.Creatives_creativeName__2SVm6 {
  width: 220px;
}

.Creatives_creativeBuilderLeft__12nWI {
  width: 50%;
  padding-right: 10px;
}
.Creatives_creativePreviewRight__LgpLJ {
  width: 50%;
}

@media (max-width: 1100px) {
  .Creatives_newCreativeContainer__2B8PP {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .Creatives_creativeBuilderLeft__12nWI {
    width: 90%;
    padding: 0;
  }
  .Creatives_creativePreviewRight__LgpLJ {
    width: 90%;
    margin-bottom: 1rem;
  }
  .Creatives_creativeSaveContainer__-sxTf {
    width: 90%;
    margin: 0 auto;
  }
}

.Generator_generatorModal__17JGA {
  width: 100%;
  height: 100vh;
}

.Generator_creativeGridContainer__3EJSf {
  height: calc(100vh - 103px);
  overflow: hidden;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

@media only screen and (max-width: 1150px) {
  .Generator_creativeGridContainer__3EJSf {
    height: auto;
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.CreativeVariations_creativeVariations__3ev2o {
  -webkit-flex: 1 1;
          flex: 1 1;
  border: 1px solid #cccccc;
  position: relative;
  margin-right: 12px;
  overflow-x: auto;
}

.CreativeVariations_headerTitle__3zuMf {
  background-color: #fbfbfd;
  height: 80px;
  color: #4d4f5c;
  border-bottom: 1px solid #cccccc;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.CreativeVariations_headerTitle__3zuMf > p {
  margin: 0;
  padding-left: 1rem;
}
.CreativeVariations_headerTitle__3zuMf span {
  color: #a6a6a6;
  font-size: 0.8rem;
}

.CreativeVariations_variationActions__2ttMW {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 0.5rem 1rem 0 1rem;
}

.CreativeVariations_variationActions__2ttMW .ant-btn-dashed {
  color: rgb(176, 176, 176);
}

.CreativeVariations_variationsGrid__2-84P {
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
}

.CreativeVariations_gridSizeBox__abNYj {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.CreativeVariations_maximumTrigger__3dXsE {
  color: rgb(216, 57, 57);
}

/* CREATIVE SAMPLE */
.CreativeVariations_sampleCreative__2LIld {
  color: #373737;
  position: relative;
  padding: 0.9rem 1.6rem 0.9rem 0.9rem;
  background-color: #e1e1e195;
  border-radius: 5px;
  cursor: pointer;
  border: 2px solid #ffffff;
  overflow: hidden;
}

.CreativeVariations_limitExceededSample__2yckN {
  border: 2px solid rgb(206, 130, 130);
}

.CreativeVariations_selectedCreative__3-Rpw {
  background-color: #4090f7;
  color: #fff;
}

.CreativeVariations_sampleTitle__1enhn {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.CreativeVariations_sampleCreativeContent__13dNL {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.CreativeVariations_imageSamplePreview__1clfS {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.CreativeVariations_imageSamplePreview__1clfS > div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 10px;
}
.CreativeVariations_imageSamplePreview__1clfS > div > p {
  font-size: 0.9rem;
  font-weight: 500;
}
.CreativeVariations_imageSamplePreview__1clfS > div > img {
  height: 50px;
  width: 50px;
  min-width: 50px;
  object-fit: cover;
  /* border-radius: 7px; */
}
.CreativeVariations_imageSamplePreview__1clfS > div > p {
  margin: 0;
  padding-left: 0.3rem;
}

.CreativeVariations_imageSamplePreview__1clfS > img {
  /* border-radius: 7px; */
  padding-top: 0.7rem;
  width: 100%;
  height: 105px;
  object-fit: cover;
}
.CreativeVariations_sampleCreativeContent__13dNL > p {
  font-size: 0.8rem;
  margin: 0.2rem 0;
}
img.CreativeVariations_iconVariation__yrDzy {
  width: 50%;
  height: auto;
}

.CreativeVariations_sampleCreativeActions__1OpPO {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  position: absolute;
  top: 0.9rem;
  right: 0.4rem;
}
.CreativeVariations_sampleCreativeActions__1OpPO > div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.CreativeVariations_actionIcon__3sEla {
  font-size: 0.8rem;
  margin-bottom: 6px;
  cursor: pointer;
  transition: color 0.2s;
}
.CreativeVariations_actionIcon__3sEla:hover {
  color: rgb(74, 74, 189);
}

@media only screen and (max-width: 1380px) {
  .CreativeVariations_headerTitle__3zuMf {
    height: auto;
  }
  .CreativeVariations_headerTitle__3zuMf > p {
    padding: 1rem;
  }
}
@media only screen and (max-width: 1240px) {
  .CreativeVariations_variationActions__2ttMW {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .CreativeVariations_variationActions__2ttMW div {
    margin-bottom: 0.2rem;
  }
}
@media only screen and (max-width: 1150px) {
  .CreativeVariations_creativeVariations__3ev2o {
    margin-left: 12px;
    margin-bottom: 12px;
  }
  .CreativeVariations_variationActions__2ttMW {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
}
@media only screen and (max-width: 620px) {
  .CreativeVariations_variationActions__2ttMW {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.CreativeBuilder_creativeBuilder__upXq6 {
  -webkit-flex: 1 1;
          flex: 1 1;
  border: 1px solid rgb(204, 204, 204);
  margin-right: 12px;
  margin-left: 12px;
}

.CreativeBuilder_headerTitle__3bGhB {
  background-color: #fbfbfd;
  border-bottom: 1px solid rgb(204, 204, 204);
  height: 80px;
  color: #4d4f5c;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.CreativeBuilder_headerTitle__3bGhB > p {
  margin: 0;
  padding-left: 1rem;
}
.CreativeBuilder_headerTitle__3bGhB span {
  color: #a6a6a6;
}

.CreativeBuilder_builderLabel__tZuOS {
  margin: 0.7rem 0.9rem 0.2rem 1rem;
  font-size: 0.8rem;
}
.CreativeBuilder_builderLabel__tZuOS > span {
  color: rgb(191, 191, 191);
}
.CreativeBuilder_builderLabel__tZuOS > span > span {
  color: rgb(255, 196, 0);
}

.CreativeBuilder_buttonUpload__1-Tfa {
  color: #4a90e2;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: 160px;
  margin: 0 auto;
  border: 1px solid #4a90e2;
  border-radius: 5px;
  padding: 0.5rem 1rem;
}
.CreativeBuilder_buttonUpload__1-Tfa > span {
  padding-left: 0.5rem;
}

.CreativeBuilder_pastUrlBox__3oLT9 {
  margin-top: 1rem;
}

.CreativeBuilder_postbackBtn__3RZmz {
  padding: 0 1.5rem;
  float: right;
}
.CreativeBuilder_labelUrl__1cthK {
  color: rgb(99, 99, 99);
  margin-top: 1rem;
  margin-bottom: 0.3rem;
}

.CreativeBuilder_dynamicDelBtn__3uP18 {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s;
}
.CreativeBuilder_dangerText__9Tyvl {
  border: 1px solid #ff4d4f !important;
  /* box-shadow: 0 0 0 2px rgb(255 77 79 / 20%); */
}
.CreativeBuilder_counterBox__2N4C_ {
  text-align: right;
  white-space: nowrap;
  min-width: 45px;
  max-width: 45px;
  margin-top: 0.4rem;
  font-size: 0.7rem;
  color: rgb(99, 99, 99);
}
.CreativeBuilder_dangerTextCounter__nl4aF {
  min-width: 45px;
  max-width: 45px;
  color: #ff4d4f !important;
  font-weight: bold;
}
.CreativeBuilder_dynamicDelBtn__3uP18:hover {
  color: #777;
}
.CreativeBuilder_dynamicDelBtn__3uP18[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

span.CreativeBuilder_requiredMark__wNgIm {
  color: rgb(237, 0, 0);
  font-size: 0.9rem;
}

/* IMAGE PREVIEW */

.CreativeBuilder_previeImageBox__3gzHx {
  padding: 0.5rem 0.3rem;
  margin: 0 0.8rem;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.CreativeBuilder_singleImagePreview__33oZM {
  width: 55px;
  height: 55px;
  margin: 4px;
  border-radius: 5px;
  position: relative;
}
.CreativeBuilder_singleImagePreview__33oZM > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.CreativeBuilder_singleImageDelete__cmWqe {
  position: absolute;
  color: #ffffff;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 5px;
  -webkit-justify-content: center;
          justify-content: center;
  cursor: pointer;
  transition: 0.2s;
}
.CreativeBuilder_singleImageDelete__cmWqe:hover {
  background-color: rgba(211, 50, 50, 0.871);
}

.CreativeBuilder_addNewImageBtn__m2qbo {
  width: 55px;
  height: 55px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin: 4px;
  color: rgb(183, 183, 183);
  border: 1px dashed rgb(183, 183, 183);
  border-radius: 5px;
  cursor: pointer;
}

.CreativeBuilder_deleteIcon__3gaqH {
  font-size: 1rem;
  background-color: rgba(211, 50, 50, 0.871);
  padding: 0.2rem;
  border-radius: 50%;
  -webkit-transform: translateX(22px) translateY(-22px);
          transform: translateX(22px) translateY(-22px);
  transition: all 0.2s;
}

.CreativeBuilder_singleImageDelete__cmWqe:hover .CreativeBuilder_deleteIcon__3gaqH {
  -webkit-transform: translateX(0px) translateY(0px);
          transform: translateX(0px) translateY(0px);
}

.CreativeBuilder_editSaveBtn__FSjIi {
  margin-left: 0.8rem;
}

.CreativeBuilder_addNewImageBtn__m2qbo:hover {
  opacity: 0.7;
}

.CreativeBuilder_macrosContent__LfDss .ant-divider-horizontal {
  margin: 0;
}
.CreativeBuilder_macrosContent__LfDss span {
  cursor: pointer;
}
.CreativeBuilder_macrosContent__LfDss span:hover {
  font-weight: bold;
}

.CreativeBuilder_headlinesBox__1wi5u {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 0 0.8rem 0rem 0.8rem;
}
.CreativeBuilder_headlinesBox__1wi5u .ant-input-affix-wrapper-focused,
.CreativeBuilder_headlinesBox__1wi5u .ant-input-affix-wrapper:focus,
.CreativeBuilder_headlinesBox__1wi5u .ant-input-affix-wrapper:hover,
.CreativeBuilder_headlinesBox__1wi5u .ant-input:focus,
.CreativeBuilder_headlinesBox__1wi5u .ant-input:hover {
  border-color: #d9d9d9;
  box-shadow: none;
}
.CreativeBuilder_headlinesBox__1wi5u .ant-input-affix-wrapper {
  /* border-top: none; */
  border-radius: 0px;
  margin-top: -2px;
}
.CreativeBuilder_headlinesBox__1wi5u .ant-input {
  border-radius: 2px 2px 0 0;
}
.CreativeBuilder_headlinesBox__1wi5u .ant-input-group-addon {
  cursor: pointer;
}
.CreativeBuilder_buttonWrapper__1tb7z {
  margin: 0rem 0.8rem 0.8rem 0.8rem;
}
.CreativeBuilder_buttonWrapper__1tb7z .ant-btn {
  color: #61636e;
  width: calc(100% - 45px);
  font-size: 0.75rem;
  height: 28px;
  margin-top: -2px;
  border-radius: 0 0 2px 2px;
}

.CreativeBuilder_macroItem__2mngD {
  cursor: pointer;
  display: inline-block;
  color: rgb(99, 99, 99);
  border: 1px solid rgb(229, 229, 229);
  padding: 0.25rem 0.6rem;
  margin-right: 0.7rem;
  margin-bottom: 0.5rem;
  border-radius: 40px;
  transition: all 0.3s;
}
.CreativeBuilder_macroItemSelected__1KpTD {
  cursor: pointer;
  display: inline-block;
  color: rgb(255, 196, 0);
  border: 1px solid rgb(255, 196, 0);
  padding: 0.25rem 0.6rem;
  margin-right: 0.6rem;
  margin-bottom: 0.5rem;
  border-radius: 40px;
  transition: all 0.3s;
}
.CreativeBuilder_macroItem__2mngD:hover {
  opacity: 0.7;
}
.CreativeBuilder_macroItemSelected__1KpTD:hover {
  opacity: 0.7;
}

.CreativeBuilder_macroInfoBtn__3Suok {
  padding: 0;
  margin: 0 1rem 1rem 1rem;
}
.CreativeBuilder_macroInfoBtn__3Suok > span {
  text-decoration: underline;
}

.CreativeBuilder_macroBadge__1nzZ3 sup {
  /* margin-top: 0.2rem;
  margin-right: 0.8rem; */
  top: 0.2rem;
  right: 0.8rem;
  background-color: #1890ff;
}

@media only screen and (max-width: 1150px) {
  .CreativeBuilder_creativeBuilder__upXq6 {
    margin-bottom: 12px;
  }
}

.CreativePreview_creativePreview__1i9KG {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-left: 12px;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}

.CreativePreview_contentPreview__2vIOp {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 0.8rem;
}

.CreativePreview_contentPreview__2vIOp > img {
  height: 60px;
  width: 60px;
  object-fit: contain;
}

.CreativePreview_pushLayout__2HNGQ {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
}

.CreativePreview_previewContainer__1X4vu > div {
  max-width: 350px;
  width: 100%;
  padding: 0.8rem;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 5px;
}

.CreativePreview_creativePreviewMain__wuBas {
  width: 100%;
  border: 1px solid rgb(204, 204, 204);
  min-height: 400px;
}

.CreativePreview_headerTitle__3RxtB {
  background-color: #fbfbfd;
  border-bottom: 1px solid rgb(204, 204, 204);
  height: 80px;
  color: #4d4f5c;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.CreativePreview_headerTitle__3RxtB > p {
  margin: 0;
  padding-left: 1rem;
}
.CreativePreview_headerTitle__3RxtB span {
  color: #a6a6a6;
}

.CreativePreview_previewContainer__1X4vu {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 2rem;
}
.CreativePreview_imagePreview__3zY-j {
  width: 100%;
  height: 200px;
  object-fit: contain;
}
.CreativePreview_contentPreview__2vIOp > div > p:nth-child(1) {
  color: rgb(67, 67, 67);
  margin: 0.2rem 0 0 0.5rem;
  font-weight: 500;
  font-size: 1rem;
}
.CreativePreview_contentPreview__2vIOp > div > p:nth-child(2) {
  color: rgb(177, 177, 177);
  margin: 0rem 0.5rem;
  font-size: 0.8rem;
}

.CreativePreview_noSelection__35dsO {
  color: #a9a9a9;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 80%;
  text-align: center;
}

.CreativePreview_creativesSaveBtn__BBDCy {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  background-color: #4090f7;
  border-color: #4090f7;
}

@media only screen and (max-width: 1150px) {
  .CreativePreview_creativePreview__1i9KG {
    margin-right: 12px;
  }
  .CreativePreview_creativePreviewMain__wuBas {
    margin-bottom: 12px;
  }
  .CreativePreview_noSelection__35dsO {
    margin-top: 12px;
  }
}

.VariationPreview_notPreviewContainer__2Dw10 {
  margin: 3.6rem 1rem 1rem 1rem;
}

.VariationPreview_sendNotifText__1YWKf {
  font-weight: 500;
  color: #4a4a4a;
  font-size: 1rem;
}

.VariationPreview_deviceBackground__1Aams .VariationPreview_iconTextWrapper__1Fo-t {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding-top: 0.3rem;
  width: 100%;
}
.VariationPreview_deviceBackgroundWindows__32iYM .VariationPreview_iconTextWrapper__1Fo-t {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  padding: 0.5rem;
}

.VariationPreview_dynamicIcon__kI5pc {
  width: 42px;
  height: 42px;
  object-fit: contain;
}

.VariationPreview_dynamicImage__2cjOD {
  margin-top: 0.8rem;
  object-fit: contain;
  width: 100%;
  min-height: 100px;
}
/* .deviceBackgroundWindows .dynamicImage {
  object-fit: cover;
  width: 100%;
  height: 120px;
} */
.VariationPreview_dynamicTextWrapper__MQkQD {
  font-weight: 500;
  padding-left: 0.5rem;
  -webkit-flex: 1 1;
          flex: 1 1;
  overflow: hidden;
  white-space: nowrap;
}

.VariationPreview_dynamicTextWrapper__MQkQD > div:first-child {
  font-size: 0.85rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.VariationPreview_dynamicTextWrapper__MQkQD > div:last-child {
  font-size: 0.7rem;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.VariationPreview_mainNotificationPreview__81cB5 {
  /* min-height: 320px; */
  /* width: 320px; */
  background-color: #f0f2f5;
  box-shadow: 0px 2px 10px #0000001c inset;
  padding: 0.8rem;
  border-radius: 0.5rem;
}

.VariationPreview_deviceBackground__1Aams {
  min-height: 332.39px;
  height: 100%;
  padding: 2rem 0;
  background-size: cover;
  background-position: top right;
}

.VariationPreview_deviceBackgroundWindows__32iYM {
  position: relative;
  min-height: 332.39px;
  height: 100%;
  background-size: cover;
  background-position: bottom right;
}

.VariationPreview_deviceBackground__1Aams .VariationPreview_coreNotifBox__2Ax5Q {
  background-color: #ffffff;
  padding: 0.7rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  width: 85%;
  margin: 0 auto;
  border-radius: 0.5rem;
  box-shadow: 0px 2px 10px #0000001c;
}

.VariationPreview_deviceBackgroundWindows__32iYM .VariationPreview_coreNotifBox__2Ax5Q {
  background-color: #1b2026;
  color: #ffffff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-align-items: center;
          align-items: center;
  width: 75%;
  position: absolute;
  bottom: 2.3rem;
  right: 0.7rem;
  box-shadow: 0px 2px 10px #0000001c;
}

.VariationPreview_notPreviewContainer__2Dw10 .ant-radio-group {
  width: 100%;
  margin-bottom: 1rem;
}

.VariationPreview_notPreviewContainer__2Dw10 .ant-radio-button-wrapper {
  width: 50%;
  text-align: center;
}

.VariationPreview_notPreviewContainer__2Dw10 .ant-radio-button-wrapper:first-child {
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}
.VariationPreview_notPreviewContainer__2Dw10 .ant-radio-button-wrapper:last-child {
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

@media (max-width: 900px) {
  .VariationPreview_notPreviewContainer__2Dw10 {
    display: none;
    /* margin: 1rem 0rem 0rem 0rem; */
  }
}

.ContextualGen_creativeGridContainer__2bzWc {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
}

.ContextualGen_creativeBuilder__cgIev {
  grid-column: 1 / 4;
  grid-row: 1 / 3;
  border: 1px solid rgb(204, 204, 204);
  margin-right: 12px;
  margin-left: 12px;
}
.ContextualGen_creativePreview__2MrRG {
  grid-column: 4 / 6;
  border: 1px solid rgb(204, 204, 204);
  margin-right: 12px;
  margin-left: 12px;
}
.ContextualGen_finalContainer__2fszm {
  grid-column: 4 / 6;
  margin-right: 12px;
  margin-left: 12px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.ContextualGen_finalContainer__2fszm > p {
  color: #989898;
  padding: 1rem 0.3rem;
}

.ContextualGen_adPreviewScreen__2UDyY {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  color: rgb(148, 148, 148);
  padding: 0.7rem 1rem;
  /* width: 65%; */
  max-width: 680px;
  margin: 0 auto;
}

.ContextualGen_headerTitle__3a3Di {
  background-color: #fbfbfd;
  border-bottom: 1px solid rgb(204, 204, 204);
  height: 80px;
  color: #4d4f5c;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.ContextualGen_headerTitle__3a3Di > p {
  margin: 0;
  padding-left: 1rem;
}
.ContextualGen_headerTitle__3a3Di span {
  color: #a6a6a6;
}

.ContextualGen_formVariationsHeader__1o9Ug,
.ContextualGen_inputVariant__1rfUq {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.ContextualGen_formVariationsHeader__1o9Ug > p,
.ContextualGen_inputVariant__1rfUq .ant-form-item {
  -webkit-flex: 1 1;
          flex: 1 1;
}
.ContextualGen_inputVariant__1rfUq .ant-form-item {
  margin-right: 1rem;
}
.ContextualGen_inputVariant__1rfUq .ant-form-item-explain {
  margin-left: 10px;
}

.ContextualGen_inputLandingUrl__1Q0zU {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.ContextualGen_formContainer__3fThR {
  min-height: 540px;
  padding: 1rem;
}

.ContextualGen_headtitle__1vVkj {
  color: #323232;
  font-size: 1rem;
  margin-bottom: 0.4rem;
  font-weight: bold;
}

.ContextualGen_adPreviewContentBox__3loVD {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.ContextualGen_adPreviewContentBox__3loVD > div {
  position: relative;
  -webkit-flex-basis: 49%;
          flex-basis: 49%;
  margin-right: 1%;
  margin-bottom: 0.2rem;
  padding: 10px 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
}
.ContextualGen_previewAd__2NDoD {
  background-color: #fafafa;
  color: #4d4f5c;
}

.CampaignReview_generalInfoHeader__3fsBU,
.CampaignReview_generalInfoRows__2RO09 {
  display: block;
  margin-bottom: 0.7rem;
  width: 100%;
}

.CampaignReview_generalInfoRows__2RO09 {
  margin: 0px 0px 15px 5px;
}

.CampaignReview_statusActiveSpan__106mW {
  border: 1px solid #1990ff;
  border-radius: 2px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-left: 0.5rem;
  margin-top: 0.4rem;
  color: #1990ff;
  display: inline-block;
  background-color: #1990ff1a;
  text-transform: capitalize;
}
.CampaignReview_statusInactiveSpan__2HBDw {
  display: none;
}

.CampaignReview_selectionTag__1XumI {
  height: 24px;
  display: inline-block;
  border: 1px solid #1990ff;
  border-radius: 2px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  color: #1990ff;
  background-color: #1990ff1a;
  transition: opacity 0.3s;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.CampaignReview_generalInfoLabel__2yOoQ {
  width: 200px;
  display: inline-block;
  color: #878787;
}
.CampaignReview_generalInfoValue__2R-qy {
  font-weight: 500;
  text-transform: capitalize;
}

.CampaignReview_targetingInfoHeader__1OQWM {
  width: 100%;
  margin-bottom: 10px;
}
.CampaignReview_targetingInfoQuantity__rsIiM {
  width: 100%;
  margin-bottom: 5px;
}
.CampaignReview_targetingInfoType__qFCxI {
  text-transform: capitalize;
  /* width: 100px; */
  margin-right: 0.5rem;
  display: inline-block;
  font-weight: 500;
  color: darkgrey;
}
.CampaignReview_targetingInfoValue__bne-n {
  border: 1px solid #1990ff;
  border-radius: 2px;
  padding-left: 0.3rem;
  padding-right: 0.3rem;
  margin-left: 0.5rem;
  margin-top: 0.4rem;
  color: #1990ff;
  display: inline-block;
  background-color: #1990ff1a;
  text-transform: capitalize;
}
.CampaignReview_targetingInfoValueList__3zZnE {
  text-transform: capitalize;
  background-color: #ffffff;
  color: #1890ff;
  font-weight: 500;
  padding: 2px 0px;
  border-radius: 5px;
  margin-right: 10px;
}

.CampaignReview_targetingInfoRow__2Da5e {
  width: 100%;
  margin-bottom: 20px;
}
.CampaignReview_statusSpan__11Jnp {
  color: white;
  background-color: #44915e;
  display: block;
  border-radius: 7px;
  text-align: center;
  width: 100px;
}
.CampaignReview_trackingInput__2xRAt {
  width: 80%;
  margin-bottom: 10px;
}
.CampaignReview_trackingParagraph__17GEP {
  margin-top: 0.5rem;
  color: darkgrey;
}
.CampaignReview_trackingParamSpan__3Vrz4 {
  color: orangered;
}

.CampaignReview_statusactive__s8ZmW {
  display: block;
  text-transform: capitalize;
  min-width: 90px;
  font-weight: 500;
  border: 1px solid;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
  background-color: #02875b1a;
  border-color: #02875b;
  color: #02875b;
  text-align: center;
}
.CampaignReview_statusinactive__1z_JN {
  display: block;
  text-transform: capitalize;
  min-width: 90px;
  font-weight: 500;
  border: 1px solid;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
  background-color: #d85c5c1a;
  border-color: #d85c5c;
  color: #d85c5c;
  text-align: center;
}

.CampaignReview_advertisingBudgetInfo__2FE4D {
  width: 200px;
  margin-right: 80%;
  margin-bottom: 10px;
}
.CampaignReview_createCampaignButton__2l149 {
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: 2rem;
  background-color: #70c040;
  border-color: #70c040;
  border-radius: 4px;
  color: white;
}
.CampaignReview_createCampaignButton__2l149:hover {
  border-color: #70c040;
  color: #70c040;
}
.CampaignReview_campaignTypePushCpa__N7kFU {
  color: #32b66b;
}
.CampaignReview_campaignTypePUSH__3_hb_ {
  color: #d95c5c;
}
.CampaignReview_campaignTypeNATIVE__NcNAP {
  color: #3067ce;
}
.CampaignReview_campaignTypeSMARTLINK__2KEDQ {
  color: #781edf;
}
.CampaignReview_campaignType__25QqW {
  width: 120px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.CampaignReview_campaignType__25QqW {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  font-weight: bold;
  /* font-size: 1rem; */
}

.CampaignReview_endActionBtns__34Z0d {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 50px;
}

.CampaignReview_reviewCampaignExtraBtn__3JTAW {
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  border-radius: 4px;
  color: rgb(63, 63, 63);
}

.NewCampaign_circleNumber__pyGzq {
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  color: white;
  line-height: 20px;
  background-color: #b2b2b2;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.NewCampaign_tabName__1mDHh {
  width: 165px;
  height: 23px;
  text-align: left;
  font: Regular 18px/51px Source Sans Pro;
  letter-spacing: 0px;
  opacity: 1;
  padding-left: 7px;
}

.NewCampaign_customTab__2IJ7d {
  padding-left: 0;
}

.NewCampaign_newCampaignTabsRow__E5Jkc .ant-tabs .ant-tabs-left-bar {
  color: #9d9d9d;
}

.NewCampaign_newCampaignTabsRow__E5Jkc .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: normal;
}
.NewCampaign_newCampaignTabsRow__E5Jkc .ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  margin: 0;
}
.NewCampaign_newCampaignTabsRow__E5Jkc .ant-tabs-nav .ant-tabs-tab-active .NewCampaign_tabNameDivCreatives__3DoIY {
  border: 1px solid #1890ff;
}

.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-btn {
  margin: 0 auto;
}

.NewCampaign_checkNotification__4YhWJ {
  font-size: 1.5rem;
  color: #c6d699;
  width: 20px;
  height: 22px;
  display: inline-block;
  -webkit-transform: translateY(-8px);
          transform: translateY(-8px);
}

.NewCampaign_warningNotification__eQRsl {
  position: absolute;
  right: -24px;
  top: -21px;
  font-size: 1.5rem;
  color: #f47562;
  display: inline-block;
  /* transform: translateY(-8px); */
}

.NewCampaign_closeBtnTop__3gcd- {
  border-color: #4a90e2;
  color: #4a90e2;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 4px;
}
.ant-tabs-nav .ant-tabs-tab-active .NewCampaign_tabNameDiv__r0M-F {
  color: #2964a9;
}
.ant-tabs-tab.ant-tabs-tab-disabled .NewCampaign_tabNameDiv__r0M-F {
  color: #c0c0c0;
}
.ant-tabs-nav .ant-tabs-tab-active .NewCampaign_circleNumber__pyGzq {
  background-color: #2964a9;
}
.ant-tabs-tab.ant-tabs-tab-disabled .NewCampaign_circleNumber__pyGzq {
  background-color: #c0c0c0;
}

.NewCampaign_tabNameDiv__r0M-F {
  color: rgb(85, 85, 85);
  display: -webkit-flex;
  display: flex;
  padding: 7px 0px;
  -webkit-align-items: center;
          align-items: center;
}
.NewCampaign_tabNameDivCreatives__3DoIY {
  position: relative;
  border: 1px solid #d6d6d6;
  color: rgb(99, 99, 99);
  border-radius: 5px;
  display: -webkit-flex;
  display: flex;
  padding: 10px 2rem 10px 2rem;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.NewCampaign_newStepSpan__1dBN9 {
  width: 44px;
  background: #4a90e2 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #ffffff;
  text-align: center;
  margin: 0 20px;
}
.NewCampaign_headerStepRight__bZFeh {
  display: -webkit-flex;
  display: flex;
  -webkit-align-content: center;
          align-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 0.5em;
  margin-left: 20px;
}
.NewCampaign_headerCurrentTabText__3SDh3 {
  color: #4d4f5c;
  font-size: 1.3rem;
}

.NewCampaign_topCampaignTitle__PA38i {
  min-width: 235px;
  color: #4d4f5c;
  font-size: 1.3rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 7px;
}
.NewCampaign_topCampaignTitle__PA38i > p {
  margin: 0;
}
.NewCampaign_topCampaignTitle__PA38i > img {
  margin: 0.5rem 0.7rem 0.5rem 1.5rem;
  width: 30px;
}

.NewCampaign_newCampaignTabsRow__E5Jkc .ant-tabs.ant-tabs-left {
  width: 100%;
}

.NewCampaign_saveCampaignBtn__1mIQR {
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: 2rem;
  background-color: #70c040;
  border-color: #70c040;
  border-radius: 4px;
  color: white;
}

.NewCampaign_saveCampaignBtn__1mIQR:hover {
  border-color: #70c040;
  color: #70c040;
}

.ModalStep_campaignTypeModal__1chkY h1 {
  font-size: 1.3rem;
  color: rgb(89, 89, 89);
  margin: 0;
}
.ModalStep_campaignTypeModal__1chkY h3 {
  margin: 0;
}

.ModalStep_campaignTypeModalHeader__mrxLH {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  text-align: center;
}

.ModalStep_campaignTypesDivs__1F31t {
  padding: 20px;
  text-align: center;
  min-height: 430px;
}
.ModalStep_campaignTypeSection__1GwgR {
  position: relative;
  height: 100%;
  border: 1pt solid #4090f7;
  border-radius: 7px;
  padding: 20px 10px;
  position: relative;
}

.ModalStep_adTypeHightlight__3HEXk {
  position: absolute;
  top: -15px;
  right: -30px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}

.ModalStep_adTypeHightlight__3HEXk span:nth-child(1) {
  color: #faad14;
  -webkit-transform: translateY(12px) rotate(-20deg);
          transform: translateY(12px) rotate(-20deg);
}
.ModalStep_adTypeHightlight__3HEXk span:nth-child(2) {
  color: #faad14;
}
.ModalStep_adTypeHightlight__3HEXk span:nth-child(3) {
  color: #faad14;
  -webkit-transform: translateY(12px) rotate(20deg);
          transform: translateY(12px) rotate(20deg);
}

.ModalStep_campaignTypeSection__1GwgR h2 {
  color: rgb(72, 72, 72);
  font-weight: 600;
  margin-bottom: 0;
  font-size: 1.2rem;
}

.ModalStep_campaignTypeSection__1GwgR img {
  width: 70%;
  height: 120px;
  object-fit: contain;
  opacity: 0;
  transition: opacity 0.5s;
}
.ModalStep_campaignTypeSection__1GwgR h3 {
  font-size: 0.9rem;
  font-weight: 600;
  height: 40px;
}
.ModalStep_campaignTypesDesc__3MZiQ {
  text-align: left;
  padding-top: 0.3rem;
  font: Bold 18px/13px;
  color: #2f6fb6;
  margin: 20px 0px 10px 0px;
  text-align: center;
}
.ModalStep_campaignTypesSelectBtn__EgFFw {
  width: 220px;
  height: 35px;
  background: #4090f7 0% 0% no-repeat padding-box;
  border-radius: 5px;
}
.ModalStep_loadingSelectCampaign__36GDj {
  min-height: 430px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.ModalStep_formatDescription__31ha7 {
  display: -webkit-flex;
  display: flex;
  padding: 0.8rem 0.8rem 0rem 0.8rem;
}
.ModalStep_formatDescription__31ha7 > span {
  display: inline-block;
  margin-top: 0.5rem;
  height: 8px;
  min-width: 8px;
  border-radius: 50%;
  background-color: #faad14;
}
.ModalStep_formatDescription__31ha7 > p {
  font-size: 0.85rem;
  color: rgb(141, 141, 141);
  padding: 0rem 0.5rem;
  text-align: left;
  height: 100px;
}

@media screen and (max-width: 1366px) {
  .ModalStep_campaignTypeSection__1GwgR {
    padding: 20px 10px;
  }
}

@media screen and (max-width: 991px) {
  .ModalStep_campaignTypeSection__1GwgR {
    display: -webkit-flex;
    display: flex;
  }
  .ModalStep_campaignTypesDivs__1F31t {
    padding: 20px;
    text-align: center;
    min-height: 180px;
  }
  .ModalStep_campaignTypeSection__1GwgR p {
    height: auto;
  }
  .ModalStep_campaignTypeSection__1GwgR h3 {
    height: auto;
    margin: 0;
  }
  .ModalStep_campaignTypeSection__1GwgR > div:first-child {
    min-width: 180px;
  }
}

@media screen and (max-width: 720px) {
  .ModalStep_campaignTypeSection__1GwgR {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
}

.BudgetAndBids_generalWrapper__1yQU9 {
  width: 100%;
  min-width: 300px;
}
.BudgetAndBids_generalContentWrapper__18z2z {
  width: 100%;
  max-width: 500px;
  min-height: 550px;
  /* max-height: 550px; */
}
.BudgetAndBids_statusRadioButton__3cC6d {
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
  -webkit-align-items: center;
          align-items: center;
}
.BudgetAndBids_radioButtonLabels__2PvKm {
  width: 75px;
  text-align: center;
}

.BudgetAndBids_autoManualPricing__1ESar .ant-form-item-label > label {
  margin-left: 30px;
}

.BudgetAndBids_datepickerStart__2dA7A,
.BudgetAndBids_datepickerEnd__1oCzn {
  width: 50%;
  display: inline-block;
}
.BudgetAndBids_datepickerStart__2dA7A .ant-col.ant-form-item-control {
  margin-right: 10px;
}
.BudgetAndBids_datepickerStart__2dA7A .ant-picker,
.BudgetAndBids_datepickerEnd__1oCzn .ant-picker {
  width: 100%;
}

.BudgetAndBids_statusInfo__1snaS {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 1rem;
}
.BudgetAndBids_statusInfo__1snaS > p {
  margin: 0;
  color: #000000d9;
  padding-right: 0.5rem;
}

.BudgetAndBids_manualValueInput__2jksx {
  display: inline-block;
  width: 150px;
  position: absolute;
  margin-top: 30px;
  margin-left: 10px;
}
.BudgetAndBids_useSuggestedBid__1pfSk {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.BudgetAndBids_budgetAndPeriod__3_je1 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.BudgetAndBids_campaignBid__NjIyw .ant-input-number {
  width: 100px;
  margin-right: 8px;
}
.BudgetAndBids_campaignBudget__TTTo0 {
  display: inline-block;
  width: 48%;
  /* margin-right: 7%; */
}
.BudgetAndBids_campaignBudget__TTTo0 input {
  min-height: 32px;
}
.BudgetAndBids_campaignBudgetPeriod__vBXeU {
  display: inline-block;
  width: 48%;
}
.BudgetAndBids_proceedButton__WzZ69 {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin-top: 20px;
  border-radius: 4px;
  background-color: #4090f7;
}

@media (max-width: 1100px) {
  .BudgetAndBids_generalWrapper__1yQU9 {
    width: 95%;
    margin: 0 auto;
  }
}

@media (max-width: 760px) {
  .BudgetAndBids_useSuggestedBid__1pfSk {
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 24px;
  }
  .BudgetAndBids_useSuggestedBid__1pfSk .BudgetAndBids_campaignBid__NjIyw {
    margin-bottom: 16px;
  }
}

/* .campaignTypeInput,
.accName {
  width: 50%;
  display: inline-block;
}

.accName :global(.ant-col.ant-form-item-control) {
  margin-right: 10px;
}
.campaignTypeInput :global(.ant-form-item-control-input-content) {
  display: flex;
} */

.Links_generalWrapper__2tgHx {
  width: 100%;
  min-width: 300px;
}
.Links_generalContentWrapper__wA2uP {
  min-height: 550px;
  /* max-height: 550px; */
}
.Links_statusRadioButton__2nxZY {
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
  -webkit-align-items: center;
          align-items: center;
}
.Links_radioButtonLabels__28tW3 {
  width: 75px;
  text-align: center;
}

.Links_autoManualPricing__1tjv9 {
  -webkit-flex-direction: row !important;
          flex-direction: row !important;
  -webkit-align-items: center;
          align-items: center;
}

.Links_datepickerStart__3NbAj,
.Links_datepickerEnd__3iswZ {
  width: 50%;
  display: inline-block;
}
.Links_datepickerStart__3NbAj .ant-col.ant-form-item-control {
  margin-right: 10px;
}
.Links_datepickerStart__3NbAj .ant-picker,
.Links_datepickerEnd__3iswZ .ant-picker {
  width: 100%;
}

.Links_statusInfo__21o-x {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 1rem;
}
.Links_statusInfo__21o-x > p {
  margin: 0;
  color: #000000d9;
  padding-right: 0.5rem;
}

.Links_campaignBudget__15UyX {
  display: inline-block;
  width: 60%;
  margin-right: 7%;
}
.Links_campaignBudget__15UyX input {
  min-height: 32px;
}
.Links_campaignBudgetPeriod__ZYjJQ {
  display: inline-block;
  width: 33%;
}

/* TRACKING PREVIEW */
.Links_generalInfoHeader__aZqDc,
.Links_generalInfoRows__v16_J {
  display: block;
  margin-bottom: 0.7rem;
  width: 100%;
}
.Links_trackingInput__2OU_s {
  width: 80%;
  margin-bottom: 10px;
}
.Links_trackingParagraph__39iXt {
  margin-top: 0.5rem;
  color: darkgrey;
}
.Links_trackingParamSpan__3vI1c {
  color: orangered;
}
.Links_proceedButton__xPu3Z {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin-top: 20px;
  border-radius: 4px;
  background-color: #4090f7;
}

@media (max-width: 1100px) {
  .Links_generalWrapper__2tgHx {
    width: 95%;
    margin: 0 auto;
  }
}

/* .campaignTypeInput,
.accName {
  width: 50%;
  display: inline-block;
}

.accName :global(.ant-col.ant-form-item-control) {
  margin-right: 10px;
}
.campaignTypeInput :global(.ant-form-item-control-input-content) {
  display: flex;
} */

.Spinner_ldsRoller__T1F5i {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  position: relative;
  width: 53%;
  height: 80px;
  margin-left: 47%;
  margin-bottom: 80px;
}
.Spinner_ldsRoller__T1F5i div {
  -webkit-animation: Spinner_ldsRoller__T1F5i 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: Spinner_ldsRoller__T1F5i 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  -webkit-transform-origin: 40px 40px;
          transform-origin: 40px 40px;
}
.Spinner_ldsRoller__T1F5i div:after {
  content: ' ';
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #082d6d;
  margin: -4px 0 0 -4px;
}
.Spinner_ldsRoller__T1F5i div:nth-child(1) {
  -webkit-animation-delay: -0.036s;
          animation-delay: -0.036s;
}
.Spinner_ldsRoller__T1F5i div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.Spinner_ldsRoller__T1F5i div:nth-child(2) {
  -webkit-animation-delay: -0.072s;
          animation-delay: -0.072s;
}
.Spinner_ldsRoller__T1F5i div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.Spinner_ldsRoller__T1F5i div:nth-child(3) {
  -webkit-animation-delay: -0.108s;
          animation-delay: -0.108s;
}
.Spinner_ldsRoller__T1F5i div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.Spinner_ldsRoller__T1F5i div:nth-child(4) {
  -webkit-animation-delay: -0.144s;
          animation-delay: -0.144s;
}
.Spinner_ldsRoller__T1F5i div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.Spinner_ldsRoller__T1F5i div:nth-child(5) {
  -webkit-animation-delay: -0.18s;
          animation-delay: -0.18s;
}
.Spinner_ldsRoller__T1F5i div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.Spinner_ldsRoller__T1F5i div:nth-child(6) {
  -webkit-animation-delay: -0.216s;
          animation-delay: -0.216s;
}
.Spinner_ldsRoller__T1F5i div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.Spinner_ldsRoller__T1F5i div:nth-child(7) {
  -webkit-animation-delay: -0.252s;
          animation-delay: -0.252s;
}
.Spinner_ldsRoller__T1F5i div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.Spinner_ldsRoller__T1F5i div:nth-child(8) {
  -webkit-animation-delay: -0.288s;
          animation-delay: -0.288s;
}
.Spinner_ldsRoller__T1F5i div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

.ant-layout-sider-collapsed .Spinner_logo__4OeDD img {
  width: 75%;
}

.ant-layout-sider-collapsed .Spinner_logo__4OeDD span {
  display: none;
}

.Spinner_logo__4OeDD {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.Spinner_logo__4OeDD img {
  display: block;
  width: 60%;
  margin: 0 auto;
}

.Spinner_logo__4OeDD span {
  font-weight: 600;
  position: relative;
  letter-spacing: 0.5px;
  font-size: 1.1rem;
  padding-top: 0.5rem;
  /* padding-left: 4px; */
}

@-webkit-keyframes Spinner_ldsRoller__T1F5i {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Spinner_ldsRoller__T1F5i {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.formLabels {
  text-align: left;
  font: bolder 12px/20px;
  letter-spacing: 0px;
  color: #b3b3b3;
  font-weight: 900;
  margin-bottom: 0px;
  margin-top: 20px;
}

.formTitles {
  text-align: left;
  font: bolder 18px/13px;
  letter-spacing: 0px;
  color: #4d4f5c;
  opacity: 1;
}

.gutter-col {
  padding: 10px;
}
.imageUploaderModal .fileContainer {
  margin: 0;
  box-shadow: none;
  border-radius: 5px;
  padding: 0;
}
.imageUploaderModal .fileContainer img {
  display: none;
}
.imagePreviewModal {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 20px 90px;
  border-radius: 50%;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.08);
  overflow: hidden;
}

.imagePreviewModal img {
  width: 130px;
  height: 130px;
  object-fit: cover;
}
.removeImageButton {
  opacity: 0.1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  margin-top: 20px;
  transition: opacity 0.3s;
}
.removeImageButton:hover {
  opacity: 1;
}
.removeImageButton img {
  cursor: pointer;
  position: absolute;
  margin-left: 100px;
}

/*general profile styling */
.Profile_titleProfile__2n3rA {
  color: #43425d;
  font-size: 1.4rem;
}
.Profile_personalDetailsContainer__3vavg {
  width: 70%;
  max-width: 680px;
}
.Profile_tabsSection__1xzEM .ant-tabs-ink-bar {
  height: 4px;
  background-color: #458edf;
}
.Profile_tabsSection__1xzEM .ant-tabs-nav {
  font-weight: normal;
  color: #4b88df;
  font-size: 1rem;
}
.Profile_tabsSection__1xzEM .ant-tabs-nav .ant-tabs-tab-active {
  font-weight: normal;
  color: #6c6c6c;
}
.Profile_tabsSection__1xzEM .ant-tabs-nav .ant-tabs-tab {
  margin-right: 30%;
  padding: 0.6rem 1rem;
}

/* Personal Details Section */

.Profile_personalDetailsTop__18bPr,
.Profile_personalDetailsBottom__kDIFj,
.Profile_companyDetailsBottom__1iQ9M {
  display: -webkit-flex;
  display: flex;
}
.Profile_personalDetailsBottom__kDIFj {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.Profile_personalDetailsTop__18bPr {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  border-bottom: 2px solid #f5f5f5;
}

.Profile_personalDetailsTop__18bPr .chooseFileButton {
  border: 2px solid #f5f5f5;
  background-color: #fff;
  font-weight: 500;
  color: #b3b3b3;
  padding: 0.4rem 2.1rem;
  margin: 0;
}
.Profile_personalDetailsTop__18bPr .chooseFileButton:hover {
  background-color: #f5f5f5;
}
.Profile_userInfo__1QIQa {
  padding-top: 2rem;
  padding-bottom: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.Profile_userInfo__1QIQa > p {
  font-size: 0.85rem;
  font-weight: 500;
  width: 100%;
}
.Profile_userInfo__1QIQa .ant-input {
  padding: 0.4rem 1.2rem;
  border: 1px solid #f5f5f5;
  font-weight: 500;
  width: 300px;
  color: #b3b3b3;
}
.Profile_confirmInfoChangesBtn__1ninz,
.Profile_confirmPersonalChangesBtn__26YKq {
  background-color: #4090f7;
  border-radius: 4px;
  padding: 0.4rem 1rem;
  font-weight: normal;
  height: auto;
}

.Profile_personalBottomLeft__1Iy3Q {
  color: #808080;
  padding: 2rem;
  padding-right: 3rem;
  max-width: 300px;
}
.Profile_companyBottomLeft__29wEp {
  color: #808080;
  padding: 2.5rem;
}
.Profile_personalBottomLeft__1Iy3Q,
.Profile_companyBottomRight__3PSvm > h3 {
  font-weight: 500;
  font-size: 0.9rem;
  color: #808080;
  padding-bottom: 2rem;
}
.Profile_companyBottomRight__3PSvm {
  margin-top: 2.5rem;
}

.Profile_contactBlock__1w407 {
  display: -webkit-flex;
  display: flex;
}
.Profile_personalDetailsBottom__kDIFj .ant-input,
.Profile_personalDetailsBottom__kDIFj .ant-select-selector {
  padding: 0.4rem 1.2rem;
  border: 2px solid #f5f5f5;
  font-weight: 500;
  color: #b3b3b3;
}

.Profile_companyBottomRight__3PSvm .ant-input,
.Profile_companyBottomRight__3PSvm .ant-select-selector {
  width: 260px;
  padding: 0.4rem 1.2rem;
  border: 2px solid #f5f5f5;
  font-weight: 500;
  color: #b3b3b3;
}
.Profile_personalDetailsBottom__kDIFj .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  border: 2px solid #f5f5f5;
  height: 38.78px; /* quick fix */
  margin: 0;
}
.Profile_datePickerItem__3N1e6 {
  width: 100%;
  height: 38.78px;
  border: 2px solid #f5f5f5;
}
.Profile_personalBottomRight__2rS-A {
  padding-top: 2rem;
  text-align: right;
  max-width: 300px;
}

/* Change Password */

.Profile_securityTabContainer__P52Oz > h2,
.Profile_companyDetailsContainer__2B73W > h2,
.Profile_personalDetailsContent__1G037 > h2 {
  color: #4d4f5c;
  font-size: 1rem;
  border-bottom: 2px solid #f5f5f5;
  padding: 0 1rem 1rem 1rem;
}

.Profile_securityTab__24td- {
  display: -webkit-flex;
  display: flex;
  margin-top: 2rem;
}

.Profile_securityTab__24td- .ant-input-password {
  width: 260px;
  padding: 0.2rem 1.2rem;
  height: auto;
  border: 2px solid #f5f5f5;
  font-weight: 500;
  color: #b3b3b3;
}
.Profile_securityTab__24td- > p {
  font-weight: 500;
  color: #808080;
  font-size: 1rem;
  padding: 0 4rem;
}

@media (max-width: 1100px) {
  .Profile_personalDetailsTop__18bPr,
  .Profile_personalDetailsBottom__kDIFj,
  .Profile_companyDetailsBottom__1iQ9M {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .Profile_personalDetailsTop__18bPr .ant-input {
    width: 100% !important;
  }
  .Profile_companyBottomLeft__29wEp {
    padding: 1rem;
  }
  .Profile_companyBottomRight__3PSvm {
    margin-top: 0;
  }
  .Profile_securityTab__24td- {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .Profile_personalDetailsContainer__3vavg {
    width: 100%;
  }
}

.trackerLogoContainer {
  width: 140px;
  height: 70px;
  cursor: pointer;
  padding: 20px;
  font-weight: 600;
  color: rgb(90, 90, 90);
  font-size: 0.9rem;
  border: 2px solid rgb(237, 237, 237);
  background-color: rgb(251, 251, 251);
  opacity: 0.7;
  border-radius: 3px;
}
.ant-row-start .ant-space-horizontal:last-of-type .trackerLogo {
  width: auto;
  text-align: center;
  background: none;
  padding: 0px;
  line-height: 1;
  font-size: smaller;
}
.trackerLogoContainerSelected {
  width: 140px;
  height: 70px;
  cursor: pointer;
  padding: 20px;
  font-weight: 600;
  color: rgb(90, 90, 90);
  font-size: 0.9rem;
  background-color: rgb(251, 251, 251);
  border: 2px solid #1890ff;
  border-radius: 3px;
}
.trackerLogo {
  width: 90px;
  height: 30px;
}

.S2Surl {
  width: 100%;
  padding-right: 0;
}

.tracking-main-title {
  color: #43425d;
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.testConversionTracking {
  width: 70%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
}

.formTitles {
  font: Bold 18px/21px Arial;
  letter-spacing: 0px;
  color: #555555;
}

.formSmallWritings {
  font: Regular 15px/24px Source Sans Pro;
  letter-spacing: 0px;
  color: #a6a6a6;
}
.formSmallWritings .enhance {
  color: #ff6b40;
}

.noConversions {
  letter-spacing: 0px;
  color: #d95c5c;
  opacity: 1;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.gutter-row {
  margin-bottom: 10px;
}

.Api_apiTabWrapper__1m9gv {
  line-height: 2rem;
}
.Api_apiTabCheckboxTitle__2_3ut {
  padding-left: 1rem;
  font-size: 1rem;
  font-weight: 600;
}
.Api_switchBlock__TsDuD {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.Api_integrationContent__2JyJs .ant-tabs-nav .ant-tabs-tab-active {
  color: #7d7d7d;
  font-size: 1.05rem;
}
.Api_integrationContent__2JyJs .ant-tabs-ink-bar {
  background-color: #7d7d7d;
}
.Api_apiTabWrapper__1m9gv h2 {
  padding-top: 0.5rem;
  font-weight: bold;
  color: #4a4a4a;
}
.Api_responseCodeExample__FfW6G {
  background-color: #f0f2f5;
  color: #4a4a4a;
  font-size: 0.8rem;
  width: 50%;
  min-width: 300px;
  max-width: 520px;
}
.Api_codeDisplayColor__2d1qF {
  color: #1890ff;
  background-color: #cbe1f7;
  padding: 2px 3px;
  border-radius: 2px;
}
.Api_apiTabWrapper__1m9gv h4 {
  text-decoration: underline;
  color: #4a4a4a;
}

.Dashboard_titleContainer__2eTKw {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 1.3rem;
}

.Dashboard_titleContainer__2eTKw > h4 {
  color: #43425d;
  font-size: 1.4rem;
  margin: 0;
}

.Dashboard_dashBottomContent__3AaXt {
  display: -webkit-flex;
  display: flex;
}

/* CONTACT INFO */
.Dashboard_contactInfoSpin__1dRLi {
  grid-column: span 2;
}
.Dashboard_contactInfoContainer__nIBy4 {
  position: relative;
  padding: 0.7rem;
  display: -webkit-flex;
  display: flex;
  background-color: #465161;
}
.Dashboard_contactInfoLeft__gNvKO {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.Dashboard_contactIcon__1BTX6 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
  opacity: 0.8;
}
.Dashboard_contactIcon__1BTX6:hover {
  opacity: 1;
}

.Dashboard_contactInfoLeft__gNvKO > div {
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  width: 60px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-right: 1rem;
  padding-bottom: 1rem;
}
.Dashboard_contactInfoLeft__gNvKO > img {
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: 50%;
  margin-left: 1rem;
  padding: 1rem;
}

.Dashboard_contactInfoRight__IQ1GV {
  color: #fff;
  padding: 1rem;
  font-size: 0.9rem;
}
.Dashboard_contactInfoRight__IQ1GV > div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 0.8rem;
  color: #fff;
}

.Dashboard_contactInfoRight__IQ1GV > div span {
  margin-left: 1rem;
}
.Dashboard_contactInfoRight__IQ1GV > div span:last-child {
  margin-left: 0.2rem;
}
.Dashboard_contactInfoRight__IQ1GV > p:first-child {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0;
  padding-bottom: 0.2rem;
}
.Dashboard_closeBtn__2htC5 {
  position: absolute;
  color: #fff;
  padding-top: 0.3rem;
  padding-right: 0.5rem;
  font-size: 1.2rem;
  top: 0;
  right: 0;
  cursor: pointer;
}
.Dashboard_closeMessage__1ZMBx {
  margin: 0;
  color: #ffffffde;
  margin: 0 auto;
  text-decoration: underline;
  cursor: pointer;
}

/* EARNINGS MAP */
.Dashboard_earningsMapContainer__NBAfJ {
  grid-row: span 3;
}
.Dashboard_earningsMapContainer__NBAfJ .Dashboard_earningsMapHeader__22xSB {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 1rem;
  font-size: 1.1rem;
  color: #4d4f5c;
}
.Dashboard_earningsMapHeader__22xSB > span {
  font-size: 0.9rem;
  color: #012d6d;
  cursor: pointer;
}

.Dashboard_earningsMapContainer__NBAfJ .jvectormap-zoomin {
  background: #3234408c !important;
  width: 17px;
  height: 17px;
}
.Dashboard_earningsMapContainer__NBAfJ .jvectormap-zoomout {
  background: #3234408c !important;
  width: 17px;
  height: 17px;
}
.Dashboard_mapEarningsList___Vsda {
  display: inline-block;
  background: #fff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
}

/* CHANNELS TABLE CONTAINER */
.Dashboard_channelsTableContainer__2shaA {
  grid-column: span 2;
  grid-row: span 3;
}
.Dashboard_channelsTableContainer__2shaA .ant-table-thead tr th {
  color: #a3a6b4;
  font-weight: bold;
}
.Dashboard_channelsTableContainer__2shaA > h4 {
  color: #4d4f5c;
  font-size: 1.3rem;
  padding: 1rem 0 0.5rem 0;
}

/* INSIGHTS */
.Dashboard_insightsContainer__bbWlv {
  border: 1px solid #dedede;
  padding: 1rem;
}
.Dashboard_insightsContainer__bbWlv > h4 {
  color: #2d71b6;
  font-weight: bold;
  font-size: 1.2rem;
}
.Dashboard_insightsList__2LauY > p {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #333333;
  font-size: 0.9rem;
}
.Dashboard_insightsDot__2ItOz {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f9ae3b;
  margin-right: 0.5rem;
}

/* USERS BY DEVICE PIE CHART */
.Dashboard_usersDevicePieContainer__JnS4o {
  margin-top: 1rem;
  grid-column: span 1;
}
.Dashboard_usersDevicePieContainer__JnS4o h4 {
  font-size: 1.1rem;
  color: #4d4f5c;
  margin: 0;
}
.Dashboard_bottomDevicesStats__1otmK {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.Dashboard_bottomDevicesStats__1otmK > div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.Dashboard_bottomDevicesStats__1otmK > div > p {
  margin: 0;
  padding: 0.2rem 0 0 0;
  font-size: 0.8rem;
  color: #a6a6a6;
}
.Dashboard_bottomDevicesStats__1otmK > div > span {
  font-size: 1.1rem;
  font-weight: 600;
}

/*  NEWS BLOG */
.Dashboard_newsBlogContainer__2-C_9 {
  grid-column: span 2;
  grid-row: span 2;
}
.Dashboard_blogCard__JYArK {
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
  margin-top: 1rem;
  padding-right: 1rem;
  box-shadow: 0px 2px 6px #00000012;
}
.Dashboard_blogCard__JYArK > div:last-child {
  width: 75%;
}
.Dashboard_blogCard__JYArK > div:first-child {
  width: 25%;
  min-width: 150px;
  max-width: 190px;
}
.Dashboard_blogCard__JYArK:hover {
  opacity: 0.85;
  transition: opacity 0.2s;
}
.Dashboard_blogCard__JYArK p {
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
.Dashboard_blogDesc__RBTK7 {
  font-size: 0.8rem;
}
.Dashboard_blogTitle__1vnWE {
  font-weight: 600;
  font-size: 0.9rem;
  padding: 0.5rem;
}
.Dashboard_blogCard__JYArK img {
  width: 100%;
  padding: 0.5rem;
}

/* ACTIVITY LOG */

.Dashboard_activityLogContainer__2VuUd {
  margin-top: 2rem;
  grid-row: span 1;
}

.Dashboard_activityLogContainer__2VuUd h4 {
  color: #303447;
  font-size: 0.9rem;
  font-weight: 600;
}
.Dashboard_singleActivity__zJOsW {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 1rem 0;
}
.Dashboard_singleActivity__zJOsW > div > img {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1rem;
}
.Dashboard_singleActivity__zJOsW p {
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Dashboard_singleActivity__zJOsW p:first-child {
  font-size: 0.9rem;
}
.Dashboard_singleActivity__zJOsW p:first-child span {
  color: #9620ff;
  font-weight: 600;
}
.Dashboard_singleActivity__zJOsW p:last-child {
  font-weight: 600;
  font-size: 0.85rem;
}

.Dashboard_customLoaderContainer__3d4Mb {
  margin: 20px auto;
  width: 300px;
  height: 300px;
  -webkit-animation: Dashboard_spin__um3DB 3s linear infinite;
          animation: Dashboard_spin__um3DB 3s linear infinite;
}
.Dashboard_customLoaderContainer__3d4Mb > img {
  top: 0;
  left: 0;
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  height: 70px;
}

@-webkit-keyframes Dashboard_spin__um3DB {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  20% {
    -webkit-transform: scale(1.05) rotate(72deg);
            transform: scale(1.05) rotate(72deg);
  }
  40% {
    -webkit-transform: scale(0.95) rotate(144deg);
            transform: scale(0.95) rotate(144deg);
  }
  60% {
    -webkit-transform: scale(1.05) rotate(216deg);
            transform: scale(1.05) rotate(216deg);
  }
  80% {
    -webkit-transform: scale(0.95) rotate(288deg);
            transform: scale(0.95) rotate(288deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes Dashboard_spin__um3DB {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  20% {
    -webkit-transform: scale(1.05) rotate(72deg);
            transform: scale(1.05) rotate(72deg);
  }
  40% {
    -webkit-transform: scale(0.95) rotate(144deg);
            transform: scale(0.95) rotate(144deg);
  }
  60% {
    -webkit-transform: scale(1.05) rotate(216deg);
            transform: scale(1.05) rotate(216deg);
  }
  80% {
    -webkit-transform: scale(0.95) rotate(288deg);
            transform: scale(0.95) rotate(288deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@media (max-width: 1200px) {
  .Dashboard_dashBottomContent__3AaXt {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  /* .channelsTableContainer {
    grid-column: span 3;
  }
  .insightsContainer {
    grid-column: span 3;
  }
  .usersDevicePieContainer {
    grid-row: span 2;
  }
  .contactInfoLeft {
    flex-direction: column;
  }
  .contactInfoRight > div > span {
    display: none;
  } */
}
/* @media (max-width: 1000px) {
  .insightsContainer {
    grid-column: span 3;
  }
  .usersDevicePieContainer {
    grid-column: span 3;
  }
  .earningsMapContainer {
    grid-column: span 3;
  }
  .newsBlogContainer {
    grid-column: span 3;
  }
  .contactInfoSpin {
    grid-column: span 3;
  }
  .activityLogContainer {
    grid-column: span 3;
  }
} */

.HeadInfo_headInfoContainer__2-5lu {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
}

/* HEAD INFO CARD */
.HeadInfo_infoCard__3NoQ9 {
  box-shadow: 0px 2px 6px #00000012;
  padding: 0.9rem;
}
.HeadInfo_infoCard__3NoQ9 * {
  margin: 0;
  color: #a3a6b4;
}
.HeadInfo_infoCard__3NoQ9 > p {
  font-size: 1.2rem;
  padding-bottom: 0.7rem;
}
.HeadInfo_cardValues__Cb97N p:first-child {
  font-size: 1.6rem;
  font-weight: bold;
}
.HeadInfo_cardValues__Cb97N p:last-child {
  line-height: 1rem;
}

@media (max-width: 1300px) {
  .HeadInfo_headInfoContainer__2-5lu {
    grid-template-columns: repeat(2, 1fr);
  }
  .HeadInfo_infoCard__3NoQ9 {
    padding: 0.3rem;
  }
}

@media (max-width: 1000px) {
}

.StatisticsLineChart_statisticsLineHeader__2Sg25 {
  margin-top: 1.5rem;
  padding: 1rem;
  font-size: 1.2rem;
  color: #4d4f5c;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.StatisticsLineChart_lineChartContainer__lW_DN {
  box-shadow: 0px 2px 6px #0000000a;
  margin-bottom: 1.5rem;
}

.Filters_titleContent__3YvMd {
  font-size: 1.5rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 1.5rem;
  color: #43425d;
}
.Filters_titleContent__3YvMd > h3 {
  padding-right: 2rem;
  font-weight: normal;
  margin: 0;
}
.Filters_newCampaignBtn__3xcA- {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 1.2rem 0.9rem;
  background-color: #4090f7;
  border-radius: 6px;
}
.Filters_plusIconCamp__2F8d1 {
  font-size: 25px;
  font-weight: bold;
}

.Filters_advancedFilters__1EY7e .Select-menu-outer {
  z-index: 10;
}

.Filters_filtersContainer__14wmo {
  background-color: #fafafa;
  padding: 0.5rem 1rem 0.5rem 1rem;
}
.Filters_dateRadioSelect__3E60I {
  font-size: 0.9rem;
}
.Filters_filtersContainer__14wmo .ant-radio-group-large .ant-radio-button-wrapper {
  color: #2f6fb6;
  font-size: inherit;
  background-color: transparent;
}
.Filters_filtersContainer__14wmo .ant-radio-group-large .ant-radio-button-wrapper-checked {
  font-weight: 550;
  border: 1px solid !important;
  border-color: rgba(47, 111, 182, 0.4) !important;
  border-radius: 6px;
  background-color: rgba(47, 111, 182, 0.03);
  line-height: 28px;
  height: 30px;
}
.Filters_filtersContainer__14wmo .ant-radio-group-large .ant-radio-button-wrapper {
  border: none;
}
.Filters_filtersContainer__14wmo .ant-radio-group-large .ant-radio-button-wrapper::before {
  display: none;
}
.Filters_filtersContainer__14wmo .Select-control {
  border-radius: 2px;
}
.Filters_filtersContainer__14wmo .Select-arrow {
  border-color: #d9d9d9 transparent transparent;
}
.Filters_dateRadioSelect__3E60I {
  margin-bottom: 0.5rem;
}
.Filters_filtersContainerBottom__N2rFN {
  display: -webkit-flex;
  display: flex;
}
.Filters_rangeDatePicker__2ok_J {
  margin-right: 1rem;
  height: 34px;
}
.Filters_inputTextSearch__3vZze {
  max-width: 400px;
  width: 50%;
  min-width: 150px;
  margin-right: 1rem;
}

.Filters_hideShowLinkBtn__2pDWB {
  padding: 0.5rem 0 0 0;
  font-size: 0.8rem;
}

.Filters_selectStatuses__IAUvP,
.Filters_selectFormats__3CGxE {
  min-width: 120px;
  margin-right: 1rem;
}

.Filters_applyFiltersBtn__2mnvS {
  /* padding-left: 2rem; */
  /* padding-right: 2rem; */
  background-color: #3d8af8;
  /* height: 34px; */
}

.Filters_topActionBtns__2iO7e {
  padding: 0.8rem 0;
}
.Filters_columnGroupContainer__1OroU {
  width: 500px;
}
.Filters_columnGroupContainer__1OroU .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}
.Filters_topActionButton__2znXB {
  margin-right: 1rem;
}

.Filters_extActionGroup__IgG78 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 1rem 0 1rem;
}

.Filters_exportToCSV__1MyK_ {
  border: none;
  color: #498adf;
}

.Filters_columnsBtn__3Xk9G,
.Filters_reloadBtn__2JePT {
  color: #a3a6b4;
  margin-left: 1rem;
  border: 1px solid #a3a6b4;
  border-radius: 0;
}

.Filters_bottomActions__2aKq0 {
  margin-top: 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.Filters_bottomActions__2aKq0 .Filters_rightActionBtns__16SAP > * {
  margin-left: 0.7rem;
}

/* virtualized select inputs */

/* .advancedFilters :global(.Select) {
  width: 200px;
  height: 32px;
  margin-right: 1rem;
  border-radius: 0;
} */
.Filters_advancedFilters__1EY7e .Select-control {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  height: 31px;
  z-index: 10;
  overflow: auto;
  /* display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; */
}
.Filters_advancedFilters__1EY7e .Select-value-label {
  max-width: 135px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.Filters_advancedFilters__1EY7e .Select-input {
  height: 30px;
}
.Filters_advancedFilters__1EY7e .Select-placeholder {
  line-height: 31px;
}
.Filters_advancedFilters__1EY7e .Select-value {
  width: calc(100% - 10px);
  margin-right: 3px;
  white-space: nowrap;
  overflow: hidden;
}

.Filters_advancedFilters__1EY7e .has-value .Select-control > *:last-child {
  display: inline-block;
  margin-left: 0rem;
}

.Filters_advancedFilters__1EY7e .Select--multi .Select-clear-zone {
  display: inline-block;
  margin-left: 5.5rem;
}

.Reports_mainTableContainer__2jxGk .ant-table-tbody > tr > td {
  padding: 13px;
}
.Reports_mainTableContainer__2jxGk .ant-table-thead > tr > th {
  padding: 13px;
}

.Reports_mainTableContainer__2jxGk .ant-table-tbody > tr > td {
  background-color: inherit;
}

.Reports_mainTableContainer__2jxGk .ant-table-row:nth-child(odd) {
  background-color: #f5f6fa;
}

.Reports_reportsContainer__3cYTT > h4 {
  font-size: 1.4rem;
  color: #43425d;
  padding-right: 0.9rem;
  margin: 0;
  font-weight: 500;
}

.Reports_columnMargin__3LD_A {
  margin: 0;
}

.Reports_campaignId__o19XL, .Reports_campaignName__c0gpr {
  margin: 0;
  color: #0071BD;
  font-weight: 600;
  cursor: pointer;
}

.Reports_cpcColContainer__25Q7J {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
}

.Reports_cpcColContainer__25Q7J button {
  margin-left: .5rem;
}

.Reports_cpcColContainer__25Q7J .ant-btn-icon-only{
  height: 22px;
  width: 22px;
  font-size: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.Reports_cpcColContainer__25Q7J .ant-btn > .anticon {
  line-height: 0;
}

.Reports_cpcColContainer__25Q7J .ant-btn-icon-only > * {
  font-size: 13px;
}

.Reports_pricingColumn__z_2P1 {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.Reports_pricingColumn__z_2P1 .Reports_confirmButtonDisabled__2FPXC {
  position: relative;
  top: 4px;
  right: 0;
  margin-left: 1rem;
}

.Reports_pricingColumn__z_2P1 .Reports_confirmButtonGreen__3wS1g {
  position: relative;
  top: 4px;
  right: 0;
  margin-left: 1rem;
  color: #73B566;
  border: 1px solid #73B566;
  background-color: #73B5661a;
}

.Reports_priceUpdateContainer__itZIU {
  display: -webkit-flex;
  display: flex;
}

.Reports_priceUpdateContainer__itZIU .ant-btn{
  padding: 0;
  height: 100%;
  width: 33px;
  border: 1px solid #EEEEEE;
  color: #4D4F5C;
  font-size: 13px;
  box-shadow: none;
  border-radius: 0 5px 5px 0;
}

.Reports_priceUpdateContainer__itZIU .ant-btn:first-child{
  border-radius: 5px 0 0 5px;
}

.Reports_mainPriceBox__34Xfp {
  /* padding: 0.3rem; */
  border-top: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
}

.Reports_mainPriceBox__34Xfp * {
  box-shadow: none;
}

.Reports_mainPriceBox__34Xfp .ant-input-number {
  border: none;
  border-radius: 0;
}

.Reports_mainPriceBox__34Xfp .ant-input-number:focus {
  border: none;
  box-shadow: none;
}

.Reports_mainPriceBox__34Xfp .ant-input-number-input-wrap input{
  padding: 0;
  text-align: center;
  color: #43425d;
}

.Reports_mainPriceBox__34Xfp .ant-input-number-handler-wrap {
  position: relative;
  display: none;
}

.TableTitle_TableTitle__3Gnk8 > .title {
  float: left;
}

.TableTitle_TableTitle__3Gnk8 > .arrows {
  float: left;
  margin-left: 8px;
  font-size: 0.7em;
  margin-top: -3px;
  color: #bbbbbb;
  cursor: pointer;
}

.TableTitle_TableTitle__3Gnk8 > .arrows > .active-arrow span {
  color: #1890ff;
}

.TableTitle_TableTitle__3Gnk8 > .arrows > .anticon-caret-up {
  display: block;
}

.TableTitle_TableTitle__3Gnk8 > .arrows > .anticon-caret-down {
  display: block;
}
.TableTitle_TableTitle__3Gnk8 > .title {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.NestedTable_nestedTableContainer__onl5D .ant-table-title {
  padding: 0px;
}
.NestedTable_nestedTableContainer__onl5D table {
  border: 1px solid #c8c8c8;
  border-radius: 3px;
  overflow: hidden;
}

.NestedTable_nestedTableContainer__onl5D .ant-table-row:nth-child(odd) {
  background-color: #fff;
}

.NestedTable_nestedTableContainer__onl5D .ant-table-footer {
  background-color: #fff;
}

.NestedTable_nestedTableContainer__onl5D .ant-table-pagination.ant-pagination {
  margin: 8px 0;
}
/* .nestedTableContainer :global(.ant-table-tbody > tr > td) {
  padding: 10px !important;
}
.nestedTableContainer :global(.ant-table-thead > tr > th) {
  padding: 10px !important;
} */

.NestedTable_nestedTableHeader__AwqfF {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.NestedTable_statsBy__2gs7Y {
  margin: 1rem 0 2rem 0;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.NestedTable_statsBy__2gs7Y>span {
  margin-right: 1rem;
}
.NestedTable_multipleAction__2bNnB {
  margin-left: 1rem;
}
.NestedTable_multipleAction__2bNnB button {
  margin-right: .5rem;
}
.NestedTable_multipleAction__2bNnB .NestedTable_active__1SnqW,
.NestedTable_multipleAction__2bNnB .NestedTable_active__1SnqW:hover,
.NestedTable_multipleAction__2bNnB .NestedTable_active__1SnqW:focus,
.NestedTable_multipleAction__2bNnB .NestedTable_included__1UlKT,
.NestedTable_multipleAction__2bNnB .NestedTable_included__1UlKT:hover ,
.NestedTable_multipleAction__2bNnB .NestedTable_included__1UlKT:focus {
  color: #73B566 !important;
  border-color: #73B566 !important;
}
.NestedTable_multipleAction__2bNnB .NestedTable_paused__2ztQ7,
.NestedTable_multipleAction__2bNnB .NestedTable_paused__2ztQ7:hover ,
.NestedTable_multipleAction__2bNnB .NestedTable_paused__2ztQ7:focus {
  color: #FAAD14 !important;
  border-color: #FAAD14 !important;
}
.NestedTable_multipleAction__2bNnB .NestedTable_excluded__38RiU,
.NestedTable_multipleAction__2bNnB .NestedTable_excluded__38RiU:hover ,
.NestedTable_multipleAction__2bNnB .NestedTable_excluded__38RiU:focus {
  color: #d82a2a !important;
  border-color: #d82a2a !important;
}
.NestedTable_actionBtn__2kF1w {
  cursor: pointer;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.NestedTable_actionBtn__2kF1w.NestedTable_included__1UlKT {
  color: #d82a2a;
}

.NestedTable_actionBtn__2kF1w.NestedTable_excluded__38RiU {
  color: #73B566;
}

.NestedTable_actionBtn__2kF1w.NestedTable_active__1SnqW {
  color: #FAAD14;
}

.NestedTable_actionBtn__2kF1w.NestedTable_paused__2ztQ7 {
  color: #73B566
}

.NestedTable_pricingColumn__23C0X {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.NestedTable_pricingColumn__23C0X .NestedTable_confirmButtonDisabled__1Mc0e {
  position: relative;
  top: 4px;
  right: 0;
  margin-left: .8rem;
}

.NestedTable_pricingColumn__23C0X .NestedTable_confirmButtonGreen__340Co {
  position: relative;
  top: 4px;
  right: 0;
  margin-left: .8rem;
  color: #73B566;
  border: 1px solid #73B566;
  background-color: #73B5661a;
}

.NestedTable_cpcTitle__30rTD{
  margin-right: -28%;
}

.NestedTable_actionColumn__LTDUW {
  width: 200px;
}

@media (max-width: 1600px) {
  .NestedTable_cpcTitle__30rTD{
    margin-right: -10%;
  }
  .NestedTable_actionColumn__LTDUW {
    width: auto;
  }
}

.NestedTable_pricingUndoBtn__3zhK_ {
  font-size: 1.2rem;
  cursor: pointer;
  margin-left: 1rem;
}

.NestedTable_percentageContainer__1_GD2 {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.NestedTable_priceUpdateContainer__2qsUB {
  display: -webkit-flex;
  display: flex;
  margin-left: 1rem;
}

.NestedTable_priceUpdateContainer__2qsUB .ant-btn{
  padding: 0;
  height: 100%;
  width: 33px;
  border: 1px solid #EEEEEE;
  color: #4D4F5C;
  font-size: 13px;
  box-shadow: none;
  border-radius: 0 5px 5px 0;
}

.NestedTable_priceUpdateContainer__2qsUB .ant-btn:first-child{
  border-radius: 5px 0 0 5px;
}

.NestedTable_priceEditPop__2Jxiq {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 0.5rem;
  -webkit-align-items: center;
          align-items: center;
}
.NestedTable_priceEditBtns__27VBu {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.NestedTable_editPriceBtn__2m7e0 {
  margin-left: 0.6rem;
  margin-right: 1rem;
}

.NestedTable_pricingColumn__23C0X .ant-radio-group-small .ant-radio-button-wrapper {
  padding: 0 4px;
}

/* .pricingColumn > span {
  width: 60px;
} */

.NestedTable_mainPriceBox__3RkZO {
  /* padding: 0.3rem; */
  border-top: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
}

.NestedTable_mainPriceBox__3RkZO * {
  box-shadow: none;
}

.NestedTable_mainPriceBox__3RkZO .ant-input-number {
  border: none;
  border-radius: 0;
}

.NestedTable_mainPriceBox__3RkZO .ant-input-number:focus {
  border: none;
  box-shadow: none;
}

.NestedTable_mainPriceBox__3RkZO .ant-input-number-input-wrap input{
  padding: 0;
  text-align: center;
  color: #43425d;
}

.NestedTable_mainPriceBox__3RkZO .ant-input-number-handler-wrap {
  position: relative;
  display: none;
}

.NestedTable_percentageBox__2nRV6 {
  font-size: 12px;
  line-height: 1.3;
  min-width: 45px;
  background-color: #1990FF1A;
  border-radius: 2px;
  border: 1px solid #1990F9;
  color: #1990F9;
  padding: 2px 4px;
}

/* STATUS AREA */

.NestedTable_statusColumn__WUOUE,
.NestedTable_status__V0Xk3,
.NestedTable_campaignType__JSs-k {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
}

.NestedTable_status__V0Xk3 {
    text-transform: capitalize;
    min-width: 90px;
    max-width: 90px;
    position: relative;
    margin: 3px 0;
    font-weight: 400;
    border-radius: 3px;
    padding: 0.1rem 0.5rem;
    border: 1px solid transparent;
    background-color: #d1d1d11a;
    border: 1px solid #d1d1d1;
    color: #d1d1d1;
}

.NestedTable_statusactive__9ygKf {
    background-color: #02875b1a;
    border-color: #02875b;
    color: #02875b;
}

.NestedTable_statuspaused__3PMkX,
.NestedTable_statussuspended__INojo {
    background-color: #d1d1d11a;
    border-color: #d1d1d1;
    color: #d1d1d1;
}

.NestedTable_statusrejected__HXwBo {
    background-color: #d85c5c1a;
    border-color: #d85c5c;
    color: #d85c5c;
}

.NestedTable_statuspending__MkdxB {
    background-color: #faad141a;
    border-color: #faad14;
    color: #faad14;
}

.NestedTable_statusready__1fadQ {
    background-color: #02875b1a;
    border: 1px solid #02875b;
    color: #02875b;
}

.NestedTable_statusimplementing__3AdCY {
    background-color: #1990ff1a;
    border: 1px solid #1990ff;
    color: #1990ff;
}

.NestedTable_statusdisabled__3-KGp {
    background-color: #d85c5c1a;
    border-color: #d85c5c;
    color: #d85c5c;
}

.NestedTable_statusrequested__3XsP0 {
    background-color: #781edf1a;
    border-color: #781edf;
    color: #781edfc9;
}

.NestedTable_creativeImageContainer__3NLb8 {
  width: 90px;
  height: 50px;
  border-radius: 3px;
  background-position: center;
  background-size: contain;
}

.NestedTable_creativeImageIcon__2S1Vg {
  width: 50px;
  height: 50px;
  border-radius: 3px;
  background-position: center;
  background-size: contain;
}

.NestedTable_landingUrl__19eok {
  margin: 0;
  width: 250px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.AddFunds_financeContainer__1du32 > h4 {
  color: #43425d;
  font-size: 1.4rem;
}

.AddFunds_methodTypeContainer__13Qyq {
  display: -webkit-flex;
  display: flex;
  overflow: hidden;
  transition: all 0.4s;
}

.AddFunds_methodTypeSelect__NAH-f {
  cursor: pointer;
  width: 130px;
  height: 70px;
  border-radius: 4px;
  padding: 0.2rem 0.6rem;
  border: 1px solid #eeeeee;
  background-color: #fafafa;
  transition: all 0.2s;
  z-index: 10;
  margin-right: 1.1rem;
  margin-bottom: 1.5rem;
}
.AddFunds_methodTypeSelect__NAH-f:hover {
  /* background-color: #c8e5ff; */
  border: 1px solid #8fc9ff;
}
.AddFunds_methodTypeSelect__NAH-f > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.AddFunds_highlightText__qm9aU {
  color: rgb(104, 104, 104);
  font-size: 0.85rem;
  font-weight: 600;
}

.AddFunds_fundPriceBtn__3CkZ- {
  position: relative;
  text-align: center;
  /* border-radius: 4px; */
  color: rgb(101, 101, 101);
  width: 130px;
  margin-right: 1.1rem;
  margin-bottom: 1.8rem;
}

.AddFunds_optionHighlight__3fiIT {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  bottom: -29px;
  font-weight: bold;
  color: #1990ff;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  font-size: 0.8em;
  padding: 0;
  width: 100%;
  pointer-events: none;
}

.AddFunds_optionHighlight__3fiIT > img {
  height: 11px;
  margin-left: 0.3rem;
}

.AddFunds_optionHighlightPrice__1ancm {
  color: #686868;
  font-weight: 600;
  margin-top: 0.3rem;
  margin-left: 0.1rem;
  font-size: 0.7em;
  padding: 0;
  width: 100%;
  pointer-events: none;
}
.AddFunds_optionHighlightPromoError__1PJec {
  color: #e31c1c;
  font-weight: 600;
  margin-top: 0.3rem;
  margin-left: 0.1rem;
  font-size: 0.75em;
  padding: 0;
  width: 100%;
  pointer-events: none;
}

.AddFunds_fundPriceBtnCustom__F9s5q {
  padding: 0px;
  width: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
}
.AddFunds_inputElementContainer__25dIH {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}
.AddFunds_inputElementContainer__25dIH > .AddFunds_currencyLabel__2IH8L {
  color: #686868;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
  font-size: 0.85rem;
}

.AddFunds_inputNumberFund__D_ThW {
  font-size: 0.8rem;
  width: 130px;
  height: 40px;
  line-height: unset;
}

@media (max-width: 1100px) {
  .AddFunds_inputNumberFund__D_ThW {
    font-size: 0.8rem;
    width: 130px;
    height: 40px;
    line-height: unset;
  }
}
.AddFunds_fundsForm__3htC7 .ant-radio-group-large .ant-radio-button-wrapper {
  font-size: 0.8rem;
}
.AddFunds_fundsForm__3htC7 > p {
  color: #606270;
  font-weight: 600;
}

.AddFunds_subtotalContainer__3D8jF {
  color: #606270;
  display: -webkit-flex;
  display: flex;
}
.AddFunds_subtotalContainer__3D8jF > div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-top: 1.3rem;
  margin-right: 3rem;
}

.AddFunds_spinWrapper__33KvM {
  margin-bottom: 1.8rem;
}

.AddFunds_subtotalContainer__3D8jF > div:first-child {
  font-weight: 600;
}
.AddFunds_subtotalContainer__3D8jF > div > span {
  margin-bottom: 0.3rem;
}
.AddFunds_totalPriceValue__2P7zW {
  color: #1990ff;
  font-weight: 700;
}

.AddFunds_bottomBtnActions__38Xec {
  display: -webkit-flex;
  display: flex;
}

.AddFunds_paymentBtn__1_ac1 {
  width: 180px;
  margin-right: 1rem;
}

.AddFunds_viewProformaInvoice__3x7RV {
  padding: 0 1rem 0 1rem;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 2px;
  align-items: center;
  border: 1px solid #d9d9d9;
  color: rgb(111, 111, 111);
}

.Stripe_paymentMessage__cn11g {
  color: rgb(105, 115, 134);
  font-size: 16px;
  line-height: 20px;
  padding-top: 12px;
  text-align: center;
}

.Stripe_paymentElement__35Bdu {
  margin-bottom: 24px;
}

/* Buttons and links */
.Stripe_stripePayButton__1_W3B {
  margin-top: 1rem;
  background: #1890ff;
  font-family: Arial, sans-serif;
  color: #ffffff;
  border-radius: 2px;
  border: 0;
  padding: 8px 16px;
  font-size: 0.9rem;
  cursor: pointer;
  display: block;
  transition: all 0.2s ease;
  box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
  /* width: 100%; */
}

.Stripe_stripePayButton__1_W3B:hover {
  -webkit-filter: contrast(115%);
          filter: contrast(115%);
}

.Stripe_stripePayButton__1_W3B:disabled {
  opacity: 0.5;
  cursor: default;
}

/* spinner/processing state, errors */
.Stripe_spinner__24iby,
.Stripe_spinner__24iby:before,
.Stripe_spinner__24iby:after {
  border-radius: 50%;
}

.Stripe_spinner__24iby {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.Stripe_spinner__24iby:before,
.Stripe_spinner__24iby:after {
  position: absolute;
  content: '';
}

.Stripe_spinner__24iby:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: Stripe_loading__k5rgK 2s infinite ease 1.5s;
  animation: Stripe_loading__k5rgK 2s infinite ease 1.5s;
}

.Stripe_spinner__24iby:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: Stripe_loading__k5rgK 2s infinite ease;
  animation: Stripe_loading__k5rgK 2s infinite ease;
}

@-webkit-keyframes Stripe_loading__k5rgK {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes Stripe_loading__k5rgK {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 600px) {
  form {
    width: 80vw;
    min-width: 0;
    min-width: initial;
  }
}

.BillingAccountStatements_campaignsTable__1_jhY > h4 {
  color: #43425d;
  font-size: 1.4rem;
}

.BillingAccountStatements_billingHeadInfo__fshKj {
  font-size: 0.9rem;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 1rem;
}
.BillingAccountStatements_billingHeadInfo__fshKj > div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-right: 3rem;
  color: rgb(89, 89, 89);
}
.BillingAccountStatements_billingHeadInfo__fshKj > div > span:first-child {
  font-weight: 600;
  margin-bottom: 0.2rem;
  color: rgb(61, 61, 61);
}

.BillingAccountStatements_headingActions__2b54I {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 30px;
}

.BillingAccountStatements_newCampaignBtn__uXYE2 {
  background-color: #3d8af8;
}

.BillingAccountStatements_headingActions__2b54I > div {
  margin-right: 20px;
}

.BillingAccountStatements_columnGroupContainer__1bZx9 {
  width: 500px;
}
.BillingAccountStatements_columnGroupContainer__1bZx9 .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

.BillingAccountStatements_searchInput__2xQcT {
  width: 250px;
}

.BillingAccountStatements_campaignsTable__1_jhY table thead th {
  background-color: #fff;
  color: #707070;
  font-size: 0.9rem;
}

.BillingAccountStatements_campaignsTable__1_jhY table tbody {
  font-size: 0.85rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #808080 !important;
}

.BillingAccountStatements_campaignsTable__1_jhY table tfoot {
  color: #707070;
  font-size: 0.85rem;
  font-weight: 500;
}

.BillingAccountStatements_campaignsTable__1_jhY .ant-table-row {
  background-color: #fff;
}


.BillingAccountStatements_campaignsTable__1_jhY table tfoot .ant-table-row {
  background: #fafafa;
}

.BillingAccountStatements_campaignsTable__1_jhY .ant-table-footer {
  background-color: #fff;
}

.BillingAccountStatements_statusColumn__39HmE,
.BillingAccountStatements_status__1R81u,
.BillingAccountStatements_campaignNameWrapper__36Xj3,
.BillingAccountStatements_campaignType__ZQNCv {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.BillingAccountStatements_campaignType__ZQNCv {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  font-weight: bold;
  font-size: 1rem;
}
.BillingAccountStatements_campaignType__ZQNCv span {
  position: relative;
  left: -15px;
  font-size: 0.85rem;
}

.BillingAccountStatements_campaignNameWrapper__36Xj3 {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

/* .campaignsTable .campaignNameColumn {
  padding-left: 50px;
} */

.BillingAccountStatements_status__1R81u {
  color: #fff;
  text-transform: capitalize;
  min-width: 80px;
  position: relative;
  border-radius: 5px;
  margin: 10px 0;
}

.BillingAccountStatements_statusActive__2GnVd {
  background: #c2d791 0% 0% no-repeat padding-box;
  border: 1px solid #c2d791;
}

.BillingAccountStatements_statusPaused__2w8-- {
  background: #d95c5c 0% 0% no-repeat padding-box;
  border: 1px solid #d95c5c;
}

.BillingAccountStatements_statussuspended__WBiKW {
  background: #d95c5c 0% 0% no-repeat padding-box;
  border: 1px solid #d95c5c;
}

.BillingAccountStatements_statusPending__1TAYl {
  background-color: #f9ae3b;
}
.BillingAccountStatements_statusready__2uSdf {
  background-color: #347ab6;
}
.BillingAccountStatements_statusimplementing__1OYwa {
  padding: 0 2px;
  background-color: #5dc0df;
}
.BillingAccountStatements_statusdisabled__3lZW8 {
  background-color: #495058;
}

.BillingAccountStatements_campaignTypePushCpa__1Awhd {
  color: #32b66b;
}
.BillingAccountStatements_campaignTypePushCpc__3URGp {
  color: #d95c5c;
}
.BillingAccountStatements_campaignTypeNativeCpc__2oM-y {
  color: #3067ce;
}
.BillingAccountStatements_campaignTypeSmartlinkCpa__3SqWQ {
  color: #781edf;
}
.BillingAccountStatements_nameInitials__2w5Em {
  width: 2.3em;
  height: 2.3em;
  border-radius: 50%;
  color: #fff;
  background-color: rgb(85, 85, 85);
  padding: 5px;
  margin-right: 5px;
  text-align: center;
}

.BillingAccountStatements_campaignsTable__1_jhY .ant-table-tbody > tr > td,
.BillingAccountStatements_campaignsTable__1_jhY .ant-table-summary > tr > td {
  padding: 10px 12px;
}

.BillingAccountStatements_channelName__2pD3I,
.BillingAccountStatements_companyName__2oRj2,
.BillingAccountStatements_dateCreated__1bY_G {
  text-align: left;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* .channelName {
  width: 200px;
} */
.BillingAccountStatements_campaignId__6Z8SY {
  font-size: 0.8rem;
}
.BillingAccountStatements_actions__15DxX {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.BillingAccountStatements_actions__15DxX > div {
  cursor: pointer;
  margin-right: 10px;
}

.BillingAccountStatements_actions__15DxX div:last-child {
  margin-right: 0;
}

.BillingAccountStatements_actions__15DxX .ant-popover-inner-content {
  padding: 5px 8px !important;
}

/* CAMPAIGNS TITLE AND FILTERS AREA */

.BillingAccountStatements_plusIconCamp__1zGOx {
  font-size: 25px;
  font-weight: bold;
}

.BillingAccountStatements_filtersContainer__2yLEU {
  background-color: #fafafa;
  padding: 0.5rem 1rem 1rem 1rem;
  margin-bottom: 0.6rem;
}
.BillingAccountStatements_dateRadioSelect__zMhLC {
  font-size: 0.9rem;
}

/* .filtersContainer :global(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  border-radius: 5px;
} */
/* .filtersContainer :global(.ant-picker) {
  border-radius: 5px;
} */

.BillingAccountStatements_dateRadioSelect__zMhLC .ant-radio-button-wrapper {
  color: #3d8bf88f;
  font-size: inherit;
  background-color: transparent;
}
.BillingAccountStatements_dateRadioSelect__zMhLC .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  color: #3d8af8;
}
.BillingAccountStatements_campaignsTable__1_jhY .ant-radio-group-large .ant-radio-button-wrapper {
  color: #2f6fb6;
  font-size: inherit;
  background-color: transparent;
}
.BillingAccountStatements_campaignsTable__1_jhY .ant-radio-group-large .ant-radio-button-wrapper-checked {
  font-weight: 550;
  border: 1px solid !important;
  border-color: rgba(47, 111, 182, 0.4) !important;
  border-radius: 6px;
  background-color: rgba(47, 111, 182, 0.03);
  line-height: 28px;
  height: 30px;
}
.BillingAccountStatements_campaignsTable__1_jhY .ant-radio-group-large .ant-radio-button-wrapper {
  border: none;
}
.BillingAccountStatements_campaignsTable__1_jhY .ant-radio-group-large .ant-radio-button-wrapper::before {
  display: none;
}
.BillingAccountStatements_dateRadioSelect__zMhLC {
  margin-bottom: 0.5rem;
}

.BillingAccountStatements_hideShowLinkBtn__1SaXw {
  color: #3d8af8;
  padding: 0.5rem 0 0 0;
  font-size: 0.8rem;
}

.BillingAccountStatements_spanMarginBottom__2gdN0 {
  color: rgb(95, 95, 95);
}

.BillingAccountStatements_filtersContainerBottom__vepbF .Select {
  width: 200px;
  /* height: 32px; */
  margin-right: 1rem;
  border-radius: 0;
}
.BillingAccountStatements_filtersContainerBottom__vepbF .Select-control {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  height: 31px;
  z-index: 10;
  overflow: auto;
}
.BillingAccountStatements_filtersContainerBottom__vepbF .has-value .Select-control > *:last-child {
  display: inline-block;
  margin-left: 0rem;
}

.BillingAccountStatements_filtersContainerBottom__vepbF .Select--multi .Select-clear-zone {
  display: inline-block;
  margin-left: 5.5rem;
}

.BillingAccountStatements_filtersContainerBottom__vepbF .Select-value .Select-value-label {
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.BillingAccountStatements_filtersContainerBottom__vepbF .Select-input {
  height: 30px;
}
.BillingAccountStatements_filtersContainerBottom__vepbF .Select-placeholder {
  line-height: 31px;
}
.BillingAccountStatements_filtersContainerBottom__vepbF .Select-value {
  width: 100%;
  max-width: 185px;
  white-space: nowrap;
  overflow: hidden;
}
.BillingAccountStatements_rangeDatePicker__3CMx3 {
  height: 32px;
  margin-right: 1rem;
}
.BillingAccountStatements_inputTextSearch__2fNJc {
  max-width: 400px;
  width: 50%;
  min-width: 150px;
  margin-right: 1rem;
}

.BillingAccountStatements_selectStatuses__RzA24,
.BillingAccountStatements_selectFormats__ReqFY {
  height: 32px;
  width: 100%;
  margin-right: 1rem;
}

.BillingAccountStatements_applyFiltersBtn__2jMCr {
  background-color: #3d8af8;
  margin-top: 0.7rem;
  /* width: 100%; */
}

/* .campaignsTable .selectStatuses :global(.ant-select-selector),
.campaignsTable .selectFormats :global(.ant-select-selector) {
  height: 100%;
} */

.BillingAccountStatements_extActionGroup__368Fy {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 1rem 0 1rem;
}

.BillingAccountStatements_exportToCSV__1ls3h {
  border: none;
  box-shadow: none;
  color: #3d8af8;
}

.BillingAccountStatements_columnsBtn__3qIB5,
.BillingAccountStatements_reloadBtn__1ngk8 {
  color: #a3a6b4;
  margin-left: 1rem;
  border: 1px solid #bcbec6;
  border-radius: 0;
}

.BillingAccountStatements_countryDesc__2y6RE {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

/* LINK MODAL */
.BillingAccountStatements_actionGroupLink__27cjI {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.BillingAccountStatements_copyLinkBtn__1MNtU {
  margin-top: 0.5rem;
  background-color: #f9ae3b;
  border-color: #f9ae3b;
  color: #ffffff;
  /* float: right; */
}

@media (max-width: 1000px) {
  .BillingAccountStatements_filtersContainerBottom__vepbF {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .BillingAccountStatements_inputTextSearch__2fNJc,
  .BillingAccountStatements_selectStatuses__RzA24,
  .BillingAccountStatements_rangeDatePicker__3CMx3,
  .BillingAccountStatements_selectFormats__ReqFY {
    width: 100%;
    max-width: none;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  .BillingAccountStatements_applyFiltersBtn__2jMCr {
    max-width: 400px;
  }
  .BillingAccountStatements_campaignsTable__1_jhY .ant-radio-group-large .ant-radio-button-wrapper {
    display: none;
  }
  .BillingAccountStatements_extActionGroup__368Fy {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .BillingAccountStatements_titleContent__OoIK- {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .BillingAccountStatements_columnsBtn__3qIB5,
  .BillingAccountStatements_reloadBtn__1ngk8 {
    margin-bottom: 0.4rem;
  }
}
.BillingAccountStatements_actionCampaignIcons__xDRoY {
  font-size: 17px;
  position: relative;
  top: 3px;
}

.BillingPaymentHistory_campaignsTable__iN5It > h4 {
  color: #43425d;
  font-size: 1.4rem;
}

.BillingPaymentHistory_billingHeadInfo__3-iEc {
  font-size: 0.9rem;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 1rem;
}
.BillingPaymentHistory_billingHeadInfo__3-iEc > div {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-right: 3rem;
  color: rgb(89, 89, 89);
}
.BillingPaymentHistory_billingHeadInfo__3-iEc > div > span:first-child {
  font-weight: 600;
  margin-bottom: 0.2rem;
  color: rgb(61, 61, 61);
}

.BillingPaymentHistory_headingActions__g7E6L {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 30px;
}

.BillingPaymentHistory_newCampaignBtn__KHZqj {
  background-color: #3d8af8;
}

.BillingPaymentHistory_headingActions__g7E6L > div {
  margin-right: 20px;
}

.BillingPaymentHistory_columnGroupContainer__LKx_F {
  width: 500px;
}
.BillingPaymentHistory_columnGroupContainer__LKx_F .ant-checkbox-inner {
  width: 20px;
  height: 20px;
}

.BillingPaymentHistory_searchInput__AiM_N {
  width: 250px;
}

.BillingPaymentHistory_campaignsTable__iN5It table thead th {
  background-color: #fff;
  color: #707070;
  font-size: 0.9rem;
}

.BillingPaymentHistory_campaignsTable__iN5It table tbody {
  font-size: 0.85rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #808080 !important;
}

.BillingPaymentHistory_campaignsTable__iN5It .ant-table-row {
  background-color: #fff;
}

.BillingPaymentHistory_campaignsTable__iN5It .ant-table-footer {
  background-color: #fff;
}

.BillingPaymentHistory_statusColumn__3DRXY,
.BillingPaymentHistory_campaignNameWrapper__hAtKv,
.BillingPaymentHistory_campaignType__3dEwD {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.BillingPaymentHistory_campaignType__3dEwD {
  display: list-item;
  list-style-type: disc;
  list-style-position: inside;
  font-weight: bold;
  font-size: 1rem;
}
.BillingPaymentHistory_campaignType__3dEwD span {
  position: relative;
  left: -15px;
  font-size: 0.85rem;
}

.BillingPaymentHistory_campaignNameWrapper__hAtKv {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

/* .campaignsTable .campaignNameColumn {
  padding-left: 50px;
} */

.BillingPaymentHistory_statusContainer__2jq-j {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.BillingPaymentHistory_status__2k4Ca {
  text-transform: capitalize;
  min-width: 90px;
  font-size: 0.8rem;
  font-weight: 500;
  border-radius: 3px;
  padding: 0.1rem 0.5rem;
}

.BillingPaymentHistory_statusin_progress__1m9bn {
  background-color: #1990ff1a;
  border: 1px solid #1990ff;
  color: #1990ff;
}

.BillingPaymentHistory_statuspending__Fsmmq {
  background-color: #faad141a;
  border: 1px solid #faad14;
  color: #faad14;
}
.BillingPaymentHistory_statuscompleted__3V38P {
  background-color: #02875b1a;
  border: 1px solid #02875b;
  color: #02875b;
}
.BillingPaymentHistory_statusdeclined__1RUu1 {
  background-color: #d85c5c1a;
  border: 1px solid #d85c5c;
  color: #d85c5c;
}

.BillingPaymentHistory_nameInitials__20L9P {
  width: 2.3em;
  height: 2.3em;
  border-radius: 50%;
  color: #fff;
  background-color: rgb(85, 85, 85);
  padding: 5px;
  margin-right: 5px;
  text-align: center;
}

.BillingPaymentHistory_campaignsTable__iN5It .ant-table-tbody > tr > td {
  padding: 10px 12px;
}

.BillingPaymentHistory_channelName__26GMl,
.BillingPaymentHistory_companyName__3_PMH,
.BillingPaymentHistory_dateCreated__2Jv9k {
  text-align: left;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
}
/* .channelName {
  width: 200px;
} */
.BillingPaymentHistory_campaignId__3nWet {
  font-size: 0.8rem;
}
.BillingPaymentHistory_actions__14Rzq {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.BillingPaymentHistory_actions__14Rzq > div {
  cursor: pointer;
  margin-right: 10px;
}

.BillingPaymentHistory_actions__14Rzq div:last-child {
  margin-right: 0;
}

.BillingPaymentHistory_actions__14Rzq .ant-popover-inner-content {
  padding: 5px 8px !important;
}

/* CAMPAIGNS TITLE AND FILTERS AREA */

.BillingPaymentHistory_plusIconCamp__2rMv5 {
  font-size: 25px;
  font-weight: bold;
}

.BillingPaymentHistory_filtersContainer__16vRt {
  background-color: #fafafa;
  padding: 0.5rem 1rem 1rem 1rem;
  margin-bottom: 0.6rem;
}
.BillingPaymentHistory_dateRadioSelect__kNg5d {
  font-size: 0.9rem;
}

.BillingPaymentHistory_filtersContainer__16vRt .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px;
}
.BillingPaymentHistory_filtersContainer__16vRt .ant-picker {
  border-radius: 5px;
}

.BillingPaymentHistory_dateRadioSelect__kNg5d .ant-radio-button-wrapper {
  color: #3d8bf88f;
  font-size: inherit;
  background-color: transparent;
}
.BillingPaymentHistory_dateRadioSelect__kNg5d .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
  color: #3d8af8;
}
.BillingPaymentHistory_campaignsTable__iN5It .ant-radio-group-large .ant-radio-button-wrapper {
  color: #2f6fb6;
  font-size: inherit;
  background-color: transparent;
}
.BillingPaymentHistory_campaignsTable__iN5It .ant-radio-group-large .ant-radio-button-wrapper-checked {
  font-weight: 550;
  border: 1px solid !important;
  border-color: rgba(47, 111, 182, 0.4) !important;
  border-radius: 6px;
  background-color: rgba(47, 111, 182, 0.03);
  line-height: 28px;
  height: 30px;
}
.BillingPaymentHistory_campaignsTable__iN5It .ant-radio-group-large .ant-radio-button-wrapper {
  border: none;
}
.BillingPaymentHistory_campaignsTable__iN5It .ant-radio-group-large .ant-radio-button-wrapper::before {
  display: none;
}
.BillingPaymentHistory_dateRadioSelect__kNg5d {
  margin-bottom: 0.5rem;
}

.BillingPaymentHistory_hideShowLinkBtn__1u0vq {
  color: #3d8af8;
  padding: 0.5rem 0 0 0;
  font-size: 0.8rem;
}

.BillingPaymentHistory_spanMarginBottom__34mNS {
  color: rgb(95, 95, 95);
}

.BillingPaymentHistory_filtersContainerBottom__3jqu6 .Select {
  width: 200px;
  /* height: 32px; */
  margin-right: 1rem;
  border-radius: 0;
}
.BillingPaymentHistory_filtersContainerBottom__3jqu6 .Select-control {
  border-radius: 2px;
  border: 1px solid #d9d9d9;
  height: 31px;
  z-index: 10;
  overflow: auto;
}
.BillingPaymentHistory_filtersContainerBottom__3jqu6 .has-value .Select-control > *:last-child {
  display: inline-block;
  margin-left: 0rem;
}

.BillingPaymentHistory_filtersContainerBottom__3jqu6 .Select--multi .Select-clear-zone {
  display: inline-block;
  margin-left: 5.5rem;
}

.BillingPaymentHistory_filtersContainerBottom__3jqu6 .Select-value .Select-value-label {
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.BillingPaymentHistory_filtersContainerBottom__3jqu6 .Select-input {
  height: 30px;
}
.BillingPaymentHistory_filtersContainerBottom__3jqu6 .Select-placeholder {
  line-height: 31px;
}
.BillingPaymentHistory_filtersContainerBottom__3jqu6 .Select-value {
  width: 100%;
  max-width: 185px;
  white-space: nowrap;
  overflow: hidden;
}
.BillingPaymentHistory_rangeDatePicker__1ateG {
  margin-right: 1rem;
}
.BillingPaymentHistory_inputTextSearch__2__vA {
  max-width: 400px;
  width: 50%;
  min-width: 150px;
  margin-right: 1rem;
}

.BillingPaymentHistory_selectStatuses__DOiMu,
.BillingPaymentHistory_selectFormats__vtUri {
  height: 32px;
  width: 100%;
  margin-right: 1rem;
}

.BillingPaymentHistory_applyFiltersBtn__19JsU {
  background-color: #3d8af8;
  border-color: #3d8af8;
  margin-left: auto;
}

/* .campaignsTable .selectStatuses :global(.ant-select-selector),
.campaignsTable .selectFormats :global(.ant-select-selector) {
  height: 100%;
} */

.BillingPaymentHistory_extActionGroup__1bSWY {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding: 1rem 0 1rem;
}

.BillingPaymentHistory_exportToCSV__35iNE {
  border: none;
  box-shadow: none;
  color: #3d8af8;
}

.BillingPaymentHistory_columnsBtn__3JZ8V,
.BillingPaymentHistory_reloadBtn__3GjZn {
  color: #a3a6b4;
  margin-left: 1rem;
  border: 1px solid #bcbec6;
  border-radius: 0;
}

.BillingPaymentHistory_countryDesc__jw5Vr {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

/* LINK MODAL */
.BillingPaymentHistory_actionGroupLink__2IIZU {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.BillingPaymentHistory_copyLinkBtn__19ElM {
  margin-top: 0.5rem;
  background-color: #f9ae3b;
  border-color: #f9ae3b;
  color: #ffffff;
  /* float: right; */
}

.BillingPaymentHistory_receiptButton__SkSH2 {
  height: 32px;
  width: 32px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 2px;
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid #d9d9d9;
  color: rgb(111, 111, 111);
}

@media (max-width: 1000px) {
  .BillingPaymentHistory_filtersContainerBottom__3jqu6 {
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
  }
  .BillingPaymentHistory_inputTextSearch__2__vA,
  .BillingPaymentHistory_selectStatuses__DOiMu,
  .BillingPaymentHistory_rangeDatePicker__1ateG,
  .BillingPaymentHistory_selectFormats__vtUri {
    width: 100%;
    max-width: none;
    margin: 0;
    margin-bottom: 0.5rem;
  }
  .BillingPaymentHistory_applyFiltersBtn__19JsU {
    max-width: 400px;
    margin: 0;
  }
  .BillingPaymentHistory_campaignsTable__iN5It .ant-radio-group-large .ant-radio-button-wrapper {
    display: none;
  }
  .BillingPaymentHistory_extActionGroup__1bSWY {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .BillingPaymentHistory_titleContent__nlNWM {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .BillingPaymentHistory_columnsBtn__3JZ8V,
  .BillingPaymentHistory_reloadBtn__3GjZn {
    margin-bottom: 0.4rem;
  }
}
.BillingPaymentHistory_actionCampaignIcons__VTH0X {
  font-size: 17px;
  position: relative;
  top: 3px;
}

/* GENERAL STYLING */

.Payment_paymentDetailsContainer__13FMZ {
  min-height: 70vh;
  position: relative;
  padding-bottom: 60px;
}
.Payment_paymentDetailsContainer__13FMZ .ant-spin-container {
  position: static;
}
.Payment_transactionDetails__3T0U4,
.Payment_customerInformation__39d0b {
  color: #4d4f5c;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1100px;
  grid-gap: 1.3rem;
  gap: 1.3rem;
  margin-bottom: 2.5rem;
}
.Payment_transactionDetails__3T0U4 > h4,
.Payment_customerInformation__39d0b > h4 {
  grid-column: 1 / 4;
  margin-bottom: 0.3rem;
  color: #4d4f5c;
  font-size: 1rem;
  font-weight: 700;
}

.Payment_paymentTitle__2P8sE {
  font-size: 1.2rem;
  color: #4d4f5c;
  font-weight: 600;
  margin-bottom: 0.7rem;
  text-transform: capitalize;
}
.Payment_tyMessage__1pClE {
  font-size: 0.9rem;
  margin-bottom: 1.5rem;
  color: #4d4f5c;
  font-weight: 400;
}

.Payment_detailsPair__1pjQN > p {
  font-size: 0.9rem;
  margin: 0px;
}
.Payment_detailsPair__1pjQN > p:first-child {
  font-weight: 600;
  margin-bottom: 0.3rem;
}

.Payment_tagStatus__18uYO {
  display: inline;
  font-size: 0.85rem;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 3px;
  padding: 0.1rem 0.8rem;
}
.Payment_tagStatusPaid__GpnpF {
  background-color: #02875b1a;
  border: 1px solid #02875b;
  color: #02875b;
}
.Payment_tagStatusFailed__29mn7 {
  background-color: #d85c5c1a;
  border: 1px solid #d85c5c;
  color: #d85c5c;
}
.Payment_tagStatusCanceled__1r_Mf {
  background-color: #faad141a;
  border: 1px solid #faad14;
  color: #faad14;
}

/* failed payment*/
.Payment_failPaymentContainer__3O406 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.Payment_failPaymentContainer__3O406 > h4 {
  font-family:  Montserrat;
  font-size: 2rem;
  font-weight: 700;
  color: #44435b;
  margin-bottom: 1.875rem;
  /* margin-bottom: 30px; */
}
.Payment_failPaymentContainer__3O406 > p {
  width: 70%;
  text-align: center;
  font: normal normal normal 1.05rem/1.125rem Montserrat;
  margin-bottom: 1.875rem;
  /* margin-bottom: 30px; */
}
.Payment_failPaymentContainer__3O406 > img {
  width: auto;
  max-width: 190px;
  margin-top: 2.5rem;
  margin-bottom: 3.125rem;
}

.Payment_goBack__6Rs8w {
  margin-top: 1.25rem;
  border-color: #ffaa2d;
  background: #ffaa2d 0% 0% no-repeat padding-box;
  border-radius: 5px;
  height: 45px;
  padding: 0 70px 0px 70px;
  font: normal normal 600 1rem/1.625rem "Montserrat", sans-serif;
}

.Payment_enableCampaigns__2THDK {
  border-top: 1px solid #f0f2f5;
  color: #4d4f5c;
  padding: 1.5rem 0;
}
.Payment_enableCampaigns__2THDK > h3 {
  font-size: 1rem;
  font-weight: 600;
  color: #4d4f5c;
  margin-bottom: 0.7rem;
}
.Payment_enableCampaigns__2THDK > p {
  color: #4d4f5c;
  margin-bottom: 2rem;
}

@media (max-width: 1000px) {
  .Payment_transactionDetails__3T0U4,
  .Payment_customerInformation__39d0b {
    grid-template-columns: repeat(2, 1fr);
  }
  .Payment_transactionDetails__3T0U4 > h4,
  .Payment_customerInformation__39d0b > h4 {
    grid-column: 1 / 3;
    font-size: 0.9rem;
  }
  .Payment_paymentTitle__2P8sE {
    font-size: 1rem;
  }
  .Payment_tyMessage__1pClE {
    font-size: 0.9rem;
  }
  .Payment_detailsPair__1pjQN > p {
    font-size: 0.9rem;
  }
  .Payment_tagStatus__18uYO {
    font-size: 0.9rem;
  }
  .Payment_enableCampaigns__2THDK > h3 {
    font-size: 0.9rem;
  }
}

@media (max-width: 700px) {
  .Payment_transactionDetails__3T0U4,
  .Payment_customerInformation__39d0b {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 1rem;
    gap: 1rem;
  }
  .Payment_transactionDetails__3T0U4 > h4,
  .Payment_customerInformation__39d0b > h4 {
    grid-column: 1 / 2;
    font-size: 0.9rem;
  }
  .Payment_paymentTitle__2P8sE {
    font-size: 0.95rem;
  }
  .Payment_tyMessage__1pClE {
    font-size: 0.8rem;
  }
  .Payment_detailsPair__1pjQN > p {
    font-size: 0.8rem;
  }
  .Payment_tagStatus__18uYO {
    font-size: 0.8rem;
  }
}

.AgreementModal_agreementModalWrapper__31CyJ .ant-modal-content {
    border-radius: 5px;
}

.AgreementModal_agreementModalWrapper__31CyJ .ant-modal-header {
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    padding: 20px 30px 0 30px;
}

.AgreementModal_agreementModalWrapper__31CyJ .ant-modal-close-x {
    font-size: 12px;
}

.AgreementModal_agreementModalWrapper__31CyJ .ant-modal-body {
    padding: 20px 30px 10px 30px;
}

.AgreementModal_agreementModalWrapper__31CyJ .ant-modal-footer {
    border-top: none;
    padding: 0 30px 20px 30px;
}

.AgreementModal_agreementModalWrapper__31CyJ .AgreementModal_optionsContainer__2azON {
    border: 1px solid #F0F0F0;
    border-radius: 5px;
    padding: 15px 15px 0;
    margin-top: 1rem;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.AgreementModal_agreementModalWrapper__31CyJ .AgreementModal_optionsContainer__2azON .ant-checkbox-wrapper {
    width: 100%;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    padding: 15px;
    margin-bottom: 15px;
    margin-left: 0;
}

.AgreementModal_agreementModalWrapper__31CyJ .AgreementModal_actionButtons__3lvxw {
    margin-bottom: 1rem;
}

.AgreementModal_agreementModalWrapper__31CyJ .AgreementModal_actionButtons__3lvxw button:first-child {
    margin-right: 1rem;
}

.AgreementModal_agreementModalWrapper__31CyJ .AgreementModal_actionButtons__3lvxw .ant-form-item-control-input-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}
.PageNotFound_container__2Jmt7 {
  position: relative;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.PageNotFound_containerGroup__2eV4P {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.PageNotFound_containerGroup__2eV4P > img {
  margin-top: 40px;
  height: 90%;
  width: 90%;
}

.PageNotFound_title__1QMJ7 {
  font: normal normal bold 160px 'Montserrat', sans-serif;
  margin-bottom: 0;
  color: #ffffff;
}

.PageNotFound_containerGroup__2eV4P > p {
  color: #ffffff;
}

.PageNotFound_goBack__lZtQ7 {
  /* background: #FFAA2D; */
  border-color: #ffaa2d;
  background-color: #ffaa2d;
  /* box-shadow: 0px 2px 8px #0000001A; */
  border-radius: 15px;
  margin-top: 20px;
  height: 45px;
  /* height: 3.2142857142857144em; */
  padding: 0 70px 0px 70px;
  font: normal normal 600 18px/26px 'Montserrat', sans-serif;
}

.PageNotFound_paragraphEmptySpaceship__1L7G0 {
  font: normal normal bold 26px/26px 'Montserrat', sans-serif;
}

.PageNotFound_paragraphNotExistPage__2YJXb {
  /* margin-top: 40px; */
  font: normal normal normal 18px/26px 'Montserrat', sans-serif;
}

@media (max-width: 640px) {
  .PageNotFound_title__1QMJ7 {
    font-size: 160px;
  }
  .PageNotFound_paragraphEmptySpaceship__1L7G0 {
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .PageNotFound_title__1QMJ7 {
    font-size: 120px;
  }
  .PageNotFound_container__2Jmt7 {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.Activation_activationSection__2ASfZ .ant-spin-nested-loading{
  width: 100%;
}

.Activation_activationSection__2ASfZ .ant-checkbox-wrapper{
  color: #9d9d9d;
}

.Activation_activationSection__2ASfZ .Activation_activation__1kF6D {
  display: -webkit-flex;
  display: flex;
  height: 100vh;
}

.Activation_formLabels__1nBIR {
  font-size: 0.875rem;
  margin-bottom: .5rem;
}

.Activation_activationSection__2ASfZ .ant-input-affix-wrapper{
  font-weight: 300;
  width: 100%;
  padding: 1rem;
  border: 1px solid #ECECEC;
  background-color: #ffffff;
  border-radius: 8px;
}

.Activation_activationSection__2ASfZ .ant-form-item-explain.ant-form-item-explain-error{
  font-size: 0.75rem;
}

.Activation_activationSection__2ASfZ .ant-input-password-icon{
  width: 30px;
}

.Activation_activationSection__2ASfZ .ant-form-item:last-child {
  margin-top: 1.5rem;
  margin-bottom: .8rem;
}

.Activation_activationSection__2ASfZ .Activation_logoLight__1BywZ {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 40px;
  left: 40px;
}

.Activation_activationSection__2ASfZ .Activation_logoLight__1BywZ img {
  width: 120px;
}

.Activation_activationSection__2ASfZ .Activation_logoLight__1BywZ p {
  color: white;
  font-size: 18px;
  margin-top: 8px;
  -webkit-text-stroke: 0.2px black;
}

.Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationLeft__1KeKi .Activation_leftBg__2sGy2 {
  height: 100%
}

.Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationRight__3-ak5 {
  padding: 0px 60px 60px 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-justify-content: center;
          justify-content: center;
}

.Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationRight__3-ak5 .Activation_center__26BD5 {
  -webkit-align-self: center;
          align-self: center;
  width: 60%;
}

.Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationRight__3-ak5 img {
  max-width: 180px
}

.Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationRight__3-ak5 .Activation_activationForm__WbBHB h1 {
  color: #012d6c;
  font-weight: bold;
  font-size: 30px;
  margin: 15px 0px 30px 0px;
}

.Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationRight__3-ak5 .Activation_subTxt__1SisL {
  color: #0071bd;
  text-decoration: underline;
  margin-top: 2%;
  display: inline-block;
}

.Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationRight__3-ak5 .Activation_box__3Fq9J {
  padding: 20px 0px;
}

.Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationRight__3-ak5 .Activation_main-txt__3R-5q {
  color: #012d6c;
  font-weight: bold;
}

.Activation_activationSection__2ASfZ .ant-btn-primary {  
  display: block;
  width: 100%;
  height: auto;
  padding: 16px 0px;
  background-color: #0071bd;
  border-radius: 8px;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.125rem;
}

@media (max-width: 1100px) {
  .Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationRight__3-ak5 .Activation_center__26BD5 {
    width: 80%;
  }
}

@media (max-width: 1000px) {
  .Activation_activationSection__2ASfZ .Activation_activation__1kF6D {
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
    top: -700px;
  }

  .Activation_activationSection__2ASfZ .Activation_logoLight__1BywZ {
    display: none;
  }

  .Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationRight__3-ak5 .Activation_activationForm__WbBHB > img {
    display: block;
    margin: auto;
    margin-bottom: 1rem;
  }

  .Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationRight__3-ak5 h1 {
    text-align: center;
  }

  .Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationLeft__1KeKi .Activation_leftBg__2sGy2 {
    height: 100%;
    width: 100%;
  }
}

@media (max-width: 880px) {
  .Activation_activationSection__2ASfZ .Activation_activation__1kF6D {
    top: -610px;
  }
}

@media (max-width: 760px) {
  .Activation_activationSection__2ASfZ .Activation_activation__1kF6D {
    top: -500px;
  }
}

@media (max-width: 600px) {
  .Activation_activationSection__2ASfZ .Activation_activation__1kF6D {
    top: -400px;
  }

  .Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationRight__3-ak5 .Activation_subTxt__1SisL {
    margin-top: 5%;
  }

  .Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationRight__3-ak5 img {
    max-width: 120px;
  }

  .Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationRight__3-ak5 .Activation_center__26BD5 {
    width: 100%;
  }

  .Activation_activationSection__2ASfZ form {
    width: auto;
  }

  .Activation_activationSection__2ASfZ .ant-spin-nested-loading{
    width: auto;
  }

  .Activation_activationSection__2ASfZ .ant-btn-primary {  
    font-size: inherit;
  }
}

@media (max-width: 500px) {
  .Activation_activationSection__2ASfZ .Activation_activation__1kF6D {
    top: -250px;
  }
}


@media (min-height: 1140px) and (max-width: 1366px) and (min-width: 1000px){
  .Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationLeft__1KeKi {
    display: none;
  }
}


@media (max-height: 700px),
(max-width: 600px) {
  .Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationRight__3-ak5 {
    padding: 40px;
    display: block;
  }

  .Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationRight__3-ak5 .Activation_activationForm__WbBHB h1 {
    font-size: 24px;
    line-height: 1;
  }

  .Activation_activationSection__2ASfZ .Activation_activation__1kF6D .Activation_activationRight__3-ak5 .Activation_center__26BD5 {
    padding-bottom: 40px;
    margin: auto;
  }
}

.ResetPassForm_resetPassFormContainer__MTn54 .ant-spin-nested-loading{
  width: 100%;
}

.ResetPassForm_activationSection__2dAYg .ant-checkbox-wrapper{
  color: #9d9d9d;
}


.ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J {
  display: -webkit-flex;
  display: flex;
  height: 100vh;
}

.ResetPassForm_formLabels__MbzZj {
  font-size: 0.875rem;
  margin-bottom: .5rem;
}

.ResetPassForm_resetPassFormContainer__MTn54 .ant-input-affix-wrapper{
  font-weight: 300;
  width: 100%;
  padding: 1rem;
  border: 1px solid #ECECEC;
  background-color: #ffffff;
  border-radius: 8px;
}

.ResetPassForm_resetPassFormContainer__MTn54 .ant-form-item-explain.ant-form-item-explain-error{
  font-size: 0.75rem;
}

.ResetPassForm_resetPassFormContainer__MTn54 .ant-input-password-icon{
  width: 30px;
}

.ResetPassForm_resetPassFormContainer__MTn54 .ant-form-item:last-child {
  margin-top: 1.5rem;
  margin-bottom: .8rem;
}

.ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_logoLight__2zi4N {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 40px;
  left: 40px;
}

.ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_logoLight__2zi4N img {
  width: 120px;
}

.ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_logoLight__2zi4N p {
  color: white;
  font-size: 18px;
  margin-top: 8px;
  -webkit-text-stroke: 0.2px black;
}

.ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetLeft__1YJgT .ResetPassForm_leftBg__1wQIk {
  height: 100%
}

.ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU {
  padding: 0px 60px 60px 60px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-justify-content: center;
          justify-content: center;
}

.ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU .ResetPassForm_containerResetPass__vvd62 {
  -webkit-align-self: center;
          align-self: center;
  width: 60%;
}

.ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU img {
  max-width: 180px
}

.ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU .ResetPassForm_formHeader__2rKgd h1 {
  color: #012d6c;
  font-weight: bold;
  font-size: 30px;
  margin: 15px 0px 30px 0px;
}

.ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU .ResetPassForm_formHeader__2rKgd h2 {
  color: #012d6c;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 1rem;
}

.ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU .ResetPassForm_subTxt__2gjT- {
  color: #0071bd;
  text-decoration: underline;
  margin-top: 2%;
  display: inline-block;
}

.ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU .ResetPassForm_box__1b-v0 {
  padding: 20px 0px;
}

.ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU .ResetPassForm_main-txt__kKmMu {
  color: #012d6c;
  font-weight: bold;
}

.ResetPassForm_resetPassFormContainer__MTn54 .ant-btn-primary {  
  display: block;
  width: 100%;
  height: auto;
  padding: 16px 0px;
  background-color: #0071bd;
  border-radius: 8px;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.125rem;
}

@media (max-width: 1100px) {
  .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU .ResetPassForm_containerResetPass__vvd62 {
    width: 80%;
  }
}

@media (max-width: 1000px) {
  .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J {
    -webkit-flex-direction: column;
            flex-direction: column;
    position: relative;
    top: -700px;
  }

  .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_logoLight__2zi4N {
    display: none;
  }

  .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU .ResetPassForm_formHeader__2rKgd > img {
    display: block;
    margin: auto;
    margin-bottom: 1rem;
  }

  .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU h1 {
    text-align: center;
  }

  .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetLeft__1YJgT .ResetPassForm_leftBg__1wQIk {
    height: 100%;
    width: 100%;
  }

  .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU .ResetPassForm_formHeader__2rKgd h1, .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU .ResetPassForm_formHeader__2rKgd h2 {
    text-align: center;
  }
}

@media (max-width: 880px) {
  .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J {
    top: -610px;
  }
}

@media (max-width: 760px) {
  .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J {
    top: -500px;
  }
}

@media (max-width: 600px) {
  .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J {
    top: -400px;
  }

  .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU .ResetPassForm_subTxt__2gjT- {
    margin-top: 5%;
  }

  .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU img {
    max-width: 120px;
  }

  .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU .ResetPassForm_containerResetPass__vvd62 {
    width: 100%;
  }

  .ResetPassForm_resetPassFormContainer__MTn54 form {
    width: auto;
  }

  .ResetPassForm_resetPassFormContainer__MTn54 .ant-spin-nested-loading{
    width: auto;
  }

  .ResetPassForm_resetPassFormContainer__MTn54 .ant-btn-primary {  
    font-size: inherit;
  }
}

@media (max-width: 500px) {
  .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J {
    top: -250px;
  }
}


@media (min-height: 1140px) and (max-width: 1366px) and (min-width: 1000px){
  .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetLeft__1YJgT {
    display: none;
  }
}


@media (max-height: 700px),
(max-width: 600px) {
  .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU {
    padding: 40px;
    display: block;
  }

  .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU .ResetPassForm_formHeader__2rKgd h1 {
    font-size: 24px;
    line-height: 1;
  }

  .ResetPassForm_resetPassFormContainer__MTn54 .ResetPassForm_reset__2bH3J .ResetPassForm_resetRight__RfukU .ResetPassForm_containerResetPass__vvd62 {
    padding-bottom: 40px;
    margin: auto;
  }
}

.LockPage_container__1PjTe {
  position: relative;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.LockPage_containerGroup__16om8 {
  max-width: 500px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 90%;
}

.LockPage_containerGroup__16om8 > img {
  margin-top: 10px;
  max-width: 200px;
}

.LockPage_containerGroup__16om8 > .LockPage_title__2tj7c {
  font-size: 2.4rem;
  font-weight: 500;
  margin-bottom: 1rem;
  color: #ffffff;
}

.LockPage_containerGroup__16om8 > p {
  text-align: center;
  font-size: 1rem;
  color: #ffffff;
}

.LockPage_goBack__1sMdA {
  /* background: #FFAA2D; */
  border-color: #ffaa2d;
  background: #ffaa2d 0% 0% no-repeat padding-box;
  /* box-shadow: 0px 2px 8px #0000001A; */
  border-radius: 3px;
  margin-top: 20px;
  height: 45px;
  /* height: 3.2142857142857144em; */
  padding: 0 70px 0px 70px;
  font: normal normal normal 18px/26px 'Source Sans Pro', sans-serif;
}

.LockPage_paragraphEmptySpaceship__uqpmZ {
  font: normal normal 600 25px/26px 'Source Sans Pro', sans-serif;
}

.LockPage_paragraphNotExistPage__CYZjE {
  margin-top: 40px;
  font: normal normal normal 16px/26px 'Source Sans Pro', sans-serif;
}

.App_spinnerContainer__2Qcqz {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100vh;
}


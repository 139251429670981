@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&display=swap');

.ant-layout {
  overflow: auto;
}

.ant-layout-sider-collapsed .logo img {
  width: 100%;
}

.ant-layout-sider-collapsed .logo span {
  display: none;
}

.logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  margin: 16px;
  color: #fff;
}

.logo img {
  display: block;
  width: 50%;
  margin: 0 auto;
}
.service-desk-link {
  padding-left: 24px;
  color: #a6b5cc;
  transition: color 0.4s;
}
.service-desk-link:hover {
  color: #fff;
}
.service-desk-link span {
  padding-left: 6px;
}
.ant-layout-sider-collapsed .service-desk-link {
  text-align: center;
  padding: 0;
  display: block;
  font-size: 20px;
}
.ant-layout-sider-collapsed .service-desk-link span {
  display: none;
}

.logo span {
  position: relative;
  top: 5px;
}

.custom-menu-item {
  display: block;
  width: 100%;
  height: 100%;
}

/* #nprogress .bar {
  background: #1890ff;
}

#nprogress .peg {
  box-shadow: 0 0 10px #1890ff, 0 0 5px #1890ff;
}

#nprogress .spinner-icon {
  border-top-color: #1890ff;
  border-left-color: #1890ff;
} */

.has-error {
  font-size: 10px;
}
.ant-layout-sider-children {
  overflow: auto;
}

.ant-layout-sider {
  background: #012d6d;
}

.ant-menu.ant-menu-dark {
  background: #012d6d;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: #012d6d;
  box-shadow: inset 0 2px 8px rgba(0, 0, 0, 0.45);
}

.ant-layout-sider-trigger {
  background: #2964a9;
}
.ant-layout-sider-zero-width-trigger {
  top: 10px;
  z-index: 9999;
}
@media (max-width: 600px) {
  .ant-popover-placement-bottomRight > .ant-popover-content > .ant-popover-arrow {
    display: none;
  }
}
.export-btn {
  margin: 0.8%;
}

/* GLOBAL STYLES: */
.divider {
  background: #f0f2f6;
  margin-top: 4%;
  width: 5000px; /* TODO: WTF??? */
  padding-left: 0;
  margin-left: -25px;
  padding: 5px;
  font-size: 1.4em;
}

h1 {
  font-size: 2em;
}

.modal-button {
  width: 100%;
  overflow: hidden;
}

/* HEADER styles */

.button-rocket {
  display: flex;
  align-items: center;
  background: #f9ae3b 0% 0% no-repeat padding-box;
  border-radius: 15px;
  border: none;
  color: white;
  font-size: 1rem;
  height: 45px;
  font-family: 'Montserrat', sans-serif;
}
.button-rocket:hover {
  background-color: #f9ad3bdc;
  color: #fff;
}

.header-new-camp-middle {
  display: flex;
  align-items: center;
}
.button-rocket img {
  width: 45px;
  transition: 0.3s;
}
.button-rocket:hover img {
  transform: translateY(-5px);
}

.header-wallet {
  text-align: right;
  white-space: nowrap;
  margin-left: 20px;
}
.coins-value {
  color: #5a9e4c;
  /* font-family: normal; */
  font-weight: bold;
  font-size: 1.1rem;
  /* font: Bold 1.4rem/32px Source Sans Pro; */
  margin-right: 20px;
}
.coins-credit-value {
  color: #c6d699;
  /* font-family: normal; */
  font-weight: bold;
  font-size: 1.1rem;
  /* font: Bold 1.4rem/32px Source Sans Pro; */
  margin-right: 20px;
}
.wallet {
  width: 26px;
  padding-bottom: 8px;
  margin-right: 7px;
}

.wallet-limit {
  width: 30px;
  padding-bottom: 4px;
  margin-right: 7px;
}

.header-main {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-right svg {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.header-dropdown {
  top: 50px !important;
  width: auto !important;
}
.header-dropdown .ant-dropdown-menu {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 14px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.header-dropdown .ant-dropdown-menu-item:hover,
.header-dropdown .ant-dropdown-menu-item a:hover {
  color: #1890ff;
}

.header-action-btn {
  color: #bcbccb;
  margin-left: 20px;
}
.header-action-btn:nth-child(2) {
  margin-right: 10px;
}
.header-action-btn:hover {
  color: #2d71b6;
  transition: 0.3s;
}
.header-username {
  max-height: 63.99px;
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  text-align: right;
}
.header-username > span {
  line-height: 0.9rem;
}
.header-username > span:nth-child(1) {
  color: #848484;
}
.header-username > span:nth-child(2) {
  color: rgb(169, 169, 169);
}
.header-highligh {
  color: #1890ff;
  font-weight: bold;
}
.profile-avatar {
  width: 38px;
  height: 38px;
  object-fit: cover;
  position: relative;
  bottom: 2px;
  border-radius: 50%;
}

.credit-label {
  font-size: 1.1rem;
  margin-right: 0.3rem;
  color: rgb(112, 112, 112);
  font-weight: bold;
}
.down-arrow svg {
  color: rgb(180, 180, 180);
  width: 10px;
  height: 10px;
  margin-right: 5px;
  margin-left: 5px;
}
.header-right-spinner {
  width: 30%;
}
.header-right-user {
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: opacity 0.2s;
}
.header-right-user:hover {
  opacity: 0.8;
}
.header-right-user > span {
  white-space: nowrap;
}
.billingDataMissingNotification {
  height: 40px;
  background: rgb(250, 191, 98);
  display: flex;
  align-content: center;
  justify-content: center;
  color: #032b6d;
}
/* End Header styles */

/* SIDEBAR */

.ant-menu-dark.ant-menu-inline .ant-menu-item {
  padding-right: 13px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent;
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected .menuItemRestyle {
  background-color: rgba(255, 255, 255, 0.07);
  border: 1px solid rgba(255, 255, 255, 0.14);
}
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected .menuItemRestyle > a {
  color: #faad14 !important;
}

.ant-menu-inline-collapsed > .ant-menu-item {
  padding: 0;
  display: flex;
  justify-content: center;
}
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding-left: 22px;
}
.ant-layout-sider-collapsed .menuItemRestyle {
  border-radius: 6px;
  padding-left: 0px;
  display: flex;
  justify-content: center;
  width: 40px;
  max-height: 40px;
}

.menuItemRestyle {
  border-radius: 6px;
  padding-left: 10px;
  max-height: 40px;
}

.menuItemRestyle > a {
  transition: 0s;
  color: #fff;
}

.menuItemRestyle > a > span {
  transition: all 0.2s;
  color: inherit !important;
}

.ant-menu.ant-menu-dark .ant-menu-sub {
  color: rgba(255, 255, 255, 0.65);
  background: #154486;
}

.ant-menu-item.ant-menu-item-only-child.ant-menu-item-active .menuItemRestyle > a {
  color: #faad14 !important;
}

.ant-menu-submenu-title {
  color: #fff;
}

.submenuTitleRestyle {
  padding: 10px;
}

.ant-menu.ant-menu-dark {
  background-color: transparent;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background: transparent;
  box-shadow: none;
}

.ant-layout-sider {
  background-image: url(https://publisher.coinis.com/images/moduleBackground.png);
}

/* Sidebar rocket */

.sidebarcontent .ant-layout-sider-children {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebarModuleDisp {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 350px;
  white-space: nowrap;
  color: #e0a64e;
  font-size: 1.3rem;
  font-weight: 600;
  bottom: 0;
  text-align: center;
  margin-bottom: 0;
  padding-bottom: 1.5rem;
  background-position: bottom;
  background-size: 200px;
  background-repeat: no-repeat;
}

.sidebarModuleDisp > span {
  transition: color 0.2s;
}

.sidebarModuleDisp > span:first-child:hover {
  color: #e0a54e72;
}

.sidebarModuleDisp > span:first-child {
  padding-bottom: 1rem;
  word-wrap: break-word;
  cursor: pointer;
}

/* .ant-layout.ant-layout-has-sider > .ant-layout {
  overflow-x: hidden;
} */

.balanceField {
  color: #73b566;
  font-size: 1.1rem;
  font-weight: bold;
  padding: 0.5rem;
  display: flex;
  align-items: center;
}
.balanceField > img {
  height: 24px;
}
.balanceField > p {
  padding-left: 0.3rem;
  margin: 0;
}
.balanceField > img {
  padding-bottom: 0.2rem;
}

.headerDropdown .ant-dropdown-menu {
  max-width: 320px;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  padding: 0px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 14px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.headerDropdown .ant-dropdown-menu-item {
  padding: 0px 0px;
}
.headerDropdown .ant-dropdown-menu-item:hover {
  background-color: transparent;
}

.broadcastNotification {
  display: inline;
}

.broadcastNotification>p {
  display: inline;
}

.barNotification {
  display: inline;
}

.barNotification>p {
  display: inline;
  font-size: 14px;
  color: #4D4F5C;
}

.barNotificationList {
  width: 496px;
  border-radius: 8px;
  border: transparent;
  background: white;
  box-shadow: 0px 2px 15px #0000000D;
}

.virtualListWrapper .rc-virtual-list-holder {
  border-radius: 0 0 8px 8px;
}

.barNotificationList .ant-list-footer {
  padding: 0;
}

.barNotificationList .ant-list-bordered .ant-list-header {
  border-radius: 8px 8px 0 0;
  box-shadow: 0px 2px 15px #0000000D;
}

.barNotificationList .unReadlistItem {
  width: 496px;
  background-color: rgba(25, 144, 255, 0.05);
}

.barNotificationList .notificationTitle {
  display: flex;
  justify-content: space-between;
  color: #4D4F5C;
}

.barNotificationList .notificationTitle label.ant-radio-wrapper {
  margin: 0;
}

.barNotificationList .notificationTitle .ant-radio-inner::after {
  background-color: #FFAA2D;
}

.barNotificationList .notificationTitle .ant-radio-checked:after {
  border: 1px solid transparent !important;
}

.barNotificationList .notificationTitle .ant-radio-input:focus + .ant-radio-inner {
  box-shadow: none;
}

.barNotificationList .notificationTitle .ant-radio-inner {
  background-color: #F0F0F0;
  border-color: #F0F0F0;
}

.barNotificationList .notificationTitle div {
  display: flex;
  align-items: center;
}

.barNotificationList .notificationTitle .notificationIcon {
  margin-right: .4rem;
}

.barNotificationList .notificationTitle h3 {
  font-size: .95rem;
  font-weight: 600;
  color: #4D4F5C;
  vertical-align: middle;
  margin: 0;
}

.barNotificationList .notificationDescription {
  margin-top: 10px;
  overflow: hidden;
  position: relative;
}

.barNotificationList .notificationDate {
  margin-top: .5rem;
  font-size: 12px;
  color: #989898;
}

.notificationDescription .notificationExpandLinkWrapperRead {
  padding-left: 5px;
  margin: 0;
  background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 1));
  cursor: pointer;
}

.notificationDescription .notificationExpandLinkWrapperUnread {
  padding-left: 5px;
  margin: 0;
  background-color: rgba(25, 144, 255, 0.05);
  cursor: pointer;
}

.notificationExpandLink {
  color: #4D4F5C;
  text-decoration: underline;
}

.barNotificationItemModal .ant-modal-content {
  border-radius: 5px;
}

.barNotificationItemModal .ant-modal-header {
  padding: 20px 30px 10px;
  border-bottom: 0px solid #f0f0f0;
  border-radius: 5px 5px 0 0;
}

.barNotificationItemModal .ant-modal-body {
  padding: 10px 30px 10px;
}

.barNotificationItemModal .ant-modal-footer {
  border-top: 0px solid #f0f0f0;
  padding: 10px 30px 20px;
}

.barNotificationItemModal .barNotificationItemModalTitle {
  display: flex;
  align-items: center;
}

.barNotificationItemModal .barNotificationItemModalTitle h3 {
  display: inline;
  font-size: 1rem;
  font-weight: 600;
  color: #4D4F5C;
  vertical-align: middle;
  margin: 0;
}

.barNotificationItemModalIcon {
  vertical-align: middle;
  margin-right: 0.4rem;
}

/* AGREEMENT SECTION */

.agreementContainer {
  background: #FFAA2D1A;
  border: 1px solid #FFA13B;
  border-radius: 6px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 8px;
}

.agreementContainer p {
  margin: 0;
  font-size: 14px;
  line-height: 1.2;
  text-align: right;
  color: #4D4F5C;
}

.agreementContainer p:first-child {
  color: #FFAA2D;
  font-weight: 600;
}

.agreementContainer svg {
  margin-left: 20px;
  color: #FFA13B;
  height: 32px;
  width: 32px;
}

.agreementContainerBilling {
  background: #F849491A;
  border: 1px solid #F84949;
  border-radius: 6px;
  padding: 8px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  margin-right: 8px;
}

.link {
  text-decoration: underline;
  color: #1990FF;
  cursor: pointer;
}

@media (max-width: 1500px) {
  /* .header-username {
    display: none;
  } */
  .button-rocket {
    font-size: 0.9rem;
    padding: 0.5rem 0.7rem;
    height: 40px;
  }
  /* .header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  } */
}

/* @media screen and (max-height: 935px) {
  .sidebarModuleDisp {
    background-image: none !important;
  }
} */

@media (max-width: 900px) {
  /* .header-wallet, */
  .header-action-btn {
    display: none;
  }
}

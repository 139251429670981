.creativePreview {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 12px;
  align-items: flex-end;
}

.contentPreview {
  display: flex;
  margin-bottom: 0.8rem;
}

.contentPreview > img {
  height: 60px;
  width: 60px;
  object-fit: contain;
}

.pushLayout {
  display: flex;
  flex-direction: column-reverse;
}

.previewContainer > div {
  max-width: 350px;
  width: 100%;
  padding: 0.8rem;
  border: 1px solid rgb(238, 238, 238);
  border-radius: 5px;
}

.creativePreviewMain {
  width: 100%;
  border: 1px solid rgb(204, 204, 204);
  min-height: 400px;
}

.headerTitle {
  background-color: #fbfbfd;
  border-bottom: 1px solid rgb(204, 204, 204);
  height: 80px;
  color: #4d4f5c;
  display: flex;
  align-items: center;
}
.headerTitle > p {
  margin: 0;
  padding-left: 1rem;
}
.headerTitle span {
  color: #a6a6a6;
}

.previewContainer {
  display: flex;
  justify-content: center;
  padding: 2rem;
}
.imagePreview {
  width: 100%;
  height: 200px;
  object-fit: contain;
}
.contentPreview > div > p:nth-child(1) {
  color: rgb(67, 67, 67);
  margin: 0.2rem 0 0 0.5rem;
  font-weight: 500;
  font-size: 1rem;
}
.contentPreview > div > p:nth-child(2) {
  color: rgb(177, 177, 177);
  margin: 0rem 0.5rem;
  font-size: 0.8rem;
}

.noSelection {
  color: #a9a9a9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80%;
  text-align: center;
}

.creativesSaveBtn {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  background-color: #4090f7;
  border-color: #4090f7;
}

@media only screen and (max-width: 1150px) {
  .creativePreview {
    margin-right: 12px;
  }
  .creativePreviewMain {
    margin-bottom: 12px;
  }
  .noSelection {
    margin-top: 12px;
  }
}

.circleNumber {
  border-radius: 50%;
  text-align: center;
  font-weight: 500;
  color: white;
  line-height: 20px;
  background-color: #b2b2b2;
  width: 20px;
  height: 20px;
  display: inline-block;
}

.tabName {
  width: 165px;
  height: 23px;
  text-align: left;
  font: Regular 18px/51px Source Sans Pro;
  letter-spacing: 0px;
  opacity: 1;
  padding-left: 7px;
}

.customTab {
  padding-left: 0;
}

.newCampaignTabsRow :global(.ant-tabs .ant-tabs-left-bar) {
  color: #9d9d9d;
}

.newCampaignTabsRow :global(.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn) {
  font-weight: normal;
}
.newCampaignTabsRow :global(.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab) {
  margin: 0;
}
.newCampaignTabsRow :global(.ant-tabs-nav .ant-tabs-tab-active) .tabNameDivCreatives {
  border: 1px solid #1890ff;
}

:global(.ant-tabs-left > .ant-tabs-nav .ant-tabs-tab-btn) {
  margin: 0 auto;
}

.checkNotification {
  font-size: 1.5rem;
  color: #c6d699;
  width: 20px;
  height: 22px;
  display: inline-block;
  transform: translateY(-8px);
}

.warningNotification {
  position: absolute;
  right: -24px;
  top: -21px;
  font-size: 1.5rem;
  color: #f47562;
  display: inline-block;
  /* transform: translateY(-8px); */
}

.closeBtnTop {
  border-color: #4a90e2;
  color: #4a90e2;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 4px;
}
:global(.ant-tabs-nav .ant-tabs-tab-active) .tabNameDiv {
  color: #2964a9;
}
:global(.ant-tabs-tab.ant-tabs-tab-disabled) .tabNameDiv {
  color: #c0c0c0;
}
:global(.ant-tabs-nav .ant-tabs-tab-active) .circleNumber {
  background-color: #2964a9;
}
:global(.ant-tabs-tab.ant-tabs-tab-disabled) .circleNumber {
  background-color: #c0c0c0;
}

.tabNameDiv {
  color: rgb(85, 85, 85);
  display: flex;
  padding: 7px 0px;
  align-items: center;
}
.tabNameDivCreatives {
  position: relative;
  border: 1px solid #d6d6d6;
  color: rgb(99, 99, 99);
  border-radius: 5px;
  display: flex;
  padding: 10px 2rem 10px 2rem;
  align-items: center;
  justify-content: center;
}
.newStepSpan {
  width: 44px;
  background: #4a90e2 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #ffffff;
  text-align: center;
  margin: 0 20px;
}
.headerStepRight {
  display: flex;
  align-content: center;
  align-items: center;
  margin-bottom: 0.5em;
  margin-left: 20px;
}
.headerCurrentTabText {
  color: #4d4f5c;
  font-size: 1.3rem;
}

.topCampaignTitle {
  min-width: 235px;
  color: #4d4f5c;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  margin-bottom: 7px;
}
.topCampaignTitle > p {
  margin: 0;
}
.topCampaignTitle > img {
  margin: 0.5rem 0.7rem 0.5rem 1.5rem;
  width: 30px;
}

.newCampaignTabsRow :global(.ant-tabs.ant-tabs-left) {
  width: 100%;
}

.saveCampaignBtn {
  padding-left: 2rem;
  padding-right: 2rem;
  margin-left: 2rem;
  background-color: #70c040;
  border-color: #70c040;
  border-radius: 4px;
  color: white;
}

.saveCampaignBtn:hover {
  border-color: #70c040;
  color: #70c040;
}

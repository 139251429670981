.generalWrapper {
  width: 100%;
  min-width: 300px;
}
.generalContentWrapper {
  width: 100%;
  max-width: 500px;
  min-height: 550px;
  /* max-height: 550px; */
}
.statusRadioButton {
  flex-direction: row !important;
  align-items: center;
}
.radioButtonLabels {
  width: 75px;
  text-align: center;
}

.autoManualPricing :global(.ant-form-item-label > label) {
  margin-left: 30px;
}

.datepickerStart,
.datepickerEnd {
  width: 50%;
  display: inline-block;
}
.datepickerStart :global(.ant-col.ant-form-item-control) {
  margin-right: 10px;
}
.datepickerStart :global(.ant-picker),
.datepickerEnd :global(.ant-picker) {
  width: 100%;
}

.statusInfo {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.statusInfo > p {
  margin: 0;
  color: #000000d9;
  padding-right: 0.5rem;
}

.manualValueInput {
  display: inline-block;
  width: 150px;
  position: absolute;
  margin-top: 30px;
  margin-left: 10px;
}
.useSuggestedBid {
  display: flex;
  flex-direction: row;
}
.budgetAndPeriod {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.campaignBid :global(.ant-input-number) {
  width: 100px;
  margin-right: 8px;
}
.campaignBudget {
  display: inline-block;
  width: 48%;
  /* margin-right: 7%; */
}
.campaignBudget input {
  min-height: 32px;
}
.campaignBudgetPeriod {
  display: inline-block;
  width: 48%;
}
.proceedButton {
  padding-left: 1.6rem;
  padding-right: 1.6rem;
  margin-top: 20px;
  border-radius: 4px;
  background-color: #4090f7;
}

@media (max-width: 1100px) {
  .generalWrapper {
    width: 95%;
    margin: 0 auto;
  }
}

@media (max-width: 760px) {
  .useSuggestedBid {
    flex-direction: column;
    margin-bottom: 24px;
  }
  .useSuggestedBid .campaignBid {
    margin-bottom: 16px;
  }
}

/* .campaignTypeInput,
.accName {
  width: 50%;
  display: inline-block;
}

.accName :global(.ant-col.ant-form-item-control) {
  margin-right: 10px;
}
.campaignTypeInput :global(.ant-form-item-control-input-content) {
  display: flex;
} */

.mainTableContainer :global(.ant-table-tbody > tr > td) {
  padding: 13px;
}
.mainTableContainer :global(.ant-table-thead > tr > th) {
  padding: 13px;
}

.mainTableContainer :global(.ant-table-tbody > tr > td) {
  background-color: inherit;
}

.mainTableContainer :global(.ant-table-row:nth-child(odd)) {
  background-color: #f5f6fa;
}

.reportsContainer > h4 {
  font-size: 1.4rem;
  color: #43425d;
  padding-right: 0.9rem;
  margin: 0;
  font-weight: 500;
}

.columnMargin {
  margin: 0;
}

.campaignId, .campaignName {
  margin: 0;
  color: #0071BD;
  font-weight: 600;
  cursor: pointer;
}

.cpcColContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.cpcColContainer button {
  margin-left: .5rem;
}

.cpcColContainer :global(.ant-btn-icon-only){
  height: 22px;
  width: 22px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cpcColContainer :global(.ant-btn > .anticon) {
  line-height: 0;
}

.cpcColContainer :global(.ant-btn-icon-only > *) {
  font-size: 13px;
}

.pricingColumn {
  display: flex;
  justify-content: flex-start;
}

.pricingColumn .confirmButtonDisabled {
  position: relative;
  top: 4px;
  right: 0;
  margin-left: 1rem;
}

.pricingColumn .confirmButtonGreen {
  position: relative;
  top: 4px;
  right: 0;
  margin-left: 1rem;
  color: #73B566;
  border: 1px solid #73B566;
  background-color: #73B5661a;
}

.priceUpdateContainer {
  display: flex;
}

.priceUpdateContainer :global(.ant-btn){
  padding: 0;
  height: 100%;
  width: 33px;
  border: 1px solid #EEEEEE;
  color: #4D4F5C;
  font-size: 13px;
  box-shadow: none;
  border-radius: 0 5px 5px 0;
}

.priceUpdateContainer :global(.ant-btn:first-child){
  border-radius: 5px 0 0 5px;
}

.mainPriceBox {
  /* padding: 0.3rem; */
  border-top: 1px solid #EEEEEE;
  border-bottom: 1px solid #EEEEEE;
}

.mainPriceBox * {
  box-shadow: none;
}

.mainPriceBox :global(.ant-input-number) {
  border: none;
  border-radius: 0;
}

.mainPriceBox :global(.ant-input-number:focus) {
  border: none;
  box-shadow: none;
}

.mainPriceBox :global(.ant-input-number-input-wrap) input{
  padding: 0;
  text-align: center;
  color: #43425d;
}

.mainPriceBox :global(.ant-input-number-handler-wrap) {
  position: relative;
  display: none;
}

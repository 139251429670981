.generatorModal {
  width: 100%;
  height: 100vh;
}

.creativeGridContainer {
  height: calc(100vh - 103px);
  overflow: hidden;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 1150px) {
  .creativeGridContainer {
    height: auto;
    flex-direction: column;
  }
}

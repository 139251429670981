.borderIP {
  border: 1px solid #cccccc;
}

.headTabInfo {
  display: flex;
  flex-direction: column;
}
.headTabInfo > div {
  display: flex;
  align-items: center;
  font-weight: 600;
}
.headTabInfo > div > p {
  font-size: 1rem;
  margin: 0px;
  padding-left: 0.4rem;
}
.headTabInfo > p {
  color: rgb(103, 103, 103);
}
